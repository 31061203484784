import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { Outlet, useLocation} from 'react-router-dom'
import { browserHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux'

import { updateGlobalStore } from '@/store/modules/global/actionCreators'
import { updateSubscriptionStore } from '@/store/modules/subscription/actionCreators'

import { GA_ID } from '@/config/config'
import getRouteMeta from '@/router/config'
import api from '@/api'
import './BaseLayout.less'
import { useMetamask } from '@/mixins/metamask';
import { clickReportGa } from '@/utils/utils'
import Message from '@/components/global/message';

import NavigationView from '@/components/global/components/NavigationView';
import DialogNftDetailsWrapper from '@/components/global/dialog/nft-details'
import DialogLogin from '@/components/global/dialog/login'
import GlobalCalendar from '@/components/global/dialog/Calendar'

const BaseLayout = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const accountAddress = useSelector(state => state.subscription.accountAddress)
  const isSubscription = useSelector(state => state.subscription.isSubscription)
  const loginProps = useSelector(state => state.global.loginProps)
  const calendarProps = useSelector(state => state.global.calendarProps)
  const metamask = useMetamask()
  const [currentPageMeta, setCurrentPageMeta] = useState({})
  let start_env = process.env.START_ENV
  let node_env = process.env.NODE_ENV
  useEffect(()=>{
    initMetamask();

    // 监听浏览器tab是否活跃
    if (document.hidden !== undefined) {
      // 监听浏览器tab是否活跃
      windowTabVisibilityChange();
    }

    // 初始化 ga 事件配置
    initGa()
    // 监听点击事件 （优化点击埋点方式）
    document.addEventListener('click', documentClickForGa, false)

    // 判断是否登录
    let isLogin = localStorage.getItem('isLogin') == 'true'
    if(!isLogin && start_env !== 'prod' && node_env != 'development' && window.location.pathname != '/login') {
      window.location.href = '/login'
    }
    // 启动监听判断钱包是否被锁定
    let timer = setInterval(async () => {
      let ethAccounts = await window.ethereum.request({
        method: 'eth_accounts',
        jsonrpc: "2.0",
        params: [],
      })
      console.log('ethAccounts', ethAccounts);
      if(!ethAccounts.length) {
        metamask.logoutMetamask()
        clearInterval(timer)
      }
      // console.log('ethAccounts=>>', ethAccounts)
    }, 1000 * 60 * 10)

    // 获取尺寸变化
    initWindowResize();
    setTimeout(() => {
      // 页面视口变化，重新获取 内容区域 的高度
      window.addEventListener('resize', initWindowResize, false)
    }, 300);

    return () => {
      clearInterval(timer)
      document.removeEventListener('click', documentClickForGa, false)
      window.removeEventListener('resize', initWindowResize, false)
    }
  },[])

  useEffect(()=>{
    if( !document.hidden && accountAddress && window.location.pathname != '/invited') {
      // 保存用户账户信息
      api.user.createUser(accountAddress, {invited: false}).then(res => {
        // 新用户给7天会员
        if(res.code == 1000) {
          receiveSubscriptionTime(accountAddress)
        }
      })
    }
  },[accountAddress])

  useEffect(()=>{
    let { pathname } = location
    let currentPageMeta = getRouteMeta(pathname)
    // console.log('currentPageMeta=>>',currentPageMeta)
    setCurrentPageMeta(currentPageMeta)
    if(currentPageMeta.title){
      document.title = currentPageMeta.title
    }
    dispatch(updateGlobalStore({
      type: 'updateCurrentPageMeta',
      data: currentPageMeta
    }))
    console.log('location=>>>', location)
    clickReportGa({
      name: 'custom_page_view',
      category: 'View',
      label: `${currentPageMeta.GALabel || ''}`,
      value: 100
    })
  },[location])


  // 全局点击事件埋点
  const documentClickForGa = (e) => {
    try {
      let target = e.target
      // console.log(target, "=================>点击元素")
      let ga_event_data = target.getAttribute('ga-event-data')
      let index = 0   // index: 查找埋点元素层级索引
      if (!ga_event_data) {
        while (index < 3) {
          let parent = target.parentNode
          ga_event_data = parent.getAttribute('ga-event-data')
          if (ga_event_data) {
            break
          } else {
            index ++
          }
        }
      }
      if (!ga_event_data) return
      let [name, label, value, category] = ga_event_data.split('/')
      console.log(name, label, value, "=====================>name")
      window.gtag('event', name, {
        'event_category': category ? category : 'Click',
        'event_label': label,
        value
      })
    } catch (e) {
      console.error(e)
    }
  }
  // 初始化 网页浏览事件（避免第一次加载网页不计浏览数）
  const initGa = () => {
    try {
      window.gtag('config', GA_ID, {
        'user_id': isSubscription ? accountAddress : ''
      });
      window.gtag('event', 'page_view', {
        page_path: '/',
        send_to: GA_ID
      })
    } catch (e) {
      console.error(e)
    }
  }

  // 监听浏览器tab是否活跃
  const windowTabVisibilityChange = () => {
    document.addEventListener('visibilitychange', ()=>{
      // console.log('是否隐藏：', document.hidden)
      dispatch(updateGlobalStore({
        type: 'updateFocusPageStatus',
        data: !document.hidden
      }))
    })
  }

  // 判断是否订阅
  const getUserMemberships = () => {
    if(!!accountAddress){
      api.user.getUserMemberships(accountAddress).then(res=>{
        if(res.code === 1000){
          // 判断是否订阅
          dispatch(updateSubscriptionStore({
            type: 'updateAccountInvitedInfo',
            data: res.data
          }))
          dispatch(updateSubscriptionStore({
            type: 'updateIsSubscription',
            data: res.data.expired_at
          }))
        }
      })
    }
  }

  // 初始化
  const initMetamask = async () => {
    if (!!window.ethereum && Boolean(window.ethereum && window.ethereum.isMetaMask) && !!window.web3) {
      // console.log('MetaMask is installed!');
      // 是否状态
      dispatch(updateSubscriptionStore({
        type: 'updateMetaMaskInstalled',
        data: true
      }))

      let ethAccounts = await window.ethereum.request({
        method: 'eth_accounts',
        jsonrpc: "2.0",
        params: [],
      })
      if(!ethAccounts.length) {
        metamask.logoutMetamask()
      }

      // 优先读取本地address
      // let accountAddress = window.web3.eth?.defaultAccount || localStorage.getItem('address') || null
      let accountAddress = localStorage.getItem('address') || null
      dispatch(updateSubscriptionStore({
        type: 'updateAccountAddress',
        data: accountAddress
      }))
      dispatch(updateSubscriptionStore({
        type: 'updateAccountLoad',
        data: true
      }))
      if(!accountAddress){
        // 设置未订阅
        dispatch(updateSubscriptionStore({
          type: 'updateAccountInvitedInfo',
          data: {}
        }))
        dispatch(updateSubscriptionStore({
          type: 'updateIsSubscription',
          data: 0
        }))
      }
      if(accountAddress && window.location.pathname != '/invited') {
        // 保存用户账户信息
        api.user.createUser(accountAddress, {invited: false}).then(res => {
          // 新用户给7天会员
          if(res.code == 1000) {
            receiveSubscriptionTime(this.accountAddress)
          }
        })
        // 判断是否订阅
        getUserMemberships();
      }


      // 监听连接
      window.ethereum.on('connect', (connectInfo) => {
        console.log('connect=>>>', connectInfo)
      });
      // 监听断开
      window.ethereum.on('disconnect', (error) => {
        console.log('disconnect=>>>', error)
      });
      // 监听切换网络
      window.ethereum.on('chainChanged', (networkIDstring) => {
        console.log('chainChanged=>>>', networkIDstring)
        window.location.reload()
      });
      // 帐户已更改
      window.ethereum.on('accountsChanged', (accounts) => {
        console.log('accountsChanged=>>>', accounts)
        if(accounts[0]){
          dispatch(updateSubscriptionStore({
            type: 'updateAccountAddress',
            data: accounts[0]
          }))
          // 判断是否订阅
          getUserMemberships();

        }else{
          dispatch(updateSubscriptionStore({
            type: 'updateAccountAddress',
            data: null
          }))
          // 提示没有订阅
          dispatch(updateSubscriptionStore({
            type: 'updateIsSubscription',
            data: 0
          }))
        }
        // 记录是否授权过
        let accredit = localStorage.getItem('accredit')
        if(accredit != 'true') {
          dispatch(updateSubscriptionStore({
            type: 'updateIsAccredit',
            data: accounts.length > 0
          }))
        }
        localStorage.setItem('accredit',accounts.length > 0)
        // 刷新页面
        // window.location.reload()
      });
      // 信息
      window.ethereum.on('message', (message)=>{
        // console.log('message=>>>', message)
      });

    }else{
      console.log('没有安装')
      dispatch(updateSubscriptionStore({
        type: 'updateAccountLoad',
        data: true
      }))
      dispatch(updateSubscriptionStore({
        type: 'updateAccountAddress',
        data: ''
      }))
    }
  }

  // 检查是否领取过免费订阅时长
  const receiveSubscriptionTime =async  (account) =>{
    try {
      let body = {
        from_address: account,
        total_price: 0,
        period: 3,
        pay_method: 'signup',
      }
      let res = await api.user.createOrder(account, body)
      if(res.code === 1000){}
      return Promise.resolve(res)
    } catch (error) {
       return Promise.reject('error')
    }
  }

  const closeGlobalCollectionSearch = () => {
    dispatch(updateGlobalStore({
      type: 'showGlobalCollectionSearch',
      data: false
    }))
  }

  // 监听尺寸变化
  const initWindowResize = () => {
    let width = document.documentElement.clientWidth
    let height = document.documentElement.clientHeight
    dispatch(updateGlobalStore({
      type: 'updateWindowClientInfo',
      data: {
        width,
        height,
      }
    }))
  }

  return (
    <div className={`base-layout ${currentPageMeta.oneScreenDisplay ? 'app-one-screen-display': ''} ${ window.location.pathname === '/mint' ? 'height100' : '' } `} onClick={()=>{
      closeGlobalCollectionSearch()
      // 关闭蓝筹详情
      dispatch(updateGlobalStore({
        type: 'updateBluechipDetailValue',
        data: null
      }))
      // 关闭顶部个人菜单
      dispatch(updateGlobalStore({
        type: 'updateShowGlobalNavBarMenu',
        data: false
      }))
    }}>
      {/* 顶部横幅 */}
      <NavigationView
        routeMeta= {currentPageMeta}
      />

      {/* 主体内容 */}
      <Outlet/>

      {/* 全局 toast */}
      <Message />
      {/* 全局 NFT details 弹窗 */}
      <DialogNftDetailsWrapper/>
      {/* 全局授权弹窗 */}
      { loginProps.show && <DialogLogin/> }
      {/* 全局calendar弹窗 */}
      { calendarProps.show && <GlobalCalendar/>}
    </div>
  )
}

export default BaseLayout
