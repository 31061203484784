import { APILINK} from '@/config/config'
import axios from '../axios'

// 获取折线图数据
export const getLineChartData = (contract_address, params) => {
  return axios({
    url: `${APILINK}/nft/v2/collections/${contract_address}/blue_chip_holders`,
    method: 'GET',
    params
  })
}

// 获取柱状图数据
export const getBarChartData = (contract_address, params) => {
  return axios({
    url: `${APILINK}/nft/v2/collections/${contract_address}/blue_chip_holding`,
    method: 'GET',
    params
  })
}
