import { APILINK } from '@/config/config'
import axios from '../axios'

// 获取listing列表
export const getListings = (params, channel=[]) => {
  let urlParams = ``
  Object.keys(params).forEach(key => {
    urlParams += `${key}=${params[key]}&`
  })
  if(channel.length === 3 || !channel.length){
    urlParams+=`markets=`
  }else{
    channel.forEach((item, index) => {
      urlParams += `markets=${item}${channel.length-1===index ? '' : '&'}`
    })
  }
  return axios({
    url: `${APILINK}/nft/v1/listings?${urlParams}`,
    method: 'GET',
    params:{}
  })
}