import { APILINK } from '@/config/config'
import axios from '../axios'

// 获取 opensea 订单
export const getNftPayOrder = (contract_address, token_id) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/os_orders/${token_id}`,
    method: 'GET'
  })
}

// 获取 opensea 订单（新）
export const getNftPayOrderNew = (contract_address, token_id) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/os_listings/${token_id}`,
    method: 'GET'
  })
}

// 获取 x2y2 订单
export const getX2y2Order = (params) => {
  return axios({
    url: `${APILINK}/nft/v1/x2y2/order`,
    method: 'GET',
    params
  })
}

// 标记 x2y2 订单
export const signX2y2Order = (data) => {
  return axios({
    url: `${APILINK}/nft/v1/x2y2/order/sign`,
    method: 'POST',
    data
  })
}

// 获取不到订单时调用下架
export const signOrder = (contract_address, token_id) => {
  return axios({
    url: `${APILINK}/nft/v1/assets/${token_id}`,
    method: 'PUT',
    data: {
      contract_address
    }
  })
}

// 获取 element 订单
export const getElementOrder = (params) => {
  return axios({
    url: `${APILINK}/nft/v1/element/orders`,
    method: 'GET',
    params
  })
}
