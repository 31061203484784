import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
// 连接
import { useSelector, useDispatch } from 'react-redux'

import { updateSubscriptionStore } from '@/store/modules/subscription/actionCreators'
import api from '@/api'
import useMessage from '@/components/global/message/message';

const useMetamask = () => {
  const dispatch = useDispatch();
  const message = useMessage();
  
  const accountAddress = useSelector(state => state.subscription.accountAddress)
  const accountAddressRef = useRef(null)
  const metaMaskInstalled = useSelector(state => state.subscription.metaMaskInstalled)
  
  useEffect(() => {
    accountAddressRef.current = accountAddress
  },[accountAddressRef])

  const connectMetamask = async (from) =>{
    console.log(from);
    // 检测是否安装
    let metaMaskInstalled = !!window.ethereum && Boolean(window.ethereum && window.ethereum.isMetaMask) && !!window.web3
    if(!metaMaskInstalled){
      message.error('There was an error connecting to your wallet. Please make sure you have MetaMask installed')
      return {code: 0, message: '没有安装metaMask'}
    }
    try {
      // 获取授权过的账户
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      let account = ''
      console.log(accounts, 'accounts');
      if(accounts.length){
        account = accounts[0]
        dispatch(updateSubscriptionStore({
          type: 'updateAccountAddress',
          data: account
        }))
        console.log(account,'account');
        // if(window.location.pathname != '/invited' || from == 'navbar') {
        //   // 保存用户账户信息
        //   await api.user.createUser(account, {invited: false})
        //   // 检查是否领取过免费订阅时长
        //   await receiveSubscriptionTime(account);
        //   setTimeout(() => {
        //     // 立即更新会员状态
        //     getUserMemberships(account)
        //   }, 200)        
        // }
      }
      return {code: 1000, message: '授权完成', account}
    } catch (error) {
      // console.log('connectMetamask=>>>', error)
      return {code: 2000, message: '发生错误'}
    }
  }
  
  // 检查是否领取过免费订阅时长
  const receiveSubscriptionTime = async (account) => {
    try {
      let body = {
        from_address: account,
        total_price: 0,
        period: 3,
        pay_method: 'signup',
      }
      let res = await api.user.createOrder(account, body)
      if(res.code === 1000){}
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject('error')
    }
  }
  
  // 购买订阅
  const buySubscription = async (data, options) => {
    // 没有安装
    if(!metaMaskInstalled){
      message.error('There was an error connecting to your wallet. Please make sure you have MetaMask installed')
      return
    }
    // eth_call 查询以太坊代币余额
    // from: DATA, 20 Bytes - 发送交易的原地址，可选
    // to: DATA, 20 Bytes - 交易目标地址
    // data: DATA - 方法签名和编码参数的哈希，可选
    // QUANTITY|TAG - 整数块编号，或字符串"latest"、“earliest"或"pending”
    // console.log(data)
  
    try {
      let txHash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [data],
      })
      // txHash
      // console.log(txHash)
      // 创建订单
      createOrder({...data, transaction_id: txHash}, options);
      return {code: 1000, message: 'Pay Success!'}
    } catch (error) {
      // console.log(error,"====================>pay fail")
      // 钱包断开连接了
      if(error.code === -32602){
        message.error(error.message);
        connectMetamask();
      }
      return error
    }
  }
  // 创建订单
  const createOrder = (data, options) => {
    let body = {
      from_address: data.from,
      to_address: data.to,
      discord: options.discordId,
      description: null,
      total_price: options.price,
      transaction_id: data.transaction_id,
      period: options.period,
      pay_method: 'pay',
      channel_exclusive_detail_id: options.channel_exclusive_detail_id,
      channel_exclusive_discount_token: options.channel_exclusive_discount_token
    }
    api.user.createOrder(data.from, body).then(res=>{
      if(res.code === 1000){
        // 获取订阅时间
        getUserMemberships(data.from);
      }
    })
  }
  // 获取订阅时间
  const getUserMemberships = (address) => {
    console.log('====================================');
    console.log('嗲用了', address || accountAddressRef.current);
    console.log('====================================');
    if(!!accountAddressRef.current || address){
      api.user.getUserMemberships(address || accountAddressRef.current ).then(res=>{
        if(res.code === 1000){
          let { expired_at } = res.data;
          // 判断是否订阅
          dispatch(updateSubscriptionStore({
            type: 'updateIsSubscription',
            data: expired_at
          }))
          dispatch(updateSubscriptionStore({
            type: 'updateAccountInvitedInfo',
            data: res.data
          }))
        }
      })
    }
  }
  
  // 退出登录
  const logoutMetamask = () => {
    // 删除保存的地址
    dispatch(updateSubscriptionStore({
      type: 'updateAccountAddress',
      data: null
    }))
    localStorage.setItem('address', '')
    // 订阅状态改变
    dispatch(updateSubscriptionStore({
      type: 'updateIsSubscription',
      data: 0
    }))
  }
  
  return {
    connectMetamask,
    receiveSubscriptionTime,
    buySubscription,
    getUserMemberships,
    logoutMetamask
  }
}

export {
  useMetamask
}