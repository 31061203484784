import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment'
import Button from '@mui/material/Button'
import useMessage from '@/components/global/message/message';
import { updateGlobalStore } from '@/store/modules/global/actionCreators'
import { updateNftsStore } from '@/store/modules/nfts/actionCreators'


import api from '@/api'

// css
import './index.less'

import calendarIcon from '@/assets/images/calendar/calendar.png';
import etherscan_normal from '@/assets/images/newLogo/etherscan_normal.png';
import etherscan_active from '@/assets/images/newLogo/etherscan_active.png';
import opensea_normal from '@/assets/images/newLogo/opensea_normal.png';
import opensea_active from '@/assets/images/newLogo/opensea_active.png';
import discord_normal from '@/assets/images/newLogo/discord_normal.png';
import discord_active from '@/assets/images/newLogo/discord_active.png';
import looksrare_normal from '@/assets/images/newLogo/looksrare_normal.png';
import looksrare_active from '@/assets/images/newLogo/looksrare_active.png';
import smallLogo from '@/assets/images/small-logo.png';
import calendarBlurIcon from '@/assets/images/calendar/blur.png';


const GlobalCalendar = (props) => {
  const dispatch = useDispatch()
  const message = useMessage()

  const collectionList = useSelector(state => state.nfts.collectionList)
  const accountAddress = useSelector(state => state.subscription.accountAddress)
  const isSubscription = useSelector(state => state.subscription.isSubscription)
  const subscriptionAt = useSelector(state => state.subscription.subscriptionAt)

  const [overlay, setOverlay] = useState(true)
  const [reveal_list, setReveal_list] = useState([])
  const [operating, setOperating] = useState(false) // 收藏防抖
  const [calendarDataLoading, setCalendarDataLoading] = useState(true) // 加载
  const [time, setTime] = useState({})

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  useEffect(() => {
    let html = document.querySelector('html')
    html.style.position = 'static'
    html.style.overflow = 'hidden'
    getCalendarData();
  },[])

  const close = () => {
    if(operating) return
    dispatch(updateGlobalStore({
      type: 'updateShowCalendar',
      data: {
        show: false,
      }
    }))
    let html = document.querySelector('html')
    html.style.position = 'initial'
    html.style.overflow = 'unset'
  }
  const getCalendarData = () => {
    if(isSubscription) {
      setCalendarDataLoading(true)
      api.collection.getCalendarData({wallet_address: accountAddress}).then(res => {
        setCalendarDataLoading(false)
        if(res.code == 1000) {
          let {reveal_list } = res.data
          let time = {}
          setReveal_list(reveal_list)
          
          reveal_list.forEach(item => {
            let timeVal = moment(item.reveal_time).format('YYYY-MM-DD')
            if(!time[timeVal]) {
              time[timeVal] = [item]
            } else {
              time[timeVal].push(item)
            }
          })
          setTime(time)
        }
      })
    } else {
      setCalendarDataLoading(false)
      // this.time['2022-03-25'] = [{"contract_address":"0xed5af388653567af2f388e6224dc7c4b3241c544","reveal_time":"2022-03-24T16:00:00Z","opensea_slug":"azuki","name":"Azuki","image":"https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s120","collected":false,"rank":true}]
    }
  }
  // 打开url
  const openUrl = (item, type) => {
    let url = ''
    if(type == 'open_1') {
      url = `https://opensea.io/collection/${item.opensea_slug}?ref=${item.contract_address}`
    } else if(type == 'open_2') {
       url = `https://looksrare.org/collections/${item.contract_address}`
    } else if(type == 'open_3') {
      url = `https://etherscan.io/address/${item.contract_address}`
    } else if(type == 'open_4') {
      url = `https://discord.com/invite/${item.opensea_slug}`
    }
    window.open(url)
  }
  // 判断今天明天
  // const nowDateLabel = (key) => {
  //   let now = moment().format("YYYY-MM-DD")
  //   let tomorrow = moment().add(1,'d').format("YYYY-MM-DD")
  //   if(key == now) {
  //     return 'Today'
  //   } else if(key == tomorrow) {
  //     return 'Tomorrow'
  //   } else {
  //     return ''
  //   }
  // }
  // 购买会员页
  const buySubscription = () => {
    window.open('/subscription')
  }
  // 是否是超过了当前时间
  const isOverflowTime = (item) => {
    let now = new Date().valueOf()
    let itemTime = new Date(item).valueOf()
    return now > itemTime
  }
  // 收藏项目
  const collectionItem = async (obj) => {
    if(operating) return
    setOperating(true)
    let tempCollectionList = [...collectionList]
    if (accountAddress) {
      dispatch(updateGlobalStore({
        type: 'updateCollectionName',
        data: obj.name
      }))
      let isCollection = obj.collected
      let data = {
        contract_address: obj.contract_address,
        wallet_address: accountAddress
      }
      obj.collected = !obj.collected
      try {
        let res = isCollection ? await api.collection.delCollectionItem(data) : await api.collection.setCollectionItem(data)
        if (res.code === 1000) {
          message.success(isCollection ? 'Removed from watchlist' : 'Added to watchlist')
        } else {
          obj.collected = !obj.collected
          message.error(isCollection ? 'Cancel Fail!' : 'Attention Fail!')
        }
        if(obj.collected) {
          tempCollectionList.push(obj.contract_address)
        } else {
          tempCollectionList = tempCollectionList.filter(item => item != obj.contract_address)
        }
        dispatch(updateNftsStore({
          type: 'updateCollectionList',
          data: tempCollectionList
        }))
        setOperating(false)
      } catch (e) {

      }
    } else {
      dispatch(updateGlobalStore({
        type: 'updateLoginProps',
        data: {
          show: true,
        }
      }))
    }
  }

  return (
    <div className='calendar-body-bg' onClick={()=>{
      close()
    }}>
    {/* style={{height: isSubscription ? 'unset' : '560px'}} */}
      <div className="calendar-body" onClick={(e)=>{
        e.stopPropagation()
      }}>
        {!calendarDataLoading ?
          <>
            <div className="calendar-header">
              <div className="header-image flex-c">
                <img src={calendarIcon} alt="" />
              </div>
              <div>
                <div className="title-top">Reveal Calendar</div>
                <div className="title-bottom">Adjusted to your timezone: {timeZone}</div>
              </div>
            </div>

            { (Object.keys(time).length && isSubscription) ?
              <div className="calendar-content-body hidden-scrollbar">
                <div className="calendar-content">
                  {Object.keys(time).map((key,index)=>
                    <div className="calendar-item" key={index + 'd'}>
                      <div className="calendar-time">
                        {moment(key).format("dddd - MMM.DD, YYYY")}
                      </div>
                      {time[key].map(item=>
                        <div className="assets-item" key={item.contract_address}>

                          <div className="flex-column-c" style={{color: isOverflowTime(item.reveal_time) ? '#757B8C' : '#fff'}}>
                            <span style={{width: '80px'}}>{moment(item.reveal_time).format('h:mm a')}</span>
                            <div className="flex-c assets-body">
                              <a className="flex-column-c" style={{color: isOverflowTime(item.reveal_time) ? '#757B8C' : '#fff'}}
                                target="_blank"
                                href={`/collection/${item.contract_address}/activity?c_page=1`}
                              >
                                {!item.image ?
                                  <img  className="collection-img" src={smallLogo} alt="" />
                                  :
                                  <img  className="collection-img" src={item.image} alt="" />
                                }
                                { item.name }
                              </a>
                            </div>
                          </div>

                          <div className="flex-column-c">
                            <div className="flex-c assets-item-list">
                              <div className="link-list cursor-p" onClick={()=>{openUrl(item, 'open_1')}}>
                                <div className="logo-img-container">
                                  <img src={opensea_normal} alt="" className="normal" />
                                  <img src={opensea_active} alt="" className="active" />
                                </div>
                              </div>
                              <div className="link-list cursor-p" onClick={()=>{openUrl(item, 'open_2')}}>
                                <div className="logo-img-container">
                                  <img src={looksrare_normal} alt="" className="normal" />
                                  <img src={looksrare_active} alt="" className="active" />
                                </div>
                              </div>
                              <div className="link-list cursor-p" onClick={()=>{openUrl(item, 'open_3')}}>
                                <div className="logo-img-container">
                                  <img src={etherscan_normal} alt="" className="normal" />
                                  <img src={etherscan_active} alt="" className="active" />
                                </div>
                              </div>
                              <div className="link-list cursor-p" onClick={()=>{openUrl(item, 'open_4')}}>
                                <div className="logo-img-container">
                                  <img src={discord_normal} alt="" className="normal" />
                                  <img src={discord_active} alt="" className="active" />
                                </div>
                              </div>
                            </div>
                            <div className="collection flex-c" onClick={()=>{collectionItem(item)}}>
                              {item.collected ?
                                <span className="iconfont icon-Star1" style={{color: '#FA2256'}} />
                                :
                                <span className="iconfont icon-Star" />
                              }
                            </div>
                          </div>

                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              : null
            }

            {!isSubscription ?
              <div className="blur-content">
                {/* @dragstart="()=>{return false}" */}
                <img className="blur-img" src={calendarBlurIcon} alt="" />
                <div className="blur-info">
                  <img src={smallLogo} alt="" />
                  <p className="blur-text">B4fomo Subscription Members Only </p>
                  <Button color="themeColor" variant="contained" classNameName="rest-primary-btn"
                    style={{width: '200px', height: '46px'}} onClick={()=>{ buySubscription() }}
                  >
                    <span className="font_b font_16">Get Now</span>
                  </Button>
                </div>
              </div>
              : null
            }
            {(isSubscription && !Object.keys(time).length && !calendarDataLoading) ?
              <div className="empty-data">
                There are no upcoming collections.
              </div>
              : null
            }
          </>
          :
          <>
            <div className='loading-body'>
              <span className="iconfont global-loading" />
            </div>
            {/* <v-progress-circular
              className="public-img-loading"
              :size="18"
              :width="3"
              color="primary"
              indeterminate
            ></v-progress-circular> */}
          </>
        }
      </div>
    </div>
  )

}

export default GlobalCalendar
