import React, { useState, useEffect, useRef, } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@mui/material/Button';

import './index.less'
import { updateGlobalStore } from '@/store/modules/global/actionCreators'
import { useMetamask } from '@/mixins/metamask'

import metamaskLogo from '@/assets/images/metamask_logo.png';

const DialogLogin = (props) => {
  const {  } = props
  const dispatch = useDispatch()
  const metamask = useMetamask()
  
  const loginProps = useSelector(state => state.global.loginProps)
      
  /**
   * init
   */
  useEffect(() => {

  }, [])

  // 授权登录
  const connect = async () => {
    let res = await metamask.connectMetamask()
    // 没有安装 mateMask
    if (res.code === 0) {

    // 发生错误
    } else if (res.code === 2000) {

    // 授权成功
    } else {
      // 成功回调
      loginProps.loginCallback && loginProps.loginCallback()
      closeLogin()
    }
  }
  const closeLogin = () => {
    dispatch(updateGlobalStore({
      type: 'updateLoginProps',
      data: {}
    }))
  }


  return (
    <div className="dialog-login-wrapper flex" onClick={()=>{
      closeLogin()
    }}>
      <div className="login-container" onClick={(e) =>{
        e.stopPropagation()
      }}>
        <div className="login-header flex">
          <img src={metamaskLogo} alt="" className="header-img" />
          <div className="header-desc flex">
            <span className="title">Sign in</span>
            <span className="desc">You haven't connected your wallet yet</span>
          </div>
        </div>
        <div className="content">
          <p>Members have a better experience of  b4fomo. Please login and continue.</p>
          <p>You’ll get a <span className="bold">free 1-day subscription</span> for your first registration.</p>
        </div>
        <div className="btn-container">
          <Button
            className='login-button'
            onClick={e => {
              e.stopPropagation()
              connect()
            }}
          >
            <span className="btn-text">Sign in</span>
          </Button>
        </div>
      </div>
    </div>
  )

}

export default DialogLogin
