
// 更新当前address
const updateContractAddress = (data) => {
    return {
        type: 'updateContractAddress',
        data: data
    }
}
const updateLiveViewStore = ({type, data}) => {
    return {
      type,
      data
    }
}

// 更新asset 详情
const updateAssetDetail = data => {
    return {
        type: 'updateAssetDetail',
        data
    }
}

// 更新listing列表
const updateListingData = data => {
    return {
        type: 'updateListingData',
        data
    }
}
// 更新listingLength
const updateListingLength = data => {
    return {
        type: 'updateListingLength',
        data
    }
}
const updateListingLoadState = data => {
    return {
        type: 'updateListingLoadState',
        data
    }
}

// 更新Trades列表
const updateTradesData = data => {
  return {
      type: 'updateTradesData',
      data
  }
}
// 更新TradesLength
const updateTradesLength = data => {
  return {
      type: 'updateTradesLength',
      data
  }
}
const updateTradesLoadState = data => {
    return {
        type: 'updateTradesLoadState',
        data
    }
}

// Rank
const updateRankTotal = data => {
  return {
      type: 'updateRankTotal',
      data
  }
}
const updateRankMap = data => {
  return {
      type: 'updateRankMap',
      data
  }
}

const updateTradesSortType = data => {
  return {
      type: 'updateTradesSortType',
      data
  }
}

const filterTradesList = data => {
  return {
      type: 'filterTradesList',
      data
  }
}

const updateListingSortType = data => {
  return {
      type: 'updateListingSortType',
      data
  }
}

const updateFilterListingList = data => {
  return {
      type: 'updateFilterListingList',
      data
  }
}

const updateFloorPirce = data => {
  return {
      type: 'updateFloorPirce',
      data
  }
}

const updateFilterData = data => {
  return {
      type: 'updateFilterData',
      data
  }
}

//请求主页数据
// export const getMainData = () => {
//     return (dispatch) => {
//         reqmain().then((res) => {
//             if (res.data.success) {
//                 dispatch(changeMainData(res.data.data))
//             } else {
//                 console.log("失败", res);
//             }
//         }).catch((e) => {
//             console.log("服务页面数据请求错误！");
//         })
//     }
// };

export {
    updateContractAddress,
    updateLiveViewStore,
    updateAssetDetail,
    updateListingData,
    updateListingLength,
    updateListingLoadState,
    updateTradesData,
    updateTradesLength,
    updateTradesLoadState,
    updateRankTotal,
    updateRankMap,
    updateTradesSortType,
    filterTradesList,
    updateListingSortType,
    updateFilterListingList,
    updateFloorPirce,
    updateFilterData
}
