import React from 'react';
import ReactDOM from 'react-dom';
import Highcharts from 'highcharts';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// 定制黑色主题
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: '#282C38', //文字颜色
      // darker: '#fa2256',
    },
    loading: {
      main: '#757B8C'
    },
    themeColor: {
      main: '#fa2256',
      // hover: 'rgba(250, 34, 86, 0.08)',
      // selected: 'rgba(250, 34, 86, 0.16)',
      // disabled: 'rgba(250, 34, 86, 0.3)',
      // disabledBackground: 'rgba(250, 34, 86, 0.12)',
      // darker: '#053e85',
    },
    // action: {
    //   active: '#fa2256',
    //   hover: 'rgba(250, 34, 86, 0.08)',
    //   selected: 'rgba(250, 34, 86, 0.16)',
    //   disabled: 'rgba(250, 34, 86, 0.3)',
    //   disabledBackground: 'rgba(250, 34, 86, 0.12)',
    // },
    // background: {
    //   default: '#121212',
    //   paper: '#121212',
    // },
    // divider: 'rgba(255, 255, 255, 0.12)',
  }
});


// 设置图表主题
// Highcharts.theme = {
//   color: ['rgb(45, 186, 136)', 'rgb(127, 209, 255)', 'rgba(255, 255, 255, .8)', '#5B65F9', '#B96ED8'],
//   chart: {
//     backgroundColor: {
//       linearGradient: [0, 0, 500, 500],
//       stops: [
//         [0, 'rgb(255, 255, 255)'],
//         [1, 'rgb(240, 240, 255)']
//       ]
//     },
//   },
//   title: {
//     style: {
//       color: '#000',
//       font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
//     }
//   },
//   subtitle: {
//     style: {
//       color: '#666666',
//       font: 'bold 12px "Trebuchet MS", Verdana, sans-serif'
//     }
//   },
//
//   legend: {
//     itemStyle: {
//       font: '9pt Trebuchet MS, Verdana, sans-serif',
//       color: 'black'
//     },
//     itemHoverStyle:{
//       color: 'gray'
//     }
//   }
// };
//
// // 使主题配置生效
// Highcharts.setOptions(Highcharts.theme);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <ThemeProvider theme={theme}>
//       <CssBaseline/>
//       <App />
//     </ThemeProvider>
//   </React.StrictMode>
// );
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
