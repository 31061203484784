import { APILINK } from '@/config/config'
import axios from '../axios'

// 获取通知设置（Mark）
export const getNotificationSetting = address => {
  return axios({
    url: `${APILINK}/nft/v1/users/${address}/notification_settings`,
    method: 'GET'
  })
}

// 增加通知邮箱（Mark）
export const addEmail = ({address, data}) => {
  return axios({
    url: `${APILINK}/nft/v1/users/${address}/notification_settings/email`,
    method: 'POST',
    data
  })
}

// 增加通知 discord（Mark）
export const addDiscord = ({address, data}) => {
  return axios({
    url: `${APILINK}/nft/v1/users/${address}/notification_settings/discord`,
    method: 'POST',
    data
  })
}

// 消息通知开关（Mark）
export const notificationSettings = ({address, data}) => {
  return axios({
    url: `${APILINK}/nft/v1/users/${address}/notification_settings`,
    method: 'PUT',
    data
  })
}

// 删除 email contact（Mark）
export const delEmail = ({address, contact}) => {
  return axios({
    url: `${APILINK}/nft/v1/users/${address}/notification_settings/email/${contact}`,
    method: 'DELETE',
  })
}

// 删除 discord contact（Mark）
export const delDiscord = ({address, contact}) => {
  return axios({
    url: `${APILINK}/nft/v1/users/${address}/notification_settings/discord/${contact}`,
    method: 'DELETE',
  })
}
