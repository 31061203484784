//导入我们封装好的axios
import axios from '../axios'
import { APILINK } from '../../config/config'

// 获取项目数据（Mark）
export const getcollections = (params) => {
  return axios({
    url: `${APILINK}/nft/v1/collections`,
    method: 'GET',
    params
  })
}

// 获取项目数据（Mark新接口）
export const getCollectionsV2 = (params) => {
  return axios({
    url: `${APILINK}/nft/v5/collections`,
    method: 'GET',
    params
  })
}

// 获取项目数据（Mark新接口）
export const getTrendingList = (params) => {
  return axios({
    url: `${APILINK}/nft/v6/collections`,
    method: 'GET',
    params
  })
}

// 创建项目数据
export const createCollection = (data) => {
  return axios({
    url: `${APILINK}/nft/v1/collections`,
    method: 'POST',
    data
  })
}

// 详情页列表信息（Mark）
export const getNftsList = (data) => {
  return axios({
    url: `${APILINK}/nft/v1/assets`,
    method: 'POST',
    data
  })
}
// 获取所有筛选条件（Mark）
export const getFilterTraitsLable = ({params, contract_address}) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/traits`,
    method: 'GET',
    params
  })
}
// 获取列表详情数据（Mark）
export const getCollectionDetail = (params) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${params}`,
    method: 'GET'
  })
}

// collection 获取用户收藏状态（Mark）
export const getCollectionState = (params, wallet_address) => {
  return axios({
    url: `${APILINK}/nft/v1/collect_record/${wallet_address}/form`,
    method: 'GET',
    params
  })
}

// collection 用户收藏（Mark）
export const setCollectionItem = (data) => {
  return axios({
    url: `${APILINK}/nft/v1/collect_record`,
    method: 'POST',
    data
  })
}

// collection 用户取消收藏（Mark）
export const delCollectionItem = (data) => {
  return axios({
    url: `${APILINK}/nft/v1/collect_record`,
    method: 'DELETE',
    data
  })
}

// collection 用户收藏列表（Mark）
export const AttentionCollectionList = (params, wallet_address) => {
  return axios({
    url: `${APILINK}/nft/v1/collect_record/${wallet_address}/list`,
    method: 'GET',
    params
  })
}

// collection 设置地板价的列表（Mark）
export const getFloorPriceList = (data) => {
  return axios({
    url: `${APILINK}/nft/v1/floor_price`,
    method: 'POST',
    data
  })
}

// collection 设置地板价（Mark）
export const setFloorPrice = (data, contract_address) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/floor_price`,
    method: 'POST',
    data
  })
}

// collection 修改地板价（Mark）
export const updateFloorPrice = (data, contract_address) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/floor_price`,
    method: 'PUT',
    data
  })
}

// collection 删除地板价提醒记录（Mark）
export const delFloor = (id, contract_address) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/floor_price/${id}`,
    method: 'DELETE',
  })
}

// 获取 gas（Mark）
export const getGas = () => {
  return axios({
    url: `${APILINK}/nft/v1/gas`,
    method: 'GET'
  })
}

// 获取地板价数据（Mark）
export const getFloorPriceDataList = (address, params) => {
  return axios({
    url: `${APILINK}/nft/v1/users/${address}/floor_price`,
    method: 'GET',
    params
  })
}
/**
 * 获取 contract 数据（Mark）
 * @param {String} address 合约地址
 * @param {String} dataType 1. traits 2. metadata 3. events
 */
export const getCollectionData = (address, dataType) => {
  return axios({
    url: `${APILINK}/nft/v1/contract/${address}/${dataType}`,
    method: 'GET'
  })
}

// collection 获取项目市值/交易信息 - activity（Mark）
export const getCollectionMarket = ({data, contract_address}) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/stats`,
    method: 'POST',
    data
  })
}

// collection 获取项目市值/交易信息 -顶部（Mark）
export const getCollectionMarketCap = contract_address => {
  return axios({
    url: `${APILINK}/nft/v4/collections/${contract_address}/market_cap`,
    method: 'GET',
  })
}
// 通过 token_id 拿取 asset 信息（Mark）
export const getAssetInfo = (contract_address, token_id) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/assets/${token_id}`,
    method: 'GET',
  })
}

// 获取项目 volume（Mark）
export const getCollectionVolume = (contract_address, params) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/volumes`,
    method: 'GET',
    params
  })
}

// Collection Traits 筛选（Mark）
export const getCollectionFilter = (contract_address, params) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/assets_token_id`,
    method: 'GET',
    params
  })
}

// 获取FloorPrice AvgPrice等数据（Mark）
export const getCollectionsDetailData = (contract_address, params) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/statistics`,
    method: 'GET',
    params
  })
}

// 获取 NFT 历史交易（Mark）
export const getNftTransactionHistory = (contract_address, token_id, params) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/token_id/${token_id}/events`,
    method: 'GET',
    params
  })
}

// 计算器（Mark）
export const getCalculatorResult = (params) => {
  return axios({
    url: `${APILINK}/nft/v1/flip_calculator`,
    method: 'GET',
    params
  })
}

// 获取 listing 图表数据（Mark）
export const getListingChartData = (contract_address, params) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/listing`,
    method: 'GET',
    params
  })
}

// 获取 floor_price 图表数据（Mark）
export const getFloorPriceChartData = (contract_address, params) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/floor_price`,
    method: 'GET',
    params
  })
}

// 获取 Calendar 数据（Mark）
export const getCalendarData = (params) => {
  return axios({
    url: `${APILINK}/nft/v1/collection_list/reveal_time`,
    method: 'GET',
    params
  })
}
// 获取 collection volumes（Mark）
export const getCollectionVolumes = (params) => {
  return axios({
    url: `${APILINK}/nft/v2/collection_list/volumes`,
    method: 'GET',
    params
  })
}

// 获取 collection floor_price
export const getCollectionFloorPrice = (params) => {
  return axios({
    url: `${APILINK}/nft/v2/collection_list/floor_price`,
    method: 'GET',
    params
  })
}

// 获取 collection listing
export const getCollectionListing = (params) => {
  return axios({
    url: `${APILINK}/nft/v1/collection_list/listings`,
    method: 'GET',
    params
  })
}

// 获取 collection trades
export const getCollectionTrades = (address) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${address}/trades`,
    method: 'GET',
    params:{}
  })
}
// 获取 collection listings
export const getCollectionListings = (address) => {
  return axios({
    url: `${APILINK}/nft/v2/collections/${address}/listings`,
    method: 'GET',
    params:{}
  })
}

// 拥有本项目中 拥有蓝筹的数量
export const getBlueChipOwners = (contract_address) => {
  return axios({
    url: `${APILINK}/nft/v2/collections/${contract_address}/blue_chip_owners`,
    method: 'GET'
  })
}

// 获取蓝筹列表
export const getBlueChipList = (contract_address, params) => {
  return axios({
    url: `${APILINK}/nft/v2/collections/${contract_address}/blue_chip`,
    method: 'GET',
    params
  })
}

// 蓝筹对照详情
export const getBlueChipCompare= (contract_address, params) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/blue_chip_comparison`,
    method: 'GET',
    params
  })
}

// 获取tokenid 及 rank对应
//token_ids=373,4998,4999,407,524,2136,2176,2180&items=true&floor_price=true
export const getTokenToRank = (contract_address, params) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/ranks`,
    method: 'GET',
    params
  })
}
// 获取当前colltion Reveal状态
export const getCollectionRevealIntervals = (contract_address, params) => {
  return axios({
    url: `${APILINK}/nft/v1/collection_reveal_intervals/${contract_address}`,
    method: 'GET',
    params
  })
}

// 获取 Collection Traits Analysis 列表
export const getCollectionTraitsAnalysisList = (contract_address, params) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/traits_analysis`,
    method: 'GET',
    params
  })
}

// 获取 Collection Traits Analysis 列表总数
export const getCollectionTraitsAnalysisCount = (contract_address, params) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/traits_analysis_count`,
    method: 'GET',
    params
  })
}

// 获取当前展示的所以 Collection Address 列表
export const getCollectionListAddress = () => {
  return axios({
    url: `${APILINK}/nft/v1/collection_list/contract_address`,
    method: 'GET',
  })
}

// 获取 traits 下的 Floor Price
export const getCollectionTraitsFloorPrice = (contract_address, params) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/traits/floor_price`,
    method: 'GET',
    params
  })
}

// 获取项目进度
export const getCollectionProgress = (contract_address) => {
  return axios({
    url: `${APILINK}/nft/v1/collections/${contract_address}/meta`,
    method: 'GET'
  })
}

export const getCollectionDelta = (params) => {
  return axios({
    url: `${APILINK}/nft/v1/collection_list/delta`,
    method: 'GET',
    params
  })
}

// 获取 Free Mint Collections
export const getFreeMintCollectionList = (params) => {
  return axios({
    url: `${APILINK}/nft/v1/free_mint_alert`,
    method: 'GET',
    params
  })
}

// etherscan api
export const etherscanApi = (params) => {
  return axios({
    url: `${APILINK}/nft/v1/etherscan/api`,
    method: 'GET',
    params
  })
}

// 创建通过slug更新collection信息
export const updateCollectionSlug = (contract_address, data={}) => {
  return axios({
    url: `${APILINK}/nft/v2/collections/${contract_address}`,
    method: 'PUT',
    data
  })
}

// collection 7D volume
export const getCollectionVolumeList = (contract_address) => {
  return axios({
    url: `${APILINK}/nft/v2/collections/${contract_address}/volume_list`,
    method: 'GET'
  })
}
