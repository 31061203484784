const updateGlobalStore = ({type, data}) => {
  return {
    type,
    data
  }
}

export {
  updateGlobalStore
}
