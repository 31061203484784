let node_env = process.env.NODE_ENV || 'development'
let start_env = process.env.START_ENV || 'staging'
if(start_env === 'prodnets'){
  start_env = 'prod'
}
const AlgoliaConfig = {
  // prod: {
  //   id: 'WCJMATNO74',
  //   key: 'd93c27c681edaadd5128c6364caf7638'
  // },
  // staging: {
  //   id: 'OAUXTWZ49C',
  //   key: '642b28e04d4518fe9b9abb7e1621cfb1'
  // },
  prod: {
    id: '6KQB3MJDC4',
    key: '38149c600acbe9e43a4dd6c20d30b450'
  },
  staging: {
    id: '6KQB3MJDC4',
    key: '38149c600acbe9e43a4dd6c20d30b450'
  },
  testnets: {
    id: 'T4PJWA1R4C',
    key: 'f74bb311f2c2dcb9446acb2814cdbdaa'
  },
}

export default {
  id:  AlgoliaConfig[start_env].id,
  key:  AlgoliaConfig[start_env].key,
}