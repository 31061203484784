import { OpenseaErrorApi, OpenseaApi } from '@/config/config'
import axios from '../axios'

// 获取是否有异常
export const getOpenseaErrorEmbedJson = () => {
  return axios({
    url: `${OpenseaErrorApi}/embed/frame.json`,
    method: 'GET'
  })
}
// 获取异常详细信息
export const getOpenseaErrorEmbedInfo = errorId => {
  return axios({
    url: `${OpenseaErrorApi}/incidents/${errorId}.json`,
    method: 'GET'
  })
}
// 获取opensea 购买信息
export const getOpenseaContractInfo = address => {
  return axios({
    url: `${OpenseaApi}/api/v1/assets?order_direction=desc&asset_contract_address=${address}&limit=1&include_orders=false`,
    method: 'GET'
  })
}
// 获取opensea 购买信息
export const getOpenseaStats = slugName => {
  return axios({
    url: `${OpenseaApi}/api/v1/collection/${slugName}/stats`,
    method: 'GET'
  })
}