import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress';
// css
import './index.less'
import { updateGlobalStore } from '@/store/modules/global/actionCreators'
import { useMetamask } from '@/mixins/metamask'
import { clickReportGa } from '@/utils/utils'

import metamaskLogo from '@/assets/images/metamask_logo.png';
import vipIcon from '@/assets/images/vip-icon.png';
import downCircleIcon from '@/assets/images/down-circle.png';
import membershipIcon from '@/assets/images/navbar/membership.png';
import buymembershipIcon from '@/assets/images/navbar/buymembership.png';
import mybidIcon from '@/assets/images/navbar/mybid.png';
import logoutIcon from '@/assets/images/navbar/logout.png';


const GlobalLoginButton = (props) => {
  const dispatch = useDispatch()
  const metamask = useMetamask()

  const windowClientInfo = useSelector(state => state.global.windowClientInfo)
  const showGlobalNavBarMenu = useSelector(state => state.global.showGlobalNavBarMenu)
  const accountAddress = useSelector(state => state.subscription.accountAddress)
  const isSubscription = useSelector(state => state.subscription.isSubscription)
  const subscriptionAt = useSelector(state => state.subscription.subscriptionAt)
  const accountloadingEnd = useSelector(state => state.subscription.getAccountAddressEnd)
  const accountInvitedInfo = useSelector(state => state.subscription.accountInvitedInfo)
  /**
   * init
   */
  useEffect(() => {

  }, [])

  const metamaskMenuClick = (command) => {
    if(command === 'subscription'){
      window.open(`/subscription`)
    }else if(command === 'logout'){
      metamask.logoutMetamask()
    } else if(command === 'mybid'){
      window.open('https://opensea.io/account?tab=bids_made')
    }
  }
  const showAddress = useMemo(() => {
    if(!accountAddress){
      return ''
    }
    let text = `${accountAddress.slice(0,4)}...${accountAddress.slice(accountAddress.length-4)}`;
    return text.toUpperCase()
  },[accountAddress])


  return (
    <div className="global-navbar-metamask-button flex-c" onClick={(e) => {
      if(windowClientInfo.width <= 750){
        e.stopPropagation()
        dispatch(
          updateGlobalStore({
            type: 'updateShowGlobalNavBarMenu',
            data: !showGlobalNavBarMenu
          })
        )
      }
    }}>
      {
        !accountloadingEnd ? <div className='loading-box'> <CircularProgress color="loading" /> <span className="m-l-10">Loading</span></div>: !accountAddress ?
        <Button className="login-button" onClick={()=>{
          metamask.connectMetamask('navbar')
          window.open('https://discord.gg/Tt3THvstpg')
        }}>
          <span className="font_b font_14">Sign in</span>
        </Button>
        :
        <>
          <div className="metamask-button flex-c">
            <div className="metamask-icon">
              <img className="wallet-icon" src={metamaskLogo} />
              { isSubscription && <img className="vip" src={vipIcon} alt="" /> }
            </div>
            <div className="address">{ showAddress }</div>
            <img className="down-circle" src={downCircleIcon} alt="" />
          </div>
          {windowClientInfo.width > 750 ?
            <div className="menu-body">
              { isSubscription && (accountInvitedInfo && !accountInvitedInfo.channel) ?
                <div className="menu-membership">
                  <div className="member-title">Subscription active until</div>
                  <div className="member-date">
                    <img className="icon" src={membershipIcon} alt="" />
                    {moment(subscriptionAt).format('MMM DD, YYYY')}
                  </div>
                </div>
                : null
              }
              <div className="menu-list">
                <div className="menu-list-item" ga-event-data="wallet_subscription//100" onClick={()=>{metamaskMenuClick('subscription')}}>
                  <img className="icon" src={buymembershipIcon} alt="" /> Buy Subscription
                </div>
                <div className="menu-list-item" ga-event-data="wallet_my_bid//100" onClick={()=>{metamaskMenuClick('mybid')}}>
                  <img className="icon" src={mybidIcon} alt="" /> My Bid
                </div>
                <div className="menu-list-item" ga-event-data="wallet_logout//100" onClick={()=>{metamaskMenuClick('logout')}}>
                  <img className="icon" src={logoutIcon} alt="" /> Log Out
                </div>
              </div>

            </div>
            : null
          }
        </>
      }
    </div>
  )

}

export default GlobalLoginButton
