import React, { useState, useEffect, useRef, useMemo }  from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate} from 'react-router-dom'
import moment from 'moment';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';

import './index.less'
import api from '@/api'
import { IMAGEURL } from '@/config/config'
import { filterSupply, filterTraitTitle, timeFilter, filterFormatGwei, filterShowAddress, filterFloorValue, filterFormatDuration } from '@/utils/filter'
import { networkNameMap, openseaLink } from '@/utils/opensea'
import { updateNftsStore } from '@/store/modules/nfts/actionCreators'
import { updateGlobalStore } from '@/store/modules/global/actionCreators'
import { updateSubscriptionStore } from '@/store/modules/subscription/actionCreators'
import { BigNumber } from "bignumber.js";
import useMessage from '@/components/global/message/message';
import { useMetamask } from '@/mixins/metamask'
import { useWeb3 } from '@/mixins/web3'
import { clickReportGa } from '@/utils/utils'

import etherscan_normal from '@/assets/images/newLogo/etherscan_normal.png';
import etherscan_active from '@/assets/images/newLogo/etherscan_active.png';
import opensea_normal from '@/assets/images/newLogo/opensea_normal.png';
import opensea_active from '@/assets/images/newLogo/opensea_active.png';
import opensea_white from '@/assets/images/newLogo/opensea_white.png';
import x2y2_normal from '@/assets/images/newLogo/x2y2_normal.png';
import x2y2_active from '@/assets/images/newLogo/x2y2_active.png';
import x2y2_white from '@/assets/images/newLogo/x2y2_white.png';
import element_normal from '@/assets/images/newLogo/element_normal.png'
import element_active from '@/assets/images/newLogo/element_active.png'
import element_white from '@/assets/images/newLogo/element_white.png';
import ethIcon from '@/assets/images/eth.png';
import looksrare_white from '@/assets/images/newLogo/looksrare_white.png';
import looksrare_normal from '@/assets/images/newLogo/looksrare_normal.png';
import looksrare_active from '@/assets/images/newLogo/looksrare_active.png';
import monadIcon from '@/assets/images/monad.png';
import wethIcon from '@/assets/images/weth.png';
import apeIcon from '@/assets/images/ape.png';
import tipsIcon from '@/assets/images/global/tips-icon.png';
import gem_logo from '@/assets/images/newLogo/gem_logo.svg';
import genie_logo from '@/assets/images/newLogo/genie_logo.svg';

import axios from 'axios';

import { ethers, BigNumberish, constants, PayableOverrides, utils } from 'ethers';
import { LooksRareExchangeAbi } from "@looksrare/sdk";
import x2y2Abi from '@/assets/json/abi/x2y2.json'
import openseaSeaportAbi from '@/assets/json/abi/opensea-seaport.json'
import elementAbi from '@/assets/json/abi/element.json'


const orderItemParamType = `tuple(uint256 price, bytes data)`
const feeParamType = `tuple(uint256 percentage, address to)`
const settleDetailParamType = `tuple(uint8 op, uint256 orderIdx, uint256 itemIdx, uint256 price, bytes32 itemHash, address executionDelegate, bytes dataReplacement, uint256 bidIncentivePct, uint256 aucMinIncrementPct, uint256 aucIncDurationSecs, ${feeParamType}[] fees)`
const settleSharedParamType = `tuple(uint256 salt, uint256 deadline, uint256 amountToEth, uint256 amountToWeth, address user, bool canFail)`
const orderParamType = `tuple(uint256 salt, address user, uint256 network, uint256 intent, uint256 delegateType, uint256 deadline, address currency, bytes dataMask, ${orderItemParamType}[] items, bytes32 r, bytes32 s, uint8 v, uint8 signVersion)`
const runInputParamType = `tuple(${orderParamType}[] orders, ${settleDetailParamType}[] details, ${settleSharedParamType} shared, bytes32 r, bytes32 s, uint8 v)`


const DialogNftDetailsWrapper = props => {
  const dispatch = useDispatch()
  const message = useMessage()
  const navigate = useNavigate()
  const metamask = useMetamask()
  const _web3 = useWeb3()

  // const collectionFloorPrice = useSelector(state => state.global.collectionFloorPrice)
  const assetsDetail = useSelector(state => state.global.assetsDetail)
  const marketCapDetail = useSelector(state => state.global.marketCapDetail)
  const nftDetailsDialogOptions = useSelector(state => state.global.nftDetailsDialogOptions)
  const successfulTransactionData = useSelector(state => state.global.successfulTransactionData)
  const gasFeeList = useSelector(state => state.global.gasFeeList)

  const accountAddress = useSelector(state => state.subscription.accountAddress)
  const isSubscription = useSelector(state => state.subscription.isSubscription)

  const buyGasData = useSelector(state => state.global.buyGasData)
  const originFilterTraitsData = useSelector(state => state.nfts.originFilterTraitsData)
  const listingData = useSelector(state => state.nfts.listingData) // 所有项目listing数据

  const openseaAssets = useSelector(state => state.global.openseaAssets)

  const [listingsPrice, setListingsPrice] = useState('') // listing价格
  const listingsPriceRef = useRef('')
  const [profitPercent, setProfitPercent] = useState(30) // 预期收益率
  const profitPercentRef = useRef(30)

  const [traitsObj, setTraitsObj] = useState(null)

  const [nftDetails, setNftDetails] = useState(null) // NFT 详情
  const [nftTransactionList, setNftTransactionList] = useState(null) // NFT 交易历史
  const [nftMintedData, setNftMintedData] = useState(null) // NFT Minted 记录
  const [collectionName, setCollectionName] = useState('')
  const [collectionRoyalty, setCollectionRoyalty] = useState(null)
  const [contractAddress, setContractAddress] = useState(null) // 合约地址
  const [tokenId, setTokenId] = useState(null) // Token ID
  const [dialogVisible, setDialogVisible] = useState(false) // 控制 dialog 显示隐藏

  const [contentHeight, setContentHeight] = useState(false) // 一列宽度
  const [traitsHeight, setTraitsHeight] = useState(false) //

  const [nftDetailsLoading, setNftDetailsLoading] = useState(true)
  const [nftTransactionLoading, setNftTransactionLoading] = useState(true)
  const [ntfTraitsLoading, setNtfTraitsLoading] = useState(true)
  const [buyStatus, setBuyStatus] = useState(null) //pending fail success
  const [buyDescription, setBuyDescription] = useState(true)
  const [rarest, setRarest] = useState(1000) // 稀有度最大
  const [rangeImageHost, setRangeImageHost] = useState(1000) // 未reveal的图片url

  const [floorPriceMap, setFloorPriceMap] = useState({}) // 各类 traits 下的 floor price
  const [targetProfitMap, setTargetProfitMap] = useState([10, 30, 50, 100])
  const [maxTraitsFloorPrice, setMaxTraitsFloorPrice] = useState(0)

  const [pendingList, setPendingList] = useState([])
  const [origin, setOrigin] = useState(null)
  const [ownerTransactionData, setOwnerTransactionData] = useState({
    maxPriorityFee: '',
    maxGas: '',
    hash: '',
    timestamp: 0
  })
  const [nowSecond, setNowSecond] = useState(0)
  const [timer, setTimer] = useState(null)
  const [showChannelBuyTips, setShowChannelBuyTips] = useState(false)
  const [openDetailChannel, setOpenDetailChannel] = useState('opensea')
  const [openDetailChannelPrice, setOpenDetailChannelPrice] = useState({})//默认价格
  
  const nftAvatarRef = useRef()
  const calculatorRef = useRef()
  const nftDetailsRef = useRef()
  const reportOrderId = useRef('') //订单操作日志id
  const collectionRoyaltyRef = useRef(0)
  
  // const existStatus = useSelector(state => state.nfts.existStatus) //是否没有项目
  const existStatus = false
  const ownerTransactionDataRef = useRef({
    maxPriorityFee: '',
    maxGas: '',
    hash: '',
    timestamp: 0
  })
  const timerRef = useRef(null)
  useEffect(() => {
    ownerTransactionDataRef.current = ownerTransactionData
  }, [ownerTransactionData])
  useEffect(()=>{
    listingsPriceRef.current = listingsPrice
  },[listingsPrice])
  useEffect(()=>{
    profitPercentRef.current = profitPercent
  },[profitPercent])
  useEffect(()=>{
    collectionRoyaltyRef.current = collectionRoyalty
  },[collectionRoyalty])

  useEffect(()=>{
    initWindowResize();
    // 页面视口变化，重新获取 内容区域 的高度
    window.addEventListener('resize', () => {
      initWindowResize();
    })
  },[])

  

  useEffect(()=>{
    let { visible, collectionName, collectionRoyalty, contractAddress, tokenId, startBuy, rangeImageHost, pendingTransactionList, origin, openDetailChannel, openDetailChannelPrice, openDetailAllChannelPrice } = nftDetailsDialogOptions
    setDialogVisible(visible)
    if(visible){

      // 直接购买
      if (startBuy) {
        // 分渠道购买
        multipleChannelsFastBuy(openDetailChannel, {
          tokenId,
          contractAddress,
          accountAddress
        }, false)
      }

      // 初始化样式
      setNftDetails({})
      setTimeout(() => {
        initWindowResize();
      }, 200);

      setCollectionName(collectionName)
      setCollectionRoyalty(collectionRoyalty)
      setContractAddress(contractAddress)
      setTokenId(tokenId)
      setRangeImageHost(rangeImageHost || '') //未reveal的图片url
      setPendingList(pendingTransactionList || [])
      setOrigin(origin)
      // 当前是从什么渠道的数据
      setOpenDetailChannel(openDetailChannel || 'opensea')
      // listing价格
      setOpenDetailChannelPrice(openDetailChannelPrice || {})
      // 出现详情弹窗，直接购买
      // if (startBuy) {
      //   // 分渠道购买
      //   multipleChannelsFastBuy(openDetailChannel, {
      //     tokenId,
      //     contractAddress,
      //     accountAddress
      //   }, false)
      // }
      // b4没有的项目要重新设置各个渠道的价格
      if(openDetailAllChannelPrice && Object.keys(openDetailAllChannelPrice).length) {
        setNftDetails({
          ...nftDetailsRef.current,
          ...openDetailAllChannelPrice
        })
      }
    }else{
      resetData()
      if(nftAvatarRef.current){
        setTraitsHeight(nftAvatarRef.current.offsetHeight + 112)
        // content 区域高度 = 头像高度 + 计算器高度 + 按钮高度和其他间距
        setContentHeight( nftAvatarRef.current.offsetHeight + calculatorRef.current.offsetHeight + 132)
      }
    }
  },[nftDetailsDialogOptions])

  useEffect(()=>{
    let html = document.querySelector('html')
    if(contractAddress && dialogVisible){
      initData(nftDetailsDialogOptions)
      html.style.position = 'static'
      html.style.overflow = 'hidden'
    } else {
      html.style.position = 'initial'
      html.style.overflow = 'unset'
      // 添加消息监听
      window.removeEventListener('message', onNerdsPostMessage, false)
    }
    if (dialogVisible) {
      timerRef.current = setInterval(() => {
        setNowSecond(moment().unix())
      }, 1000)
    } else {
      clearInterval(timerRef.current)
    }
  },[contractAddress, dialogVisible])

  useEffect(()=>{
    // 获取到 详情 和 历史交易 后计算默认价格
    if (!nftDetailsLoading && !nftTransactionLoading) {
      startCalculator('percent')
    }
  },[nftDetailsLoading, nftTransactionLoading])

  useEffect(()=>{
    if (!pendingList || !pendingList.length) return
    // 同步自己的交易 hash
    pendingList.forEach(item => {
      if (item.from === accountAddress) {
        setOwnerTransactionData({
          ...ownerTransactionDataRef.current,
          hash: item.hash,
          timestamp: item.timestamp,
        })
      }
    })
  },[pendingList])

  useEffect(()=>{
    if (!successfulTransactionData || !successfulTransactionData.topics) return
    checkPendingTransaction(successfulTransactionData)
  },[successfulTransactionData])

  useEffect(() => {
    if (nftDetails) {
      nftDetailsRef.current = nftDetails
      // 初始化 origin
      if (!nftDetails.base_price && !nftDetails.looksrare_price && !nftDetails.x2y2_price && !nftDetails.element_price) {
        setOrigin('trades')
      } else {
        setOrigin('listing')
      }
    }
  }, [nftDetails])

  // 初始化 所有数据
  const initData = () => {
    if(contractAddress){
      if(existStatus) {
        // 获取 NFT 详情
        getNftdetails()
        // 整理 Traits 数据
        getTraitsInfo()
      } else {
        setNtfTraitsLoading(true)
        setNftDetailsLoading(true)
        const nftnerdsWindow = window.frames['nftnerdsCollectioniframe']
        if(location.pathname.includes('/snipe') || !traitsObj){
          nftnerdsWindow && nftnerdsWindow.contentWindow.postMessage({target: 'nftnerds-B4-openseaItmeTraits', data: {token: nftDetailsDialogOptions.tokenId, address: nftDetailsDialogOptions.contractAddress}}, "*")
        }else{
          nftnerdsWindow && nftnerdsWindow.contentWindow.postMessage({target: 'nftnerds-B4-getItemTraits', data: {id: nftDetailsDialogOptions.tokenId, contractAddress}}, "*")
        }
        // 添加消息监听
        window.addEventListener('message', onNerdsPostMessage, false)
      }
      // 获取 NFT 历史交易
      getNftTransaction()
      // 获取 NFT Minted 记录
      getNftMinted()
    }
  }
  // 收到nerds消息
  const onNerdsPostMessage = (event) => {
    if (event.data.target === 'nftnerds-Nerds-ItmeTraits') {
      setNtfTraitsLoading(false)
      setNftDetailsLoading(false)
      const {data} = event.data
      let rankMaptemp = window.rankMaptemp || {}
      setNftDetails({
        ...nftDetailsRef.current,
        traits_arr: data.traits_arr,
        token_id: tokenId || nftDetailsDialogOptions.tokenId,
        rank: nftDetailsDialogOptions.rank || (!!rankMaptemp ? rankMaptemp[tokenId]?.rank : 0) || 0
      })
      setTraitsObj(data.traits_obj)
      if(data.collectionRoyalty){
        setCollectionRoyalty(data.collectionRoyalty)
      }
    }
  }
  // 重置数据
  const resetData = () => {
    setNftDetails(null)
    setNftTransactionList(null)
    setListingsPrice('')
    setProfitPercent(30)
    setBuyStatus(null)
    setBuyDescription(null)
    setShowChannelBuyTips(false)
    reportOrderId.current = ''
  }
  // 计算宽高
  const initWindowResize = () => {
    // 获取弹窗有效高度（依据左侧列表 column-left）
    // traits 列表高度 = 头像高度 + 按钮高度和其他间距
    if(nftAvatarRef.current){
      setTraitsHeight(nftAvatarRef.current.offsetHeight + 112)
      // content 区域高度 = 头像高度 + 计算器高度 + 按钮高度和其他间距
      setContentHeight( nftAvatarRef.current.offsetHeight + calculatorRef.current.offsetHeight + 132)
    }
  }

  // 设置弹窗显示或隐藏
  const toggleDialog = (visible) => {
    setDialogVisible(visible)
    dispatch(updateGlobalStore({
      type: 'updateNftDetailsDialogOptions',
      data: {
        collectionName:null,
        collectionRoyalty:null,
        contractAddress:null,
        tokenId:null,
        visible:false
      }
    }))
  }

  // 修改 profit
  const changeProfit = (val) => {
    clickReportGa({
      name: 'item_detail_cal_shortcut',
      label: `${val}%`,
      value: 100
    })
    setProfitPercent(val)
    setTimeout(() => {
      startCalculator('percent')
    }, 200);
  }

  const changeListingsPrice = () => {
    clickReportGa({
      name: 'item_detail_cal_shortcut',
      label: 'top trait floor',
      value: 100
    })
    console.log('maxTraitsFloorPrice', maxTraitsFloorPrice)
    setListingsPrice(maxTraitsFloorPrice)
    setTimeout(() => {
      startCalculator('price')
    }, 200);
  }

  // 跳转页面
  const assetsFilter = (obj) => {
    clickReportGa({
      name: 'item_detail_trait',
      label: obj.id,
      value: 100
    })
    window.open(`${window.location.origin}/collection/${contractAddress}/items?page=1&traits=${obj.id}`)
  }

  // 获取 NFT 详情
  const getNftdetails = async () => {
    try {
      setNftDetailsLoading(true)
      let res = await api.collection.getAssetInfo(contractAddress, tokenId)
      setNftDetailsLoading(false)
      if (res.code === 1000) {
        let { traits_arr=[] } = res.data
        // res.data.base_price = 0
        // res.data.x2y2_price = 2
        // res.data.looksrare_price = 3
        setNftDetails({
          ...res.data,
          ...openDetailChannelPrice
        })
        clickReportGa({
          name: 'item_detail',
          category: 'View',
          label: `${contractAddress}_${tokenId}`,
          value: 100
        })
        let rare = []
        try {
          rare = traits_arr && traitsObj ? traits_arr.map(item => {
            return traitsObj[item] ? traitsObj[item].pct : 99.9999
          }) : []
        } catch (error) {
          console.log('整理失败', error)
        }
        setRarest(Math.min(...rare))

        // 获取 traits 下的 Floor Price
        getCollectionTraitsFloorPrice(res.data)
      }
    } catch (error) {
      setNftDetailsLoading(false)
      console.log('获取 NFT 详情错误：', error)
    }
  }

  // 整理 Traits 数据
  const getTraitsInfo = async () => {
    let traits = null
    setNtfTraitsLoading(true)
    if(originFilterTraitsData[contractAddress]) {
      traits = JSON.parse(JSON.stringify(originFilterTraitsData[contractAddress]))
    } else {
      let res = await api.collection.getFilterTraitsLable({contract_address: contractAddress})
      if(res.code === 1000) {
        traits = JSON.parse(res.data)
        if(typeof traits === 'string'){
          traits = {}
        }
        dispatch(updateNftsStore({
          type: 'updateOriginFilterTraitsData',
          data: {
            address: contractAddress,
            traits
          }
        }))
      }
    }
    // 整理一批数据
    let temp = {}
    let traitsCount = {}
    if(traits){
      Object.keys(traits).forEach(key => {
        traitsCount[key] = []
        Object.keys(traits[key]).forEach(skey => {
          let data = traits[key][skey]
          temp[data.id] = {
            ...data,
            type: key,
            name: skey
          }
        })
      })
    }
    setNtfTraitsLoading(false)
    // window.traitsObj = temp
    setTraitsObj(temp)
  }

  // 获取 NFT 历史交易
  const getNftTransaction = async () => {
    try {
      setNftTransactionLoading(true)
      let res = await api.collection.getNftTransactionHistory(contractAddress, tokenId, {event_type: 'successful'})
      setNftTransactionLoading(false)
      if (res.code === 1000) {
        setNftTransactionList(res.data.events || [])
      }
    } catch (error) {
      setNftTransactionLoading(false)
      console.log('获取 NFT 历史交易错误：', error)
    }
  }

  // 获取 NFT Minted 记录
  const getNftMinted = async () => {
    try {
      let res = await api.collection.getNftTransactionHistory(contractAddress, tokenId, {event_type: 'mint'})
      if (res.code === 1000) {
        setNftMintedData(res.data.events[0] || {})
      }
    } catch (error) {
      console.log('获取 NFT 历史交易错误：', error)
    }
  }

  // 获取 traits 下的 Floor Price
  const getCollectionTraitsFloorPrice = async (nftDetails) => {
    try {
      let params = nftDetails.base_price ? { price: nftDetails.base_price } : {}
      let res = await api.collection.getCollectionTraitsFloorPrice(contractAddress, params)
      if (res.code === 1000) {

        let arr = res.data.traits_floor_price
        let obj = {}
        arr.forEach(item => {
          obj[item.trait] = item.floor_price
        })
        setFloorPriceMap(obj)

        setTimeout(() => {
          let max = 0
          nftDetails.traits_arr && nftDetails.traits_arr.forEach(item => {
            if (floorPriceMap[item] > max) max = floorPriceMap[item]
          })
          setMaxTraitsFloorPrice(max)
        }, 200)

      }
    } catch (error) {
      console.log('获取 traits 下的 Floor Price 错误：', error)
    }
  }

  // 快速购买 - looksrare
  const looksrareFastBuy = async (options={}) => {
    // 若跨域请求需要带 cookie 身份识别
    axios.defaults.withCredentials = false
    axios.get(`https://api.looksrare.org/api/v1/orders?isOrderAsk=true&collection=${ options.nftContractAddress || contractAddress }&tokenId=${ options.nftTokenId || tokenId }&pagination[first]=150&status[]=VALID&sort=PRICE_ASC`)
    .then(async (res) => {

      // LooksRare api
      // Mainnet: https://api.looksrare.org
      // Rinkeby: https://api-rinkeby.looksrare.org

      // LooksRareExchange
      // Mainnet: 📃 0x59728544b08ab483533076417fbbb2fd0b17ce3a
      // Rinkeby: 📃 0x1AA777972073Ff66DCFDeD85749bDD555C0665dA

      if (!res.data.data || !res.data.data.length) {
        // 创建订单日志
        createLogOrderRecords({
          order: null,
          platform: 'looksrare',
          price: 0,
          status: 3
        })
        // 标记订单
        await api.pay.signOrder(options.nftContractAddress || contractAddress, options.nftTokenId || tokenId)
        setBuyDescription('Order Not Available')
        setBuyStatus('fail')
        return
      }

      // 合约地址
      const looksRareExchangeContract = '0x59728544b08ab483533076417fbbb2fd0b17ce3a'

      // 订单
      const order = res.data.data[0]

      setBuyDescription('Checking Balance')

      // 签名
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()

      // 创建合约实例
      const looksRareContract = new ethers.Contract(looksRareExchangeContract, LooksRareExchangeAbi, signer)

      // 创建 taker 订（购买 NFT 的匹配订单）
      const taker = {
        isOrderAsk: false,
        price: order.price,
        taker: options.accountAddress || accountAddress,
        tokenId: order.tokenId,
        minPercentageToAsk: order.minPercentageToAsk,
        params: '0x'
      }

      // 创建 maker 订单（被匹配的订单）
      const maker = {
        isOrderAsk: true,
        signer: order.signer,
        collection: order.collectionAddress,
        price: order.price,
        tokenId: order.tokenId,
        amount: order.amount,
        strategy: order.strategy,
        currency: order.currencyAddress,
        nonce: order.nonce,
        startTime: order.startTime,
        endTime: order.endTime,
        minPercentageToAsk: order.minPercentageToAsk,
        v: order.v,
        r: order.r,
        s: order.s,
        params: '0x'
      }

      // 创建订单日志
      try {
        createLogOrderRecords({
          order: order || {},
          platform: 'looksrare',
          price: order.price ? order.price * Math.pow(10, -18) : 0,
          status: 0
        })
      } catch (error) {}
      
      // 提示正在拉起钱包
      setBuyDescription('Double Check Price In Metamask')


      // let gasLimit = getGasLimit({
      //   value: order.price,
      //   from: acountAddress,
      //   to: looksRareExchangeContract,
      // })

      // 吊起支付
      let tx = null
      if (buyGasData.gas_mode === 'metamask') {
        tx = await looksRareContract.matchAskWithTakerBidUsingETHAndWETH(taker, maker, {
          gasLimit: 50000,
          value: order.price
        })
      } else {
        tx = await looksRareContract.matchAskWithTakerBidUsingETHAndWETH(taker, maker, {
          value: order.price,
          gasLimit: 50000,
          maxFeePerGas: `0x${((buyGasData.max_fee) * Math.pow(10, 9)).toString(16)}`,
          maxPriorityFeePerGas: `0x${((buyGasData.max_priority_fee) * Math.pow(10, 9)).toString(16)}`,
        })
      }

      // 同步交易哈希
      setOwnerTransactionData({
        ...ownerTransactionDataRef.current,
        hash: tx.hash,
        timestamp: moment().unix()
      })
      await tx.wait()

      // 成功
      setBuyDescription('Purchase Success')
      setBuyStatus('success')
      // getNftdetails()

      // 更新订单日志状态
      updateLogOrderRecords(1)

    }).catch(error => {
      console.log('looksrare 订单购买错误：', error)
      setBuyDescription('Purchase Failed')
      setBuyStatus('fail')

      // 更新订单日志状态
      if (error.message && error.message.includes('User denied transaction signature')) {
        updateLogOrderRecords(6)
      } else{
        updateLogOrderRecords(3)
      }
    })
    setTimeout(() => {
      setShowChannelBuyTips(false)
    }, 5 * 1000)

  }

  // 预估 交易 gas limit
  const getGasLimit = async (params) => {
    try {
      let gasLimit = await web3.eth.estimateGas(params)
      if (gasLimit && gasLimit > 0) return gasLimit * 1.5
      else return null
    } catch (error) {
      return null
    }
  }

  // 快速购买 - x2y2
  const x2y2FastBuy = async (options={}) => {
    try {
      let paramsOrder = {
        contract: options.nftContractAddress || contractAddress,
        token_id: options.nftTokenId || tokenId
      }
      // 获取 x2y2 订单
      let resOrder = await api.pay.getX2y2Order(paramsOrder)

      // 无订单标记
      if (!resOrder.data.length) {
        // 创建订单日志
        createLogOrderRecords({
          order: null,
          platform: 'x2y2',
          price: 0,
          status: 3
        })
        // 标记订单
        await api.pay.signOrder(options.nftContractAddress || contractAddress, options.nftTokenId || tokenId)
        setBuyDescription('Order Not Available')
        setBuyStatus('fail')
        return
      }

      // 有效订单
      let orderData = null
      if (resOrder.code === 1000) {
        orderData = resOrder.data[0]
        setBuyDescription('Checking Balance')

        // 创建订单日志
        try {
          createLogOrderRecords({
            order: orderData || {},
            platform: 'x2y2',
            price: orderData.price ? orderData.price * Math.pow(10, -18) : 0,
            status: 0
          })
        } catch (error) {}

      } else {
        // 创建订单日志
        createLogOrderRecords({
          order: null,
          platform: 'x2y2',
          price: 0,
          status: 3
        })
        setBuyDescription('Order Not Available')
        setBuyStatus('fail')
        setTimeout(() => {
          setShowChannelBuyTips(false)
        }, 5 * 1000)
        return
      }
      
      // 标记订单
      let paramsSign = {
        caller: options.accountAddress || accountAddress,
        op: 1,
        amountToEth: "0",
        amountToWeth: "0",
        items: [
          {
            orderId: orderData.id,
            currency: orderData.currency,
            price: orderData.price,
          }
        ]
      }
      let resSign = {code: 0}
      try {
        resSign = await api.pay.signX2y2Order(paramsSign)
      } catch (error) {
        // 更新订单日志状态
        updateLogOrderRecords(3)
      }
      if (resSign.code !== 1000) {
        setBuyDescription('Purchase Failed')
        setBuyStatus('fail')

        // 更新订单日志状态
        updateLogOrderRecords(3)

        setTimeout(() => {
          setShowChannelBuyTips(false)
        }, 5 * 1000)
        return
      }
      // 提示正在拉起钱包
      setBuyDescription('Double Check Price In Metamask')

      // 整理订单 吊起支付
      let input = resSign.data[0]
      const runInput = decodeRunInput(input.input)
      let value = ethers.BigNumber.from(0)
      let valid = true
      const orders = []
      if (runInput && runInput.orders.length && runInput.details.length) {
        runInput.details.forEach((detail) => {
          const order = runInput.orders[detail.orderIdx.toNumber()]
          const orderItem = order === null || order === void 0 ? void 0 : order.items[detail.itemIdx.toNumber()]
          if (detail.op !== 1 || !orderItem) {
              valid = false;
          }
          else if (isNative(order.currency)) {
              value = value.add(detail.price)
          }
        })
        if (valid) {
          fixSignature(runInput)
          runInput.orders.forEach((order) => fixSignature(order))
          orders.push({ runInput, value })
        }
      }

      // 整理后无有效订单
      if (!orders.length) {
        // 更新订单日志状态
        updateLogOrderRecords(3)
        setBuyDescription('Order Not Available')
        setBuyStatus('fail')
        return
      }

      // 签名
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      // 合约地址
      const x2y2ExchangeContract = '0x74312363e45DCaBA76c59ec49a7Aa8A65a67EeD3'
      // 创建合约实例
      const market = new ethers.Contract(x2y2ExchangeContract, x2y2Abi, signer)

      let txHash = await run(market, orders[0].runInput, orders[0].value)

      console.log('xxxxxx', txHash)

      // 同步交易哈希
      setOwnerTransactionData({
        ...ownerTransactionDataRef.current,
        hash: txHash.hash,
        timestamp: moment().unix()
      })

      // 检查 x2y2 购买结果
      checkHash(txHash)

      // if (txHash) {
      //   // 成功
      //   setBuyDescription('Purchase Success')
      //   setBuyStatus('success')
      //   getNftdetails()
      //   setTimeout(() => {
      //     setShowChannelBuyTips(false)
      //   }, 5 * 1000)
      // }

    } catch (error) {
      console.log('x2y2 获取订单错误：', error)
      // 更新订单日志状态
      if (error.message && error.message.includes('User denied transaction signature')) {
        updateLogOrderRecords(6)
      } else{
        updateLogOrderRecords(3)
      }
      setBuyDescription('Purchase Failed')
      setBuyStatus('fail')
      setTimeout(() => {
        setShowChannelBuyTips(false)
      }, 5 * 1000)
    }    

  }

  // 检查状态
  const checkHash = async (hash) => {
    let res = await window.web3.eth.getTransactionReceipt(hash)
    console.log('xxxxxxx', res)
    // pending
    if (!res) return checkHash(hash)
    // sucess
    if (res.status) {
      updateLogOrderRecords(1)
      setBuyDescription('Purchase Success')
      setBuyStatus('success')
    // fail
    } else {
      updateLogOrderRecords(2)
      setBuyDescription('Purchase Failed')
      setBuyStatus('fail')
    }
    setTimeout(() => {
      setShowChannelBuyTips(false)
    }, 5 * 1000)
  }

  const decodeRunInput = (data) => {
    try {
        const result = utils.defaultAbiCoder.decode([runInputParamType], data)
        return result[0]
    }
    catch (ignored) {
        console.log('decodeRunInput error', ignored)
    }
    return undefined
  }

  const isNative = (currency) => {
    return !currency || currency === constants.AddressZero
  }

  const fixSignature = (data) => {
    // in geth its always 27/28, in ganache its 0/1. Change to 27/28 to prevent
    // signature malleability if version is 0/1
    // see https://github.com/ethereum/go-ethereum/blob/v1.8.23/internal/ethapi/api.go#L465
    if (data.v < 27) {
        data.v = data.v + 27
    }
  }

  const run = async (market, runInput, value) => {
    const options = { value }
    try {
        const gasLimit = await market.estimateGas.run(runInput, options)
        options.gasLimit = gasLimit
        if (buyGasData.gas_mode !== 'metamask') {
          options.maxFeePerGas = `0x${((buyGasData.max_fee) * Math.pow(10, 9)).toString(16)}`
          options.maxPriorityFeePerGas = `0x${((buyGasData.max_priority_fee) * Math.pow(10, 9)).toString(16)}`
        }
    }
    catch (ignored) { }
    return market.run(runInput, options)
  }

  // 快速购买
  const fastBuy = async (channelType, options={}, isShowTips) => {

    // 购买中
    setBuyDescription('Double Check Price')
    setBuyStatus('pending')
    isShowTips && setShowChannelBuyTips(true)

    // 设置 购买 NFT 参数
    let params = {
      isFast: true,
      nftTokenId: options.tokenId || tokenId,
      nftContractAddress: options.contractAddress || contractAddress,
      accountAddress: options.accountAddress || accountAddress
    }
    // 清空上报id
    reportOrderId.current = ''

    // 调用购买方法
    let res = null
    let resultDesc = null
    // 不同渠道吊起支付
    if (channelType === 'opensea') {

      res = await openseaFastBuy(params)

      if (res.code === 9999) return

      // 延后，需要传递
      if (res.code === 1000) {
        resultDesc = 'Purchase Success'
        // 更新订单日志状态
        updateLogOrderRecords(1)
      } else if(res.code === 3000){
        resultDesc = 'Order Not Available'
        // 更新订单日志状态
        updateLogOrderRecords(3)
        // 下架 listing
        await api.pay.signOrder(options.contractAddress || contractAddress, options.tokenId || tokenId)
      } else if(res.code === 4000){
        resultDesc = 'Balance of Wallet Not Enough'
        // 更新订单日志状态
        updateLogOrderRecords(4)
      } else if (res.code === 5000) {
        resultDesc = 'Item Belongs to Your Wallet'
        // 更新订单日志状态
        updateLogOrderRecords(5)
      } else if (res.code === 6000) {
        resultDesc = 'Purchase Failed'
        updateLogOrderRecords(6)
      } else{
        resultDesc = 'Purchase Failed'
        // 更新订单日志状态
        updateLogOrderRecords(3)
      }

      // 购买结束
      setBuyDescription(resultDesc)
      setBuyStatus(res.code === 1000 ? 'success' : 'fail')
      // 购买成功，重新获取详情
      // if(res.code === 1000){
      //   getNftdetails();
      // }
      // // 更新订单日志状态
      // updateLogOrderRecords(resultDesc)
      setTimeout(() => {
        setShowChannelBuyTips(false)
      }, 5 * 1000)

    } else if (channelType === 'looksrare') {
      looksrareFastBuy(params)
    } else if (channelType === 'x2y2') {
      x2y2FastBuy(params)
    } else if (channelType === 'element') {
      elementFastBuy(params)
    }

  }

  // element 购买
  const elementFastBuy = async (options) => {
    try {
      
      // 不存在 element SDK
      // if (!window.$element) {
      //   setBuyDescription('Purchase Failed')
      //   setBuyStatus('fail')
      //   return
      // }

      // 同步购买描述
      setBuyDescription('Checking Balance')

      let paramsOrder = {
        asset_contract_address: options.nftContractAddress || contractAddress,
        token_ids: options.nftTokenId || tokenId
      }

      // let paramsOrder = {
      //   asset_contract_address: '0x8ff7e67ee1aea1d59e0fd97ac890e3645a01067b',
      //   token_ids: 2598
      // }

      // 获取 element 订单
      let resOrder = await api.pay.getElementOrder(paramsOrder)

      let orderData = null
      // 筛选有效 order
      if (resOrder && resOrder.data && resOrder.data.orders && resOrder.data.orders.length) {
        let orders = resOrder.data.orders
        orders = orders.filter(item => item.assetTokenId == (options.nftTokenId || tokenId))
        orderData = orders[orders.length - 1]
      }
      // 无订单
      else {
        // 创建订单日志
        createLogOrderRecords({
          order: null,
          platform: 'element',
          price: 0,
          status: 3
        })
        // 标记订单
        await api.pay.signOrder(options.nftContractAddress || contractAddress, options.nftTokenId || tokenId)
        setBuyDescription('Order Not Available')
        setBuyStatus('fail')
        setTimeout(() => {
          setShowChannelBuyTips(false)
        }, 5 * 1000)
        return
      }

      // 有订单
      // let orderData = resOrder.data.orders[resOrder.data.orders.length - 1]

      // 记录购买日志
      createLogOrderRecords({
        order: orderData || {},
        platform: 'element',
        price: orderData.basePrice ? orderData.basePrice * Math.pow(10, -18) : 0,
        status: 0
      })


      // 同步购买描述
      setBuyDescription('Double Check Price In Metamask')

      // 整理订单
      const { order, sig } = analyseOrder(orderData)
      // console.log('uuuuuuuuuuuuuuuuu  999', order, sig)

      // element 合约
      const elementContract = '0x20F780A973856B93f63670377900C1d2a50a77c4'

      // element 合约实例
      const elementEx = new window.web3.eth.Contract(elementAbi, elementContract)

      // 获取 input data
      let inputData = elementEx.methods.buyERC721(order, sig).encodeABI()
      // console.log('uuuuuuuuuuuuuuuuu  101010', inputData)

      // 整理 transaction 需要的参数
      let params = null
      const nonce = await web3.eth.getTransactionCount(accountAddress, "latest")
      params = {
        to: elementContract,
        from: accountAddress,
        value: orderData.basePrice,
        data: web3.utils.toHex(inputData),
        nonce: nonce
      }

      // 是否自定义 gas
      if (buyGasData.gas_mode !== 'metamask') {
        params.maxFeePerGas = `0x${((buyGasData.max_fee) * Math.pow(10, 9)).toString(16)}`
        params.maxPriorityFeePerGas = `0x${((buyGasData.max_priority_fee) * Math.pow(10, 9)).toString(16)}`
      }

      // 吊起钱包
      let transactionHash = await sendTransaction(params)
      console.log('transactionHash', transactionHash)

      // 同步交易哈希
      setOwnerTransactionData({
        ...ownerTransactionDataRef.current,
        hash: transactionHash,
        timestamp: moment().unix()
      })

      // 检查 购买结果
      checkHash(transactionHash)

      // let params = {}
      // params.order = orderData
      // // 是否自定义 gas
      // if (buyGasData.gas_mode !== 'metamask') {
      //   params.maxFeePerGas = `0x${((buyGasData.max_fee) * Math.pow(10, 9)).toString(16)}`
      //   params.maxPriorityFeePerGas = `0x${((buyGasData.max_priority_fee) * Math.pow(10, 9)).toString(16)}`
      // }

      // // 购买
      // const txHash = await window.$element.fillOrder(params)
      // console.log('uuuuuu', txHash)
      // // 同步交易哈希
      // setOwnerTransactionData({
      //   ...ownerTransactionDataRef.current,
      //   hash: txHash,
      //   timestamp: moment().unix()
      // })

      // // 检查 购买结果
      // checkHash(txHash)

      // console.log('txHash', txHash)

    } catch (error) {
      console.log('error', error)
      setBuyDescription('Purchase Failed')
      setBuyStatus('fail')
      // 更新订单日志状态
      if (error.message && error.message.includes('User denied transaction signature')) {
        updateLogOrderRecords(6)
      } else{
        updateLogOrderRecords(3)
      }
    }

  }

  // 整理订单
  const analyseOrder = (obj) => {
    const expiry = "0x" +
        formatNumber(obj.saleKind, 4) +
        formatNumber(0, 156) +
        formatNumber(obj.extra, 32) +
        formatNumber(obj.listingTime, 32) +
        formatNumber(obj.expirationTime, 32)
    const fees = toStandardFees(obj.fees)
    const erc20Token = obj.paymentToken.toLowerCase() == '0x0000000000000000000000000000000000000000'
        ? '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
        : ethers.utils.getAddress(obj.paymentToken)
    const erc20TokenAmount = calcPayERC20Amount(obj.basePrice, fees)
    const order = {
        maker: ethers.utils.getAddress(obj.maker),
        taker: ethers.utils.getAddress(obj.taker),
        expiry: expiry,
        nonce: toNumStr(obj.nonce, 10),
        erc20Token: erc20Token,
        erc20TokenAmount: erc20TokenAmount,
        fees: fees,
        hashNonce: toNumStr(obj.hashNonce, 10)
    }
    if (obj.schema == 'ERC721') {
      order.nft = ethers.utils.getAddress(obj.assetContract)
      order.nftId = toNumStr(obj.assetTokenId, 10)
      if (new BigNumber.default(obj.side).eq('BuyOrder')) {
        order.nftProperties = toStandardProperties(obj.properties)
      }
    }
    else if (obj.schema == 'ERC1155') {
      order.erc1155Token = ethers.utils.getAddress(obj.assetContract)
      order.erc1155TokenId = toNumStr(obj.assetTokenId, 10)
      order.erc1155TokenAmount = toNumStr(obj.quantity, 10)
      if (new BigNumber.default(obj.side).eq('BuyOrder')) {
        order.erc1155TokenProperties = toStandardProperties(obj.properties)
      }
    }
    return {
      order: order,
      sig: {
        signatureType: obj.signatureType,
        v: obj.v,
        r: obj.r,
        s: obj.s
      }
    }
  }
  const formatNumber = (num, bitCount) => {
    BigNumber.default.config({ EXPONENTIAL_AT: 1024 })
    const hexStr = new BigNumber.default(num).toString(16)
    return formatHexBytes(hexStr, bitCount)
  }
  const formatHexBytes = (hexStr, bitCount) => {
    const count = bitCount / 4
    const str = hexStr.toLowerCase().startsWith("0x") ? hexStr.substring(2).toLowerCase() : hexStr.toLowerCase();
    if (str.length > count) {
        return str.substring(str.length - count)
    }
    let zero = ''
    for (let i = str.length; i < count; i++) {
        zero += '0'
    }
    return zero + str
  }
  const toStandardFees = (fees) => {
    if (fees != undefined) {
        return fees.map(fee => ({
            recipient: ethers.utils.getAddress(fee.recipient),
            amount: toNumStr(fee.amount, 10),
            feeData: fee.feeData,
        }))
    }
    return []
  }
  const toNumStr = (num, base) => {
    BigNumber.default.config({ EXPONENTIAL_AT: 1024 })
    return new BigNumber.default(num).toString(base)
  }
  const calcPayERC20Amount = (totalTokenAmount, fees) => {
    let payAmount = new BigNumber.default(totalTokenAmount)
    for (let i = 0; i < fees.length; i++) {
        payAmount = payAmount.minus(fees[i].amount)
    }
    BigNumber.default.config({ EXPONENTIAL_AT: 1024 })
    return payAmount.toString(10)
  }
  const toStandardProperties = (properties) => {
    if (properties) {
        return properties.map(property => ({
          propertyValidator: ethers.utils.getAddress(property.propertyValidator),
          propertyData: property.propertyData
        }))
    }
    return []
  }

  // 检查当前账户钱包余额是否大于当前 NFT Price
  const checkBalance = async (options) => {
    try {
      // 账户钱包余额
      let account = options.accountAddress || accountAddress
      let res = await window.ethereum.request({
        method: 'eth_getBalance',
        jsonrpc: "2.0",
        params: [account, 'latest']
      })
      // 转换值
      let balance = Number(web3.utils.fromWei(res,'ether'))
      // 获取 NFT Price
      let nftPrice = nftDetailsDialogOptions.nowNftPrice || nftDetails.base_price
      // 对比
      return balance > nftPrice
    } catch (error) {
      console.log('检查余额错误：', error)
    }
  }

  // 跳转到不同渠道的 NFT 详情页
  const toChannelNftDetail = (channel) => {
    let url = toLink(channel)
    window.open(url)
  }

  // 多渠道购买
  const multipleChannelsFastBuy = async (channel, options={}, isShowTips) => {


    // let res = await api.pay.getElementOrder({
    //   asset_contract_address: '0x8ff7e67ee1aea1d59e0fd97ac890e3645a01067b',
    //   token_ids: 2598
    // })

    // console.log('channelchannelchannel', channel)


    // const res = await window.$element.queryOrders({
    //   asset_contract_address: '0x8ff7e67ee1aea1d59e0fd97ac890e3645a01067b',
    //   token_id: 2598
    // })
    // console.log('res', res)
   
    // const transactionReceipt = await window.$element.fillOrder({
    //   order: res.orders[res.orders.length - 1]
    // });

    // console.log('transactionReceipt', transactionReceipt)

    // return

    if (buyStatus === 'success') return

    /**
     * 各种购买校验
     */
    // 登录检查
    if (!accountAddress) {
      connectMetamask()
      message.error('Please connect to wallet')
      return
    }

    try {
      let { status } = await unlockMetamask();
      if(!status){
        setTimeout(() => {
          message.error('Please connect to wallet')
        }, 500);
        return
      }
    } catch (error) {}

    // 快速购买 && 没有订阅
    if(!isSubscription){
      setDialogVisible(false)
      navigate('/subscription')
      return
    }

    // 判断网络是否正常
    let errorTips = await _web3.checkNetworkError()
    if(!!errorTips){
      message.error(errorTips)
      return
    }

    // 余额判断
    let check = await checkBalance(options)
    if (!check) {
      setBuyDescription('Balance of Wallet Not Enough')
      setBuyStatus('fail')
      isShowTips && setShowChannelBuyTips(true)
      // 创建订单日志
      createLogOrderRecords({
        order: null,
        platform: channel,
        price: 0,
        status: 4
      })
      return
    }

    // 按不同渠道吊起支付
    fastBuy(channel, options, isShowTips)
    // 改变 owner pending 列表
    changeOwnerTransaction()
  }

  const changeOwnerTransaction = () => {
    setOwnerTransactionData({
      ...ownerTransactionDataRef.current,
      maxPriorityFee: buyGasData.max_priority_fee,
      maxGas: buyGasData.max_fee,
    })
  }

  const toEtherscan = (hash) => {
    let url = `https://etherscan.io/tx/${hash}`
    window.open(url)
  }

  // 跳转到 opensea 详情页面
  const toOpensea = (report) => {
    if (report) {
      clickReportGa({
        name: 'item_detail_offer',
        label: `${contractAddress}_${tokenId}`,
        value: 100
      })
    }
    let url = `${openseaLink }/assets/${contractAddress }/${tokenId }`
    window.open(url)
  }

  // 点击某一行跳转页面
  const toLinkByRow = (item) => {
    let platform = ''
    if(item.platform.includes('looksrare')) {
      platform = 'looksrare'
    } else if(item.platform.includes('opensea')) {
      platform = 'opensea'
    } else if(item.platform.includes('x2y2')) {
      platform = 'x2y2'
    } else if(item.platform.includes('element')) {
      platform = 'element'
    }
    if(!!platform) {
      return toLink(platform)
    }
    return ''
  }

  // 跳转到 opensea 钱包页面
  const toOpenseaWallet = (address) => {
    let url = `${ openseaLink }/${ address }`
    window.open(url)
  }

  // 跳转外链
  const toLink = (type) => {
    let detail = assetsDetail || {}
    const { contract_address, slug_name, external_link, twitter_username, discord } = detail
    let url = null
    switch (type) {
      case 'etherscan': {
        if (nftTransactionList && nftTransactionList.length){
          url = `https://etherscan.io/tx/${ nftTransactionList[0].transaction_hash }`
        } else {
          url = `https://etherscan.io/address/${ contractAddress }`
        }
        break;
      }
      case 'opensea': url = `${ openseaLink }/assets/${ contractAddress }/${ tokenId }`; break;
      case 'external': url = external_link; break;
      case 'discord': url = discord; break;
      case 'twitter': url = `https://twitter.com/${ twitter_username }`; break;
      case 'x2y2': url = `https://x2y2.io/eth/${ contractAddress }/${ tokenId }`; break;
      case 'looksrare': url = `https://looksrare.org/collections/${ contractAddress }/${ tokenId }`; break;
      case 'element': url = `https://www.element.market/assets/${ contractAddress }/${ tokenId }`; break;
    }
    return url
  }

  //
  const checkPendingTransaction = (log) => {
    // 解码出 用户钱包地址 和 NFT token id
    let userAddress = '0x' + log.topics[2].slice(26)
    let nftTokenId = ''
    try {
      nftTokenId = window.web3.utils.hexToNumber(log.topics[3])
    } catch (error) {
      
    }

    // console.log('用户钱包 和 token id：',userAddress, nftTokenId)

    // check 是否是这个 token id 的 transaction
    if (nftTokenId != tokenId) return

    // 监听到当前账户 正在弹窗里出发的购买 并且监听到交易完成
    if ( (accountAddress.toLowerCase() === userAddress.toLowerCase()) && (nftTokenId == tokenId) && (buyStatus === 'pending') ) {
      setBuyDescription('Purchase Success')
      setBuyStatus('success')
      // getNftdetails()
      // 标记成功的 transaction
      setOwnerTransactionData({
        ...ownerTransactionDataRef.current,
        successful: true
      })
      setTimeout(() => {
        setShowChannelBuyTips(false)
      }, 5 * 1000)
      // 更新订单日志状态
      updateLogOrderRecords(1)
    }

    // check (owner)
    if (ownerTransactionDataRef.current.hash && (accountAddress.toLowerCase() === userAddress.toLowerCase())) {
        // 标记成功的 transaction
        setOwnerTransactionData({
          ...ownerTransactionDataRef.current,
          successful: true
        })
        // 关闭监听
        // collectionPendingiframe = ''
        // 关闭订阅 logs
        // closeLogsSubscribe()
      return
    }

    // check
    pendingList.forEach((item, index) => {
      // pending 列表里有 transaction 完成了交易
      if (item.from.toLowerCase() === userAddress.toLowerCase()) {
        // 标记成功的 transaction
        let newPendingList = JSON.parse(JSON.stringify(pendingList))
        newPendingList[index].successful = true
        setPendingList(newPendingList)
        // 关闭监听
        // collectionPendingiframe = ''
        // 关闭订阅 logs
        // closeLogsSubscribe()
      }
    })

  }
  // 检查是否锁定，如果锁定，授权
  const unlockMetamask = async () => {
    // return {status: true}
    console.log('unlockMetamask 1=>>')
    try {
      let ethAccounts = await window.ethereum.request({
        method: 'eth_accounts',
        jsonrpc: "2.0",
        params: [],
      })
      console.log('ethAccounts=>>', ethAccounts)
      if (!ethAccounts.length) {
        // console.log(connectMetamask)
        let res = await connectMetamask();
        // let res = {code:1000}
        return {
          status: res.code === 1000
        }
      }else{
        return {status: true}
      }
    } catch (error) {
      console.log('eth_accounts error=>>', error)
      return {status: false}
    }
  }

  // 不同类型去购买
  const channelFastBuy = (channel) => {
    clickReportGa({
      name: 'item_detail_buy',
      label: `${contractAddress}_${tokenId}`,
      value: 100
    })
    let url = toLink(channel)
    window.open(url)
  }

  // 取 详情价格，没有就取 最近历史价格，没有就取 地板价
  const currentNftPrice = useMemo(() => {
    if (!nftDetails || !nftDetails.token_id){
      // console.log(0, nftDetails)
      return 0
    }
    if (!!nftDetails.base_price && nftDetails.base_price !== 0){
      // console.log(1, nftDetails.base_price)
      return nftDetails.base_price
    }
    else if (nftTransactionList && nftTransactionList.length){
      // console.log(2, nftTransactionList[0].price)
      return nftTransactionList[0].price
    }
    else if (marketCapDetail){
      // console.log(3, marketCapDetail.floor_price)
      return marketCapDetail.floor_price
    }
    else return 0
  },[nftDetails, nftTransactionList, marketCapDetail ])

  // 计算器
  const startCalculator = async (type) => {
    try {

      if (type === 'price' && !listingsPriceRef.current){
        setProfitPercent(null)
        return
      }
      if (type === 'percent' && !profitPercentRef.current){
        setListingsPrice('')
        return
      }

      // 基础参数
      let baseParams = {
        roy: !collectionRoyaltyRef.current ? 0 : collectionRoyaltyRef.current / 10000,
        gas: gasFeeList[0].price,
        price: currentNftPrice
      }
      // 不确定参数
      let uncertaintyParams = null
      if (type === 'price') {
        uncertaintyParams = {listing_price: listingsPriceRef.current}
      } else if (type === 'percent') {
        uncertaintyParams = {earnings_rate: profitPercentRef.current / 100}
      }

      // 接口需要的参数
      let params = {
        ...baseParams,
        ...uncertaintyParams
      }

      // 获取计算结果
      let res = await api.collection.getCalculatorResult(params)
      if (res.code === 1000) {
        if (type === 'price') {
          setProfitPercent(parseFloat((res.data.earnings_rate * 100).toFixed(2)))
        } else if (type === 'percent') {
          setListingsPrice(res.data.listing_price)
        }
      }

    } catch (error) {
      console.log('计算器错误：', error)
    }
  }
  // 最后交易价格
  const lastNftPrice = useMemo(() => {
    if (!nftTransactionList) return 0
    if (nftTransactionList && nftTransactionList.length) return nftTransactionList[0].price
    else return 'Not Traded'
    // marketCapDetail.floor_price
  },[nftTransactionList, marketCapDetail ])

  const profitValue = useMemo(() => {
    return listingsPrice - ( listingsPrice * ((collectionRoyaltyRef.current / 100)) * 0.01) - currentNftPrice
  },[listingsPrice, collectionRoyaltyRef.current, currentNftPrice ])

  // 区分价格
  const channelPriceInfo = useMemo(() => {
    let sortMap = ['element', 'x2y2', 'looksrare', 'opensea']
    // 各渠道价格
    let { x2y2_price=0, looksrare_price=0, base_price=0, element_price=0 } = (nftDetails || {})

    // 价格从高到低排序
    let priceList =  [{channel: 'opensea', price: base_price}, {channel: 'looksrare', price: looksrare_price}, {channel: 'x2y2', price: x2y2_price}, {channel: 'element', price: element_price}].sort((a, b) => b.price-a.price)
   
    // 正在卖的渠道数
    let channelCount = 0
    priceList.forEach(item => {
      item.price && channelCount ++
    })

    // 多渠道价格一样，需要按顺序排列 
    // 可以购买的渠道，如果价格都一样，就按 sortMap 的顺序展示
    if (channelCount > 1) {

      let standard = null
      priceList.forEach(item => {
        item.price && (standard = item.price)
      })
      let isEqual = true
      priceList.forEach(item => {
        (item.price > 0 && item.price != standard) && (isEqual = false)
      })
      if (!!isEqual) {
        let newArr = []
        sortMap.forEach(type => {
          priceList.slice(0, channelCount).forEach(item => {
            if (type === item.channel && item.price > 0) {
              newArr.push(item)
            }
          })
        })
        newArr = [...newArr, ...priceList.slice(channelCount)]
        priceList = newArr
      }
      
      // let standard = priceList[0].price
      // let isEqual = true
      // priceList.forEach(item => {
      //   (item.price != standard) && (isEqual = false)
      // })
      // !!isEqual && (priceList = [{channel: 'element', price: element_price}, {channel: 'x2y2', price: x2y2_price}, {channel: 'looksrare', price: looksrare_price}, {channel: 'opensea', price: base_price}])

      // if (priceList[0].price === priceList[1].price && priceList[0].price === priceList[2].price && priceList[1].price === priceList[2].price) {
      //   priceList = [{channel: 'element', price: element_price}, {channel: 'x2y2', price: x2y2_price}, {channel: 'looksrare', price: looksrare_price}, {channel: 'opensea', price: base_price}]
      // }
    }
    // if (channelCount === 2) {
    //   if (priceList[0].price === priceList[1].price) {
    //     if (priceList[1].channel !== openDetailChannel) {
    //       priceList = [priceList[1], priceList[0], priceList[2]]
    //     }
    //   }
    // } else if (channelCount === 3) {
    //   if (priceList[0].price === priceList[1].price && priceList[0].price === priceList[2].price && priceList[1].price === priceList[2].price) {
    //     priceList = [{channel: 'x2y2', price: x2y2_price}, {channel: 'looksrare', price: looksrare_price}, {channel: 'opensea', price: base_price}]
    //   } else if (priceList[1].price === priceList[2].price) {
    //     if (priceList[2].channel !== openDetailChannel) {
    //       priceList = [priceList[0], priceList[2], priceList[1]]
    //     }
    //   }
    // }

    return {
      channelCount,
      priceList
    }

  },[nftDetails])

  useEffect(() => {
    // 特殊情况 显示三个购买按钮时，需要增加弹窗高度
    if (channelPriceInfo.channelCount > 2) {
      setTimeout(() => {
        setTraitsHeight(nftAvatarRef.current.offsetHeight + 112 + 56)
        // content 区域高度 = 头像高度 + 计算器高度 + 按钮高度和其他间距
        setContentHeight( nftAvatarRef.current.offsetHeight + calculatorRef.current.offsetHeight + 132 + 56)
      }, 200)
    }
  }, [channelPriceInfo.channelCount])

  // 连接
  const connectMetamask = async () =>{
    // 检测是否安装
    let metaMaskInstalled = !!window.ethereum && Boolean(window.ethereum && window.ethereum.isMetaMask) && !!window.web3
    if(!metaMaskInstalled){
      message.error('There was an error connecting to your wallet. Please make sure you have MetaMask installed')
      return {code: 0, message: '没有安装metaMask'}
    }
    try {
      // 获取授权过的账户
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      let account = ''
      if(accounts.length){
        account = accounts[0]
        dispatch(updateSubscriptionStore({
          type: 'updateAccountAddress',
          data: account
        }))
        localStorage.setItem('address', account)
        dispatch(updateSubscriptionStore({
          type: 'updateIsSubscription',
          data: new Date().getTime() + 1000 * 60 * 60
        }))
      }
      return {code: 1000, message: '授权完成', account}
    } catch (error) {
      // console.log('connectMetamask=>>>', error)
      metamask.logoutMetamask()
      return {code: 2000, message: '发生错误'}
    }
  }



  // 购买
  /**
   * 说明：
   * function 非下划线开头的方法为普通方法，作用于此js文件以外调用
   * _function 以下划线开头的方法为内部方法，不希望在此js文件以外调用
   */

  /**
   * opensea-js SDK 购买 nft
   * @param {string} accountAddress  购买 nft 人的钱包地址
   * @param {string} nftContractAddress  nft 的合约地址
   * @param {string} nftTokenId  nft 的 token id
   * @param {Boolean} isFast 是否是 fastbuy
   * @returns {string} 交易哈希值
   */
  const openseaFastBuy = async (options) => {
    try {

      let { accountAddress, nftContractAddress, nftTokenId, isFast } = options

      // 不存在 seaport
      // if (!window.$seaport) return {code: 2000, error: '不存在 seaport'}

      setBuyDescription('Checking Balance')
      // 获取订单
      // let order = await _getOrder(nftContractAddress, nftTokenId)
      let order = await _getOpenseaOrder(nftContractAddress, nftTokenId)

      // 订单错误
      if (order.error) {
        // 创建订单日志
        createLogOrderRecords({
          order: null,
          platform: 'opensea',
          price: 0,
          status: order.code === 5000 ? 5 : 3
        })
        return order
      } 

      // 创建订单日志
      try {
        createLogOrderRecords({
          order,
          platform: 'opensea',
          price: nftDetailsRef.current.base_price,
          status: 0
        })
      } catch (error) { }

      // 订单购买
      const buyResult = await _fulfillOrder(accountAddress, order, buyGasData)

      return buyResult

    } catch (e) {
      console.log(e)
      return {
        code: 0
      }
    }
  }

  const formatCode = (str) => {
    let newStr = ''
    let arr = str.split('_')
    arr.forEach((item, index) => {
        if (index > 0) {
            return newStr += item.replace(item[0], item[0].toUpperCase())
        } else {
            return newStr += item
        }
    })
    return newStr
  }

  // 获取 opensea 订单
  const _getOpenseaOrder = async (contractAddress, tokenId) => {
    try {

      let finalOrder = null
      let nftAsset = null
      // 获取 NFT info
      if (openseaAssets && (openseaAssets.asset_contract.address == contractAddress) && (openseaAssets.token_id == tokenId)) {
        nftAsset = openseaAssets
      } else {
        // nftAsset = await window.$seaport.api.getAsset({
        //   tokenAddress: contractAddress,
        //   tokenId: tokenId
        // })
        const options = {
          method: 'GET',
          url: `https://api.opensea.io/api/v1/asset/${contractAddress}/${tokenId}/`,
          params: {include_orders: 'true'}
        };
        
        let response =  await axios.request(options)
        nftAsset = response.data
      }

      // check owner
      let nftOwnerAddress = nftAsset?.owner?.address || nftAsset?.last_sale?.transaction?.from_account?.address
      if (!!nftOwnerAddress && (nftOwnerAddress.toLowerCase() === accountAddress.toLowerCase())) {
        return {code: 5000, error: '已拥有'}
      }

      // 订单列表
      const nftOrders = nftAsset.seaport_sell_orders || nftAsset.orders

      // search order
      if (nftOrders && nftOrders.length) {
        const order = nftOrders[0]
        // 新版 opensea 订单
        if (order.protocol_address) {
          if (order.side === 'ask' && order.order_type === 'basic') {
            let newOrder = {}
            newOrder.cancelled = order.cancelled
            newOrder.clientSignature = order.client_signature
            newOrder.closingDate = order.closing_date
            newOrder.createdDate = order.created_date
            newOrder.currentPrice = order.current_price
            newOrder.expirationTime = order.expiration_time
            newOrder.finalized = order.finalized
            newOrder.listingTime = order.listing_time
            newOrder.maker = order.maker
            newOrder.makerFees = order.maker_fees
            newOrder.markedInvalid = order.marked_invalid
            newOrder.orderHash = order.order_hash
            newOrder.orderType = order.order_type
            newOrder.protocolAddress = order.protocol_address
            newOrder.protocolData = order.protocol_data
            newOrder.relayId = order.relay_id
            newOrder.side = order.side
            newOrder.taker = order.taker
            newOrder.takerFees = order.taker_fees
            finalOrder = newOrder
          }
        // 旧版 opensea 订单
        } else {
          finalOrder = order
        }
      }

      // check order
      if (finalOrder) {
        return finalOrder
      } else {
        return {code: 3000, error: '不存在订单'}
      }

    } catch (error) {
      console.log('获取 opensea 订单 错误：', error)
      return {code: 3000, error: '不存在订单'}
    }
  }

  // // 获取 opensea 订单
  // const _getOpenseaOrder = async (tokenAddress, tokenId) => {
  //   try {
  //     let finalOrder = null
  //     // 获取 opensea 新版订单
  //     let res = await api.pay.getNftPayOrderNew(tokenAddress, tokenId)
  //     if (res.code === 1000 && JSON.parse(res.data) && JSON.parse(res.data).orders && JSON.parse(res.data).orders.length) {
  //     // if (orders && orders.length) {
  //       let data = JSON.parse(res.data)
  //       let { orders } = data
  //       let order = orders[0]
  //       let newOrder = {}
  //       newOrder.cancelled = order.cancelled
  //       newOrder.clientSignature = order.client_signature
  //       newOrder.closingDate = order.closing_date
  //       newOrder.createdDate = order.created_date
  //       newOrder.currentPrice = order.current_price
  //       newOrder.expirationTime = order.expiration_time
  //       newOrder.finalized = order.finalized
  //       newOrder.listingTime = order.listing_time
  //       newOrder.maker = order.maker
  //       newOrder.makerFees = order.maker_fees
  //       newOrder.markedInvalid = order.marked_invalid
  //       newOrder.orderHash = order.order_hash
  //       newOrder.orderType = order.order_type
  //       newOrder.protocolAddress = order.protocol_address
  //       newOrder.protocolData = order.protocol_data
  //       newOrder.relayId = order.relay_id
  //       newOrder.side = order.side
  //       newOrder.taker = order.taker
  //       newOrder.takerFees = order.taker_fees
  //       finalOrder = newOrder
  //     // 获取 opensea 旧版订单
  //     } else {
  //       let res = await api.pay.getNftPayOrder(tokenAddress, tokenId)
  //       let data = JSON.parse(res.data)
  //       let { orders } = data

  //       if (orders && orders.length) {
  //         let orderArr = []
  //         let nowTime = moment().unix()
  //         // 过滤没过期的 及 付款是eth的
  //         orderArr = orders.filter(item => {
  //           return item.expiration_time >= nowTime && item.payment_token_contract.symbol === 'ETH'
  //         })
  //         // 根据创建时间排序
  //         orderArr = orderArr.sort((a, b)=>{
  //           return b.created_date - a.created_date
  //         })
  //         if (orderArr.length) {
  //           let order = orderArr[0]
  //           let obj = {}
  //           Object.keys(order).forEach(item => {
  //             obj[formatCode(item)] = order[item]
  //           })
  //           obj.asset = undefined
  //           obj.assetBundle = undefined
  //           obj.cancelledOrFinalized = obj.cancelled
  //           obj.feeRecipientAccount = obj.feeRecipient
  //           obj.feeRecipient = obj.feeRecipient.address
  //           obj.hash = obj.prefixedHash
  //           obj.makerAccount = obj.maker
  //           obj.maker = obj.maker.address
  //           obj.takerAccount = obj.taker
  //           obj.taker = obj.taker.address
  //           obj.waitingForBestCounterOrder = false
  //           finalOrder = obj
  //         }
  //       } else {
  //         await api.pay.signOrder(tokenAddress, tokenId)
  //       }
        
  //     }

  //     // 后端接口没获取到有效订单 则获取 opensea 接口 订单
  //     if (finalOrder) {
  //       return finalOrder
  //     } else {
  //       // 获取 nft 的出售订单
  //       const openseaRes = await window.$seaport.api.getAsset({
  //         tokenAddress,
  //         tokenId,
  //       })
  //       // 订单列表
  //       const openseaOrders = openseaRes.b4fomoSeaportSellOrders || openseaRes.sellOrders

  //       if (openseaOrders && openseaOrders.length) {
  //         const order = openseaOrders[0]
  //         // 新版 opensea 订单
  //         if (order.protocol_address) {
  //           let newOrder = {}
  //           newOrder.cancelled = order.cancelled
  //           newOrder.clientSignature = order.client_signature
  //           newOrder.closingDate = order.closing_date
  //           newOrder.createdDate = order.created_date
  //           newOrder.currentPrice = order.current_price
  //           newOrder.expirationTime = order.expiration_time
  //           newOrder.finalized = order.finalized
  //           newOrder.listingTime = order.listing_time
  //           newOrder.maker = order.maker
  //           newOrder.makerFees = order.maker_fees
  //           newOrder.markedInvalid = order.marked_invalid
  //           newOrder.orderHash = order.order_hash
  //           newOrder.orderType = order.order_type
  //           newOrder.protocolAddress = order.protocol_address
  //           newOrder.protocolData = order.protocol_data
  //           newOrder.relayId = order.relay_id
  //           newOrder.side = order.side
  //           newOrder.taker = order.taker
  //           newOrder.takerFees = order.taker_fees
  //           finalOrder = newOrder
  //         // 旧版 opensea 订单
  //         } else {
  //           finalOrder = order
  //         }
  //       }

  //       if (finalOrder) {
  //         return finalOrder
  //       } else {
  //         return {code: 3000, error: '不存在订单'}
  //       }
  //     }
  //   } catch (error) {
  //     console.log('获取订单错误：', error)
  //   }
  // }

  // // 获取订单
  // const _getOrder = async (tokenAddress, tokenId) => {
  //   try {
  //     // let res = await api.pay.getNftPayOrder(tokenAddress, tokenId)
  //     // res = JSON.parse(JSON.stringify(res))
  //     // if (res.code === 2000) {
  //     //   await api.pay.signOrder(tokenAddress, tokenId)
  //     // }
  //     if ( false && res.code === 1000 && JSON.parse(res.data).orders && JSON.parse(res.data).orders.length ) {
  //       let orderData = JSON.parse(res.data)
  //       let orders = orderData.orders

  //       if (!orders.length) {
  //         await api.pay.signOrder(tokenAddress, tokenId)
  //       }

  //       if (orders && orders.length) {

  //         // let order = orders[2]

  //         let orderArr = []
  //         let nowTime = moment().unix()
  //         // 过滤没过期的 及 付款是eth的
  //         orderArr = orders.filter(item => {
  //           return item.expiration_time >= nowTime && item.payment_token_contract.symbol === 'ETH'
  //         })
  //         // 根据创建时间排序
  //         orderArr = orderArr.sort((a, b)=>{
  //           return b.created_date - a.created_date
  //         })

  //         if (!orderArr.length) return {code: 3000, error: '不存在订单'}

  //         let order = orderArr[0]

  //         let obj = {}
  //         Object.keys(order).forEach(item => {
  //           obj[formatCode(item)] = order[item]
  //         })
  //         obj.asset = undefined
  //         obj.assetBundle = undefined
  //         obj.cancelledOrFinalized = obj.cancelled
  //         obj.feeRecipientAccount = obj.feeRecipient
  //         obj.feeRecipient = obj.feeRecipient.address
  //         obj.hash = obj.prefixedHash
  //         obj.makerAccount = obj.maker
  //         obj.maker = obj.maker.address
  //         obj.takerAccount = obj.taker
  //         obj.taker = obj.taker.address
  //         obj.waitingForBestCounterOrder = false

  //         console.log(999, obj)
  //         return obj
  //       } else {
  //         return {code: 3000, error: '不存在订单'}
  //       }
  //     } else {
  //       // 获取 nft 的出售订单
  //       const orderRes = await window.$seaport.api.getAsset({
  //         tokenAddress,
  //         tokenId,
  //       })
  //       console.log('xxxxx', orderRes)
  //       // 订单列表
  //       const orders = orderRes.b4fomoSeaportSellOrders || orderRes.sellOrders

  //       // 已经拥有
  //       if (orderRes.owner.address === accountAddress) return {code: 5000, error: '已拥有'}

  //       // 检查是否有 有效订单
  //       if (!orders || !orders.length) return {code: 3000, error: '不存在订单'}


  //       const order = orders[0]

  //       // 新版 opensea 订单
  //       if (order.protocol_address) {
  //         let newOrder = {}
  //         newOrder.cancelled = order.cancelled
  //         newOrder.clientSignature = order.client_signature
  //         newOrder.closingDate = order.closing_date
  //         newOrder.createdDate = order.created_date
  //         newOrder.currentPrice = order.current_price
  //         newOrder.expirationTime = order.expiration_time
  //         newOrder.finalized = order.finalized
  //         newOrder.listingTime = order.listing_time
  //         newOrder.maker = order.maker
  //         newOrder.makerFees = order.maker_fees
  //         newOrder.markedInvalid = order.marked_invalid
  //         newOrder.orderHash = order.order_hash
  //         newOrder.orderType = order.order_type
  //         newOrder.protocolAddress = order.protocol_address
  //         newOrder.protocolData = order.protocol_data
  //         newOrder.relayId = order.relay_id
  //         newOrder.side = order.side
  //         newOrder.taker = order.taker
  //         newOrder.takerFees = order.taker_fees
  //         return newOrder
  //       // 旧版 opensea 订单
  //       } else {
  //         let res = await api.pay.getNftPayOrder(tokenAddress, tokenId)
  //         let orderData = JSON.parse(res.data)
  //         let orders = orderData.orders
  //         let myOrder = orders[0]

  //         let obj = {}
  //         Object.keys(myOrder).forEach(item => {
  //           obj[formatCode(item)] = myOrder[item]
  //         })
  //         obj.asset = undefined
  //         obj.assetBundle = undefined
  //         obj.cancelledOrFinalized = obj.cancelled
  //         obj.feeRecipientAccount = obj.feeRecipient
  //         obj.feeRecipient = obj.feeRecipient.address
  //         obj.hash = obj.prefixedHash
  //         obj.makerAccount = obj.maker
  //         obj.maker = obj.maker.address
  //         obj.takerAccount = obj.taker
  //         obj.taker = obj.taker.address
  //         obj.waitingForBestCounterOrder = false

  //         return obj
  //       }


  //       // const { owner } = orderRes
  //       // const orders = orderRes.orders || orderRes.seaport_sell_orders
  //       // // 已经拥有
  //       // if (owner.address === accountAddress) return {code: 5000, error: '已拥有'}

  //       // // 检查是否有 有效订单
  //       // if (!orders || !orders.length) return {code: 3000, error: '不存在订单'}

  //       // let buyorder = null
  //       // let orderArr = []
  //       // let nowTime = moment().unix()
  //       // // 过滤没过期的 及 付款是eth的
  //       // orderArr = orderRes.orders.filter(item => {
  //       //   // console.log(item, nowTime, item.paymentTokenContract)
  //       //   return new BigNumber(item.expirationTime).toNumber() >= nowTime && item.paymentTokenContract.symbol === 'ETH' && item.side === 1
  //       // })
  //       // // 根据创建时间排序
  //       // orderArr = orderArr.sort((a, b)=>{
  //       //   return new BigNumber(b.createdTime).toNumber() - new BigNumber(a.createdTime).toNumber()
  //       // })

  //       // // console.log('orderArr=>>>', orderArr)
  //       // return orderArr.length ? orderArr[0] : {code: 3000, error: '不存在订单'}
  //    }

  //   } catch (e) {
  //     console.log('获取订单错误：', e)
  //   }
  // }

  // 吊起 metamask 钱包
  const sendTransaction = (_tx) => {
    return new Promise((resolve, reject) => {
      web3.eth
        .sendTransaction(_tx)
        .once("transactionHash", (txHash) => resolve(txHash))
        .catch((err) => reject(err))
    })
  }

  // opensea buy
  const _fulfillOrder = async (accountAddress, order, buyGasData) => {
    try {

      // 提示正在拉起钱包
      setBuyDescription('Double Check Price In Metamask')

      // 吊起 metamask 钱包
      let transactionHash = null
      // 新版 opensea 订单 支付
      if (order.protocolAddress) {

        // seaport 合约地址
        const seaportContract = '0x00000000006c3852cbef3e08e8df289169ede581'

        // seaport 合约实例
        const seaportEx = new window.web3.eth.Contract(openseaSeaportAbi, seaportContract)

        // 整理订单
        let forAdditionalRecipients = order.protocolData.parameters.consideration.slice(1)
        let additionalRecipients = forAdditionalRecipients.map(function (_a) {
          var startAmount = _a.startAmount, recipient = _a.recipient
          return ({
            amount: startAmount,
            recipient: recipient
          })
        })
        let seaportOrder = {
          offerer: order.protocolData.parameters.offerer,
          offererConduitKey: order.protocolData.parameters.conduitKey,
          zone: order.protocolData.parameters.zone,
          basicOrderType: order.protocolData.parameters.orderType + 4 * 0,
          offerToken: order.protocolData.parameters.offer[0].token,
          offerIdentifier: order.protocolData.parameters.offer[0].identifierOrCriteria,
          offerAmount: order.protocolData.parameters.offer[0].endAmount,
          considerationToken: order.protocolData.parameters.consideration[0].token,
          considerationIdentifier: order.protocolData.parameters.consideration[0].identifierOrCriteria,
          considerationAmount: order.protocolData.parameters.consideration[0].endAmount,
          startTime: order.protocolData.parameters.startTime,
          endTime: order.protocolData.parameters.endTime,
          salt: order.protocolData.parameters.salt,
          totalOriginalAdditionalRecipients: order.protocolData.parameters.consideration.length - 1,
          signature: order.protocolData.signature,
          fulfillerConduitKey: order.protocolData.parameters.conduitKey,
          additionalRecipients: additionalRecipients,
          zoneHash: order.protocolData.parameters.zoneHash
        }
        console.log('xxx seaportOrder', seaportOrder)
        // 获取 input data
        let inputData = seaportEx.methods.fulfillBasicOrder(seaportOrder).encodeABI()
        console.log('xxx inputData:', inputData)
        // 整理 transaction 需要的参数
        let params = null
        const nonce = await web3.eth.getTransactionCount(accountAddress, "latest")
        if (buyGasData.gas_mode === 'metamask') {
          params = {
            to: seaportContract,
            from: accountAddress,
            value: order.currentPrice,
            data: web3.utils.toHex(inputData),
            nonce
          }
        } else {
          params = {
            to: seaportContract,
            from: accountAddress,
            value: order.currentPrice,
            data: web3.utils.toHex(inputData),
            // gasLimit: 500000,
            maxFeePerGas: `0x${((buyGasData.max_fee) * Math.pow(10, 9)).toString(16)}`,
            maxPriorityFeePerGas: `0x${((buyGasData.max_priority_fee) * Math.pow(10, 9)).toString(16)}`,
            nonce
          }
        }

        // 吊起钱包
        transactionHash = await sendTransaction(params)

        console.log('transactionHash', transactionHash)

        // 同步交易哈希
        setOwnerTransactionData({
          ...ownerTransactionDataRef.current,
          hash: transactionHash,
          timestamp: moment().unix()
        })

        // 检查 购买结果
        checkHash(transactionHash)

        return {code: 9999, hash: transactionHash}
      // 旧版 opensea 订单 支付
      } else {

        transactionHash = await window.$seaportV2.fulfillOrder({
          order,
          accountAddress,
          fastBuyData: buyGasData.gas_mode === 'metamask' ? null : {
            type: buyGasData.gas_mode,
            maxFeePerGas: buyGasData.max_fee,
            maxPriorityFeePerGas: buyGasData.max_priority_fee
          }
        })

        return {code: 1000, hash: transactionHash}

      }

    } catch (error) {
      console.log('opensea buy 错误：', error)
      if(error.message && error.message.includes('insufficient funds for gas * price + value:')){
        return {code: 4000, error: error}
      } else if (error.message && error.message.includes('User denied transaction signature')) {
        return {code: 6000, error: error}
      }else{
        return {code: 2000, error: error}
      }
    }
  }

  // 创建订单操作日志
  const createLogOrderRecords = (options) => {
    let data = {
      user_address: accountAddress, //用户钱包		
      contract_address: nftDetailsDialogOptions.contractAddress, //collection合约地址		
      token_id: nftDetailsDialogOptions.tokenId, //token_id		
      order_info: options.order || {}, //order_info		
      platform: options.platform || '', //平台		
      price: options.price || '', //购买价格
      status: options.status || ''
    }
    api.logs.createLogOrderRecords(data).then(res => {
      console.log(res)
      if(res.code === 1000){
        reportOrderId.current = res.data.id
      }
    })
  }
  // 更新订单操作日志
  const updateLogOrderRecords = (status) => {
    let data = {
      status, //状态
    }
    console.log('updateLogOrderRecords',status)
    if(!reportOrderId.current){
      return
    }
    api.logs.updateLogOrderRecords(reportOrderId.current ,data).then(res => {
      console.log(res)
      reportOrderId.current = ''
    })
  }

  return(
    <div className={['dialog-nft-details-wrapper ', dialogVisible?' show':'' ]} onClick={()=> {
      toggleDialog(false)
    }}>
      <div className="dialog-nft-details-box" onClick={(e) => {
        e.stopPropagation()
      }}>
        {!!nftDetails ?
          <div className="container">
            <div className="close-btn-box" onClick={()=>{
              toggleDialog(false)
            }}>
              <i className="iconfont icon-Close"></i>
            </div>
            <div className="scroll-container">
              {/* <!-- Header */}
              <div className="header">

                <div className="header-left flex">
                  <h6 className="collection-name">{ collectionName }</h6>
                  <span className="nft-token-id">#{ nftDetailsDialogOptions ? nftDetailsDialogOptions.tokenId : ' ?'} /</span>
                  <span className="nft-rank">Rank { (!!nftDetails.token_id && nftDetails.rank) ? nftDetails.rank : '?'}</span>
                  <div className="jump-list flex">
                    <a className="jump-item" href={toLink('opensea')} target="_blank">
                      <div className={['logo-img-container ', nftDetails.base_price ? ' active': '']}>
                        <img src={opensea_normal} alt="" className="normal" />
                        <img src={opensea_active} alt="" className="active" />
                      </div>
                    </a>
                    <a className="jump-item" href={toLink('x2y2')} target="_blank">
                      <div className={['logo-img-container ', nftDetails.x2y2_price ? ' active': '']}>
                        <img src={x2y2_normal} alt="" className="normal" />
                        <img src={x2y2_active} alt="" className="active" />
                      </div>
                    </a>
                    <a className="jump-item" href={toLink('looksrare')} target="_blank">
                      <div className={['logo-img-container ', nftDetails.looksrare_price ? ' active': '']}>
                        <img src={looksrare_normal} alt="" className="normal" />
                        <img src={looksrare_active} alt="" className="active" />
                      </div>
                    </a>
                    <a className="jump-item" href={toLink('element')} target="_blank">
                      <div className={['logo-img-container ', nftDetails.element_price ? ' active': '']}>
                        <img src={element_normal} alt="" className="normal" />
                        <img src={element_active} alt="" className="active" />
                      </div>
                    </a>
                    <a className="jump-item" href={toLink('etherscan')} target="_blank">
                      <div className="logo-img-container">
                        <img src={etherscan_normal} alt="" className="normal" />
                        <img src={etherscan_active} alt="" className="active" />
                      </div>
                    </a>
                  </div>
                </div>

                <div className="header-right">
                  {/* <!-- Close Button */}
                  {/* <!-- <div className="close-btn" onClick={()=>{toggleDialog(false)}}>
                    <i className="iconfont icon-Close"></i>
                  </div> */}
                </div>

              </div>

              {/* <!-- Content */}
              <div className="content">

                <div className="column-1 column-left">

                  <div className="column-left-top">


                    <div className="column-1 column-avatar">

                      {/*  NFT 头像 */}
                      <div ref={nftAvatarRef} className="nft-avatar">
                        <div className="img-content flex">
                          { dialogVisible && <img
                            src={`${rangeImageHost || IMAGEURL}/${nftDetailsDialogOptions.contractAddress}/${nftDetailsDialogOptions.tokenId}`+'?x-oss-process=image/resize,w_450'} alt=""
                          /> }
                        </div>
                      </div>


                      {/* NFT Buy Button Origin - trades */}
                      {origin === 'trades' &&
                        <Button variant="contained" className={`content-btn fast-buy`} onClick={()=>{ toChannelNftDetail(openDetailChannel) }}>
                          <span>Last Price</span>
                          <img src={ethIcon} alt="" className="icon-eth"/>
                          <span className="nft-price">{ filterSupply(lastNftPrice)}</span>
                        </Button>
                      }

                      {/* NFT Buy Button Origin - listing */}
                      {origin === 'listing' && !!channelPriceInfo.channelCount &&
                        <Button variant="contained" className={`content-btn fast-buy able`} onClick={() => multipleChannelsFastBuy(channelPriceInfo.priceList[channelPriceInfo.channelCount - 1].channel, {}, false) }>
                          {buyStatus ?
                            <>
                              {buyStatus === 'pending' && <span className="iconfont global-loading" /> }
                              {buyStatus === 'success' && <i className="iconfont icon-a-TickSquare2"></i> }
                              {buyStatus === 'fail' && <i className="iconfont icon-a-InfoCircle"></i> }
                              <span>{ buyDescription }</span>
                            </>
                            :
                            <>
                              {/* 渠道 logo */}
                              {channelPriceInfo.priceList[channelPriceInfo.channelCount - 1].channel === 'opensea' && <img src={opensea_white} alt="" className="icon-channel" />}
                              {channelPriceInfo.priceList[channelPriceInfo.channelCount - 1].channel === 'looksrare' && <img src={looksrare_white} alt="" className="icon-channel" />}
                              {channelPriceInfo.priceList[channelPriceInfo.channelCount - 1].channel === 'x2y2' && <img src={x2y2_white} alt="" className="icon-channel" />}
                              {channelPriceInfo.priceList[channelPriceInfo.channelCount - 1].channel === 'element' && <img src={element_white} alt="" className="icon-channel" />}
                              {/* 文案 */}
                              <span className="buy-text">Buy At</span>
                              {/* ETH icon */}
                              <img src={ethIcon} alt="" className="icon-eth"/>
                              {/* 价格 */}
                              {channelPriceInfo.priceList[channelPriceInfo.channelCount - 1].channel === 'opensea' && <span className="nft-price">{ filterSupply(nftDetails ? nftDetails.base_price: 0)}</span>}
                              {channelPriceInfo.priceList[channelPriceInfo.channelCount - 1].channel === 'looksrare' && <span className="nft-price">{ filterSupply(nftDetails ? nftDetails.looksrare_price : 0)}</span>}
                              {channelPriceInfo.priceList[channelPriceInfo.channelCount - 1].channel === 'x2y2' && <span className="nft-price">{ filterSupply(nftDetails ? nftDetails.x2y2_price : 0)}</span>}
                              {channelPriceInfo.priceList[channelPriceInfo.channelCount - 1].channel === 'element' && <span className="nft-price">{ filterSupply(nftDetails ? nftDetails.element_price : 0)}</span>}
                            </>
                          }
                        </Button>
                      }

                      {/* Make Offer Origin - trades */}
                      {origin === 'trades' &&
                        <Button className="content-btn make-offer" style={{marginTop: '10px'}} onClick={()=>{ toOpensea(true) }}>
                          <i className="iconfont icon-a-makeoffer"></i>Make Offer
                        </Button>
                      }

                      {/* Make Offer Origin - listing */}
                      {origin === 'listing' &&
                        <div className='make-offer-content'>
                          {channelPriceInfo.channelCount === 1 && <>
                            <Button className="content-btn make-offer" onClick={()=>{ toOpensea(true) }}>
                              <i className="iconfont icon-a-makeoffer"></i>Make Offer
                            </Button>
                          </>}
                          {channelPriceInfo.channelCount === 2 && <>
                            <div className="content-btn-flex">
                              <Button className="content-btn make-offer" onClick={() => { multipleChannelsFastBuy(channelPriceInfo.priceList[0].channel, {}, true) }}>
                                {channelPriceInfo.priceList[0].channel === 'opensea' && <img src={opensea_white} alt="" className="icon-channel" />}
                                {channelPriceInfo.priceList[0].channel === 'looksrare' && <img src={looksrare_white} alt="" className="icon-channel" />}
                                {channelPriceInfo.priceList[0].channel === 'x2y2' && <img src={x2y2_white} alt="" className="icon-channel" />}
                                {channelPriceInfo.priceList[0].channel === 'element' && <img src={element_white} alt="" className="icon-channel" />}
                                <img src={ethIcon} alt="" className="icon-eth" />
                                <span className="nft-price">{ filterSupply(channelPriceInfo.priceList[0].price) }</span>
                              </Button>
                              <Button className="content-btn make-offer" onClick={()=>{ toOpensea(true) }}>
                                <i className="iconfont icon-a-makeoffer"></i>Make Offer
                              </Button>
                            </div>
                          </>}
                          {channelPriceInfo.channelCount === 3 && <>
                            <div className="content-btn-flex">
                              <Button className="content-btn make-offer" onClick={() => { multipleChannelsFastBuy(channelPriceInfo.priceList[1].channel, {}, true) }}>
                                {channelPriceInfo.priceList[1].channel === 'opensea' && <img src={opensea_white} alt="" className="icon-channel" />}
                                {channelPriceInfo.priceList[1].channel === 'looksrare' && <img src={looksrare_white} alt="" className="icon-channel" />}
                                {channelPriceInfo.priceList[1].channel === 'x2y2' && <img src={x2y2_white} alt="" className="icon-channel" />}
                                {channelPriceInfo.priceList[1].channel === 'element' && <img src={element_white} alt="" className="icon-channel" />}
                                <img src={ethIcon} alt="" className="icon-eth" />
                                <span className="nft-price">{ filterSupply(channelPriceInfo.priceList[1].price) }</span>
                              </Button>
                              <Button className="content-btn make-offer" onClick={() => { multipleChannelsFastBuy(channelPriceInfo.priceList[0].channel, {}, true) }}>
                                {channelPriceInfo.priceList[0].channel === 'opensea' && <img src={opensea_white} alt="" className="icon-channel" />}
                                {channelPriceInfo.priceList[0].channel === 'looksrare' && <img src={looksrare_white} alt="" className="icon-channel" />}
                                {channelPriceInfo.priceList[0].channel === 'x2y2' && <img src={x2y2_white} alt="" className="icon-channel" />}
                                {channelPriceInfo.priceList[0].channel === 'element' && <img src={element_white} alt="" className="icon-channel" />}
                                <img src={ethIcon} alt="" className="icon-eth" />
                                <span className="nft-price">{ filterSupply(channelPriceInfo.priceList[0].price) }</span>
                              </Button>
                            </div>
                            <Button className="content-btn make-offer" style={{marginTop: '10px'}} onClick={()=>{ toOpensea(true) }}>
                              <i className="iconfont icon-a-makeoffer"></i>Make Offer
                            </Button>
                          </>}
                          {channelPriceInfo.channelCount === 4 && <>

                            <div className="content-btn-flex">
                              <Button className="content-btn make-offer" onClick={() => { multipleChannelsFastBuy(channelPriceInfo.priceList[2].channel, {}, true) }}>
                                {channelPriceInfo.priceList[2].channel === 'opensea' && <img src={opensea_white} alt="" className="icon-channel" />}
                                {channelPriceInfo.priceList[2].channel === 'looksrare' && <img src={looksrare_white} alt="" className="icon-channel" />}
                                {channelPriceInfo.priceList[2].channel === 'x2y2' && <img src={x2y2_white} alt="" className="icon-channel" />}
                                {channelPriceInfo.priceList[2].channel === 'element' && <img src={element_white} alt="" className="icon-channel" />}
                                <img src={ethIcon} alt="" className="icon-eth" />
                                <span className="nft-price">{ filterSupply(channelPriceInfo.priceList[2].price) }</span>
                              </Button>
                              <Button className="content-btn make-offer" onClick={() => { multipleChannelsFastBuy(channelPriceInfo.priceList[1].channel, {}, true) }}>
                                {channelPriceInfo.priceList[1].channel === 'opensea' && <img src={opensea_white} alt="" className="icon-channel" />}
                                {channelPriceInfo.priceList[1].channel === 'looksrare' && <img src={looksrare_white} alt="" className="icon-channel" />}
                                {channelPriceInfo.priceList[1].channel === 'x2y2' && <img src={x2y2_white} alt="" className="icon-channel" />}
                                {channelPriceInfo.priceList[1].channel === 'element' && <img src={element_white} alt="" className="icon-channel" />}
                                <img src={ethIcon} alt="" className="icon-eth" />
                                <span className="nft-price">{ filterSupply(channelPriceInfo.priceList[1].price) }</span>
                              </Button>
                            </div>


                            <div className="content-btn-flex" style={{marginTop: '10px'}} >
                              <Button className="content-btn make-offer" onClick={() => { multipleChannelsFastBuy(channelPriceInfo.priceList[0].channel, {}, true) }}>
                                {channelPriceInfo.priceList[0].channel === 'opensea' && <img src={opensea_white} alt="" className="icon-channel" />}
                                {channelPriceInfo.priceList[0].channel === 'looksrare' && <img src={looksrare_white} alt="" className="icon-channel" />}
                                {channelPriceInfo.priceList[0].channel === 'x2y2' && <img src={x2y2_white} alt="" className="icon-channel" />}
                                {channelPriceInfo.priceList[0].channel === 'element' && <img src={element_white} alt="" className="icon-channel" />}
                                <img src={ethIcon} alt="" className="icon-eth" />
                                <span className="nft-price">{ filterSupply(channelPriceInfo.priceList[0].price) }</span>
                              </Button>
                              <Button className="content-btn make-offer" onClick={()=>{ toOpensea(true) }}>
                                <i className="iconfont icon-a-makeoffer"></i>Make Offer
                              </Button>
                            </div>

                          </>}
                          { (showChannelBuyTips && !!buyDescription) ?
                            <div className="channel-buy-tips">
                              {buyDescription !=='Balance of Wallet Not Enough' && <CircularProgress className="buy-loading" size={16} />}
                              {buyDescription ==='Balance of Wallet Not Enough' && <i className="iconfont icon-a-InfoCircle"></i>}
                              { buyDescription }
                            </div>
                            : null
                          }
                        </div>
                      }

                      {/* <div className="content-btn-flex">
                        { (openDetailChannel !== 'opensea' && nftDetails.base_price && showChannelBuyTips && !!buyDescription) ?
                          <div className="channel-buy-tips">
  
                            <CircularProgress className="buy-loading" size={16} />
                            { buyDescription }
                          </div>
                          : null
                        }
                        { (openDetailChannel !== 'opensea' && nftDetails.base_price) ?
                          <Button className="content-btn make-offer" onClick={()=>{ toHandler() }}>
                            <img src={opensea_white} alt="" className="icon-channel" />
                            <img src={ethIcon} alt="" className="icon-eth" />
                            <span className="nft-price">{ filterSupply(nftDetails.base_price) }</span>
                          </Button>
                          : null
                        }
                        <Button className="content-btn make-offer" onClick={()=>{ toOpensea(true) }}>
                          <i className="iconfont icon-a-makeoffer"></i>Make Offer
                        </Button>
                      </div> */}

                    </div>
                    {/* 中间traits */}
                    <div className="column-1 column-traits" style={{'height': traitsHeight + 'px'}}>
                      {!!traitsHeight &&
                        <>
                          {/* Traits */}
                          <div className="nft-properties">

                            <div className="nft-properties-header">
                              <span className="nft-properties-title">Traits</span>
                              {
                                existStatus && <span className="nft-score">Score：{ filterSupply(nftDetails.score) }</span>
                              }
                            </div>
                            {!nftDetailsLoading && !ntfTraitsLoading &&
                              <div className="nft-properties-list">
                                { nftDetails.traits_arr && traitsObj && Object.keys(traitsObj).length > 0 ?
                                  <>
                                  { nftDetails.traits_arr.map((item, index) =>
                                    <>
                                    { !!traitsObj[item] &&
                                      <div className="nft-property-item cursor-p" key={'traits-'+index} onClick={()=>{
                                        assetsFilter(traitsObj[item])
                                      }}>
                                        <p className="property-type">
                                          <span>{ filterTraitTitle(traitsObj[item].type) }</span>
                                          <span className="strong">{ (traitsObj[item].pct * 100).toFixed(2) }%</span>
                                        </p>
                                        {/* <div className="property-type-info" :style="{color: rarest == traitsObj[item].pct ? '#30e0a1' : '#fff'}"> */}
                                        <div className="property-type-info">
                                          <span className="property-value">{ filterTraitTitle( traitsObj[item].name ) }</span>
                                          { existStatus && <>
                                              {nftDetails.base_price && (floorPriceMap[item] > currentNftPrice) ?
                                                <span className="property-rarity">
                                                  <span className="special">Ξ{ floorPriceMap[item] }/</span>Ξ{ filterSupply(currentNftPrice) }
                                                </span>
                                                :
                                                <span className="property-rarity">
                                                  <span>Ξ{ floorPriceMap[item] }</span>
                                                </span>
                                              }
                                            </>
                                          }
                                        </div>
                                      </div>}
                                    </>
                                  )}

                                  </>
                                  :
                                  <>
                                    <div className="no-traits">
                                      Not reveal yet, we will show the traits after revealing.
                                    </div>
                                  </>
                                }
                              </div>
                            }

                            { nftDetailsLoading || ntfTraitsLoading ?
                              <CircularProgress className="public-img-loading" size={18} />
                              : null
                            }

                          </div>
                        </>
                      }
                    </div>

                  </div>
                  {/* 左侧计算器 */}
                  {/*  */}
                  <div className="column-left-bottom calculator-box" ref={calculatorRef}>

                    {/* Calculator Header */}
                    <div className="calculator-box-header">
                      <div className="calculator-box-title">
                        <i className="iconfont icon-Calculator"></i>Target Price
                      </div>
                      <div className="calculator-desc-btn">
                        <img src={tipsIcon} alt="" />
                        <p className="calculator-desc-tooltips">The calculation result may differ from actual transactions  for reference only.</p>
                      </div>
                    </div>

                    {/* Calculator Body */}
                    <div className="calculator-box-body">

                      <div className="calculator-box-body-top">
                      <Input
                        value={listingsPrice}
                        type="number"
                        onClick={()=>{
                          clickReportGa({
                            name: 'item_detail_calculator',
                            label: `${contractAddress}_${tokenId}`,
                            value: 100
                          })
                        }}
                        onChange={(e)=>{
                          setListingsPrice(e.target.value)
                          setTimeout(()=>{
                            startCalculator('price')
                          },200)
                        }}
                      />
                        {/* <v-text-field
                          v-model="listingsPrice"
                          className="calculator-input"
                          hide-details
                          type="number"
                          placeholder="Price"
                          solo
                          @input="debounce( () => startCalculator('price'), 500)"
                        >
                          <template v-slot:prepend-inner>
                            <img src={monadIcon} alt="">
                          </template>
                        </v-text-field> */}
                        <p>Target Profit %: { filterSupply(profitPercent) }%</p>
                        <p className={[profitValue < 0?'bad':'']}>Profit: Ξ {profitValue > 0 ? '+' : ''}{filterSupply(profitValue)}</p>
                      </div>

                      <div className="calculator-box-body-bottom">
                        {targetProfitMap.map(item =>
                          <div className={['profit-item ', item === profitPercent ? ' active' : '']} key={'targetProfit-'+item} onClick={()=>{
                            changeProfit(item)
                          }}>
                            { item }%
                          </div>
                        )}
                        {
                          existStatus && <div className={['profit-item ',listingsPrice === maxTraitsFloorPrice ? ' active' : '']} onClick={()=>{
                            changeListingsPrice()
                          }}>Top Trait Floor</div>
                        }
            
                      </div>
                    </div>

                    {/* Calculator Footer */}
                    <div className="calculator-box-footer">* PnL = Price - Fees ( { filterSupply(collectionRoyalty / 100 - 2.5 )}%+2.5% ) - Last Price { filterSupply(currentNftPrice) } - Gas { gasFeeList[0].price }</div>

                  </div>

                </div>
                {/* 右侧列表及pending */}
                <div className="column-1 column-right" style={{'height': contentHeight + 'px'}}>
                  {!!contentHeight &&
                    <>

                    {/* Transaction History */}
                      <div className="nft-transaction-history">
                        <div className="transaction-history-row transaction-history-header">
                          <div>Action</div><div>Price</div><div>Date</div>
                        </div>
                        <div className="transaction-history-body">

                          {!nftTransactionLoading && nftTransactionList && nftTransactionList.length ?
                          <>
                            {nftTransactionList.map((item,index) =>
                              <a className="transaction-history-row" key={'nftTransactionList-'+index} href={toLinkByRow(item)} target="_blank">
                                <div>
                                  Sale
                                  {item.platform.includes('looksrare') &&
                                    <div className="source_logo cursor-p">
                                      <div className="logo-img-container">
                                        <img src={looksrare_active} alt="" />
                                      </div>
                                    </div>
                                  }
                                  {item.platform.includes('element') &&
                                    <div className="source_logo cursor-p">
                                      <div className="logo-img-container">
                                        <img src={element_active} alt="" />
                                      </div>
                                    </div>
                                  }
                                  {item.platform.includes('opensea') &&
                                    <div className="source_logo cursor-p">
                                      <div className="logo-img-container">
                                        <img src={opensea_active} alt="" />
                                      </div>
                                    </div>
                                  }
                                  {item.platform.includes('x2y2') &&
                                    <div className="source_logo cursor-p">
                                      <div className="logo-img-container">
                                        <img src={x2y2_active} alt="" />
                                      </div>
                                    </div>
                                  }
                                  {item.platform.includes('gem') &&
                                    <div className="source_logo cursor-p">
                                      <Tooltip title="Gem Sale" >
                                        <div className="logo-img-container">
                                          <img src={gem_logo} alt="" />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  }
                                  {item.platform.includes('genieswap') &&
                                    <div className="source_logo cursor-p">
                                      <Tooltip title="Genie Swap" >
                                        <div className="logo-img-container">
                                          <img src={genie_logo} alt="" />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  }
                                </div>
                                <div>
                                  { item.payment_symbol === 'ETH' && <img src={monadIcon} alt="" /> }
                                  { item.payment_symbol === 'WETH' && <img src={wethIcon} alt="" /> }
                                  { item.payment_symbol === 'APE' && <img src={apeIcon} alt="" className="ape-coin" />}
                                  { filterSupply(item.price) }
                                </div>
                                <div>{ timeFilter(item.created_date)}</div>
                              </a>
                            )}

                            <div className="transaction-history-row">
                              <div>
                                Minted
                              </div>
                              <div>
                                {/* {nftMintedData && nftMintedData.price &&
                                  <>
                                    { nftMintedData.payment_symbol === 'ETH' && <img src={monadIcon} alt="" /> }
                                    { nftMintedData.payment_symbol === 'WETH' && <img src={wethIcon} alt="" /> }
                                    { nftMintedData.payment_symbol === 'APE' && <img src={apeIcon} alt="" /> }
                                    { filterSupply (nftMintedData.price) }
                                  </>
                                } */}
                              </div>
                              { nftMintedData && <div>{ timeFilter(nftMintedData.created_date) }</div>}
                            </div>

                          </>
                          : null
                          }

                          { !nftTransactionLoading && !(nftTransactionList && nftTransactionList.length) ?
                            <p className="no-transaction-text">This item has not traded recently</p> : null
                          }
                          { nftTransactionLoading && <CircularProgress className="public-img-loading" size={18} /> }

                        </div>
                      </div>

                      {/* Ongoing Transaction */}
                      <div className="nft-ongoing-transaction">
                        <p>Pending transactions and <span>you</span></p>
                        {((buyStatus !== 'pending') && !pendingList.length) || origin === 'trades' ?
                          <p>Pending transactions will start updating when you click Buy</p>
                          :
                          <div className="transaction-list">

                            {/* list header */}
                            <div className="transaction-list-row header">
                              <div>Max Priority Fee</div>
                              <div>Max Gas</div>
                              <div>User</div>
                              <div>Link</div>
                            </div>

                            {/* transaction yourserf  */}
                            { buyStatus === 'pending' && <div className="transaction-list-row owner">
                              <div>{ !!ownerTransactionData.maxPriorityFee ? `${ownerTransactionData.maxPriorityFee} gwei` : `Unknown` }</div>
                              <div>{ !!ownerTransactionData.maxGas ? `${ownerTransactionData.maxGas} gwei` : `Unknown` }</div>
                              <div className="cursor-p" onClick={()=>{ toOpenseaWallet(accountAddress) }}>You</div>
                              <div>
                                {ownerTransactionData.hash ?
                                  <>
                                    {!ownerTransactionData.successful ?
                                      <span >pending for { filterFormatDuration( ownerTransactionData.timestamp, nowSecond) } sec</span>
                                      :
                                      <span className="iconfont icon-a-TickSquare2 pending-successful-icon"></span>
                                    }
                                    <div className="logo-img-container" onClick={()=>{ toEtherscan(ownerTransactionDataRef.current.hash) }}>
                                      <img src={etherscan_normal} alt="" className="normal" />
                                      <img src={etherscan_active} alt="" className="active" />
                                    </div>
                                  </>
                                  :
                                  <>
                                    <div className="iconfont icon-a-InfoCircle">
                                      <p className="tooltips">After you confirm in MetaMask, link to pending transaction will become available.</p>
                                    </div>
                                  </>
                                }
                              </div>
                            </div>}

                            {/* transaction others */}
                            {pendingList.map((item,index) =>
                              <>
                                {item.from.toLowerCase() !== accountAddress.toLowerCase() &&
                                  <div className="transaction-list-row others" key={item.tod}>
                                    {/* <div>{ filterSupply( filterFormatGwei(item.maxPriorityFeePerGas) ) } gwei</div>
                                    <div>{ filterFloorValue( filterFormatGwei(item.maxFeePerGas) )} gwei</div> */}
                                    <div>Unknown</div>
                                    <div>Unknown</div>
                                    <div className="cursor-p" onClick={()=>{toOpenseaWallet(item.from)}}>{ filterShowAddress(item.from) }</div>
                                    <div>
                                      {!item.successful ?
                                        <span>pending for { filterFormatDuration(item.timestamp , nowSecond) } sec</span>
                                        :
                                        <span className="iconfont icon-a-TickSquare2 pending-successful-icon"></span>
                                      }
                                      <div className="logo-img-container" onClick={()=>{ toEtherscan(item.hash) }}>
                                        <img src={etherscan_normal} alt="" className="normal" />
                                        <img src={etherscan_active} alt="" className="active" />
                                      </div>
                                    </div>
                                  </div>
                                }
                              </>
                            )}

                          </div>
                        }

                      </div>

                    </>
                  }
                </div>

              </div>
            </div>

          </div>
          : null
        }

      </div>
    </div>
  )
}

export default DialogNftDetailsWrapper
