// 初始状态
const defaultstate = {
  contractAddress: '',
  floorPirce: 0,
  allChannelListingList: [], //全部渠道实时数据
  newListinsWidth10Mins: 0,
  listingList: [], // 实时数据
  listingSortType: 'Time', // Listing 列表 排序类型 1.price 2.rank 3.date
  listingLoadEnd: false, //是否加载完成
  listingStoped: false, //是否暂停中
  listingLength: 0,

  tradesList: [], // sales交易数据
  tradesSortType: 'All', // Trades 列表 筛选 1. All 2. Opensea 3. LooksRare 4. Gem 5. X2Y2
  tradesLoadEnd: false, //是否加载完成
  tradesLength: 0,

  filterTradesList: [],
  filterListingList: [],

  rankMap: null, //rank对象 通过 nft tokenid 查找
  rankTotal: 0, //有rank的数量
  rankMapTokenIds: [], //rank排序时的tokenids

  currentTime: 0, //当前秒

  filterData: null, //筛选条件
  filterChangeIng: false, //筛选条件执行中

  collectionPending: {}, //pending

  listingChannelType: 'opensea', //listing数据是哪个渠道，opensea，x2y2，looksrare
  assetsDetail: {},

  tradesYPlotLineData: {
    value: null,
    visible: false
  },
  listingFilter: { //页面筛选
    channels: ['opensea', 'looksrare', 'element'],
    sort_type: 'bestListingTime', //pirce bestLowToHigh, pirce bestHighToLow, time bestListingTime, bestExpiringSoon,
    tokenIds: [],
    chain: 'eth',
    page_index: 1,
    isRankFilter: false,
  },
  listingFilterTokenIds: [], //根据rank排序时有用
  listingFilterTokenIdStartIndex: 0, //根据rank排序时有用
  listingFilterTokenIdsMapping: [], //根据rank/traits筛选出的tokenids
  listingScrollTop: true, //listing列表是否滚动到顶部

  listingHasMore: false,
  listingMoreLoading: false,
}

const reducer = (state = defaultstate, action) => {
  switch (action.type) {
    case 'updateContractAddress':
      return {...state, contractAddress: action.data }
    case 'updateAssetDetail':
      return {...state, assetsDetail: action.data}
    case 'updateAllChannelListingList':
      console.log(11111, 'updateAllChannelListingList', action.data)
      return {...state, allChannelListingList: action.data}
    case 'updateNewListingsWidth10Mins':
      return {...state, newListinsWidth10Mins: action.data}
    case 'updateListingStoped':
      return {...state, listingStoped: action.data}
    case 'updateListingData':
      return {...state, listingList: action.data}
    case 'updateListingLength':
      console.log('updateListingLength', action.data)
      return {...state, listingLength: action.data}
    case 'updateListingLoadState':
      return {...state, listingLoadEnd: action.data}
    case 'updateTradesData':
      return {...state, tradesList: action.data}
    case 'updateTradesLength':
      return {...state, tradesLength: action.data}
    case 'updateTradesLoadState':
      return {...state, tradesLoadEnd: action.data}
    case 'updateRankTotal':
      return {...state, rankTotal: action.data}
    case 'updateRankMap':
      // 按照rank排序
      let rankObj = []
      if(action.data){
        Object.keys(action.data).forEach(key => {
          rankObj.push({
            ...action.data[key],
            tokenId: key
          })
        })
        rankObj = rankObj.sort((a, b) => {
          return a.rank - b.rank
        })
      }

      return {...state, rankMap: action.data, rankMapTokenIds: rankObj}
    case 'updateTradesSortType':
      return {...state, tradesSortType: action.data}
    case 'updateFilterTradesList':
      return {...state, filterTradesList: action.data}
    case 'updateListingSortType':
      return {...state, listingSortType: action.data}
    case 'updateFilterListingList':
      return {...state, filterListingList: action.data}
    case 'updateFloorPirce':
      return {...state, floorPirce: action.data}
    case 'updateFilterData':
      return {...state, filterData: action.data}
    case 'updateCollectionPending':
      return {...state, collectionPending: action.data}
    case 'updateTradesYPlotLineData':
      return {...state, tradesYPlotLineData: action.data}
    case 'updateFilterChangeIng':
      return {...state, filterChangeIng: action.data}
    case 'updateListingFilter':
      console.log(1111, 'updateListingFilter', action.data)
      return {...state, listingFilter: action.data}
    case 'updateListingFilterTokenIds':
      console.log('updateListingFilterTokenIds', action.data)
      return {...state, listingFilterTokenIds: action.data, listingFilterTokenIdStartIndex: 0}
    case 'updateListingFilterTokenIdStartIndex':
      return {...state, listingFilterTokenIdStartIndex: action.data}
    case 'updateListingFilterTokenIdsMapping':
      return {...state, listingFilterTokenIdsMapping: action.data}
    case 'updateListingHasMore':
      return {...state, listingHasMore: action.data}
    case 'updateListingMoreLoading':
      console.log('updateListingMoreLoading', action.data)
      return {...state, listingMoreLoading: action.data}
    case 'updateListingScrollTop':
      return {...state, listingScrollTop: action.data}
      
    default:
      return state;
  }
}
export default reducer;
