// 初始状态
const defaultstate = {
  listingList: [],
  listingStopTipVisible: false, //提示监听暂停中
  filterData: {
    colletion: [], //搜索colletion
    buyPrice: { //筛选价格
      min: '',
      max: '',
    },
    floor: null, //地板价前百分比
    rarityRank: null, //rank前百分比
  },
  currentTime: 0,
  colletionInfos: {}, //项目详情
  colletionRankMap: {}, //项目下的rank
  
  leaveMaskVisible: false, //离开页面提示
}

const reducer = (state = defaultstate, action) => {
  switch (action.type) {
    case 'updateFilterData':
      localStorage.setItem('snip-filter', JSON.stringify(action.data))
      return {...state, filterData: action.data}
    case 'updateListingList':
      return {...state, listingList: action.data}
    case 'updateListingStopTipVisible':
      return {...state, listingStopTipVisible: action.data}
    case 'updateCurrentTime':
      return {...state, currentTime: action.data}
    // 添加某个详情
    case 'addColletionInfos':
      return {...state, colletionInfos: {
        ...state.colletionInfos,
        [action.data.address]: action.data.info
      }}
    case 'updateColletionInfos':
      return {...state, colletionInfos: action.data}
    case 'updateColletionRankMap':
      return {...state, colletionRankMap: action.data}
    case 'updateLeaveMaskVisible':
      return {...state, leaveMaskVisible: action.data}
    default:
      return state;
  }
}
export default reducer;
