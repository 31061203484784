import { APILINK } from '@/config/config'
import axios from '../axios'

// 创建订单（Mark）
export const createOrder = (address, data) => {
  return axios({
    url: `${APILINK}/nft/v1/orders`,
    method: 'POST',
    data
  })
}
// 获取订单列表（Mark）
export const getOrderList = (address) => {
  return axios({
    url: `${APILINK}/nft/v1/users/${address}/orders`,
    method: 'GET',
    params: {},
  })
}
// 获取会员到期时间（Mark）
export const getUserMemberships = (address) => {
  return axios({
    url: `${APILINK}/nft/v1/memberships/${address}`,
    method: 'GET',
    params: {},
  })
}

// 保存用户钱包信息(创建用户)（Mark）
export const createUser = (address, data) => {
  return axios({
    url: `${APILINK}/nft/v1/users/${address}`,
    method: 'POST',
    data,
  })
}

// 保存用户钱包信息(创建用户)（Mark）
export const getUserCode = (params) => {
  return axios({
    url: `${APILINK}/nft/v1/users_code`,
    method: 'GET',
    params,
  })
}

// 送会员
export const claimMemberships = (address, data) => {
  return axios({
    url: `${APILINK}/nft/v1/claim/wallet_address/${address}/memberships`,
    method: 'POST',
    data,
  })
}