import axios from '../axios'
import { APILINK, NFTNERDSCDN } from '../../config/config'

// 获取优惠券链接详情
export const getDiscounts = (token) => {
  return axios({
    url: `${APILINK}/nft/v1/channel_exclusive_discounts/${token}`,
    method: 'GET',
  })
}

export const getCDN = contract_address => {
  return axios({
    url: `${NFTNERDSCDN}/nftnerds-books/${contract_address}`,
    method: 'GET'
  })
}
// 获取sales列表
export const getSalesCDN = contract_address => {
  return axios({
    url: `${NFTNERDSCDN}/b4fomo-trades/${contract_address}`,
    method: 'GET',
    params: {
      v: new Date().getTime()
    },
    customHeaders: {
      // 'Expires': new Date(1657784020000 - 24 * 60 * 60 * 1000),
      // 'Last-Modified': new Date(1657784020000),
      // 'cache-control':'no-cache',
    }
  })
}
// 获取lisitng列表
export const getListingCDN = contract_address => {
  return axios({
    url: `${NFTNERDSCDN}/b4fomo-listing/${contract_address}`,
    method: 'GET',
    params: {
      v: new Date().getTime()
    },
  })
}
