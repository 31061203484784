import * as collection from './list/collection'
import * as user from './list/user'
import * as notification from './list/notification'
import * as firebase from './list/firebase'
import * as bluechip from './list/bluechip'
import * as pay from './list/pay'
import * as opensea from './list/opensea'
import * as discount from './list/discount'
import * as logs from './list/logs'
import * as global from './list/global'
import * as element from './list/element'


const api = {
  collection,
  user,
  notification,
  firebase,
  bluechip,
  pay,
  opensea,
  discount,
  logs,
  global,
  element
}

export default api
