const useWeb3 = () => {
  // 获取余额
  const getBalance = async (accountAddress) =>{
    let balance = await window.web3.eth.getBalance(accountAddress);
    // console.log(balance,"===============>balance=");
    return {
      ether: window.web3.utils.fromWei(balance, 'ether'),
      balance: balance
    }
  }
  // 判断是否需要切换网络
  const checkSwitchNetwork = async (chainId) => {
    let netId = await window.web3.eth.net.getId()
    // console.log('网络id=>>', netId)
    if(netId === 1){
      return true
    }else{
      // 切换网络
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chainId ? chainId : '0x1' }],
        });
        return true
      } catch (error) {
        return false
      }
    }
  }
  // 判断当前网络是否错误
  const checkNetworkError = async () => {
    let errorTips = ''
    try {
      // 当前网络id
      let netId = await window.web3.eth.net.getId()
      let env = process.env.START_ENV // testnets staging prod
      if (env === 'testnets' && netId !== 4) {
        errorTips = "You're viewing data from the test network, but your wallet is connected to the other network."
      } else if (env !== 'testnets' && netId !== 1) {
        errorTips = "You're viewing data from the main network, but your wallet is connected to the other networks."
      }
    } catch (e) {
      console.log(e)
    }
    return errorTips
  }

  return {
    getBalance,
    checkSwitchNetwork,
    checkNetworkError
  }
}

export {
  useWeb3
}