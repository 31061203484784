import { APILINK } from '@/config/config'
import axios from '../axios'

// 创建订单操作日志
export const createLogOrderRecords = data => {
  return axios({
    url: `${APILINK}/nft/log/v1/order_records`,
    method: 'POST',
    data
  })
}
// 更新订单状态
export const updateLogOrderRecords = (logId, data) => {
  return axios({
    url: `${APILINK}/nft/log/v1/order_records/${logId}`,
    method: 'PUT',
    data
  })
}