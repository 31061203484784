import { updateNftsStore } from '@/store/modules/nfts/actionCreators'
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux'
const useMessage = () => {
  const dispatch = useDispatch()
  const success = (content = '', during = 2000) => {
    dispatch(updateNftsStore({
      type: 'updateSnackbar',
      data: {
        show: true,
        type: 'success',
        text: content,
        during
      }
    }))
  }
  const error = (content = '', during = 2000) => {
    dispatch(updateNftsStore({
      type: 'updateSnackbar',
      data: {
        show: true,
        type: 'error',
        text: content,
        during
      }
    }))
  }
  const info = (content = '', during = 2000) => {
    dispatch(updateNftsStore({
      type: 'updateSnackbar',
      data: {
        show: true,
        type: 'info',
        text: content,
        during
      }
    }))
  }
  const warning = (content = '', during = 2000) => {
    dispatch(updateNftsStore({
      type: 'updateSnackbar',
      data: {
        show: true,
        type: 'warning',
        text: content,
        during
      }
    }))
  }
  return {
    success,
    error,
    warning,
    info
  }
}

export default useMessage