import React, { useState, useEffect, useRef, } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import detectEthereumProvider from "@metamask/detect-provider";
import { useNavigate} from 'react-router-dom'
import Button from '@mui/material/Button'

import './index.less'
import { updateGlobalStore } from '@/store/modules/global/actionCreators'
import { updateSubscriptionStore } from '@/store/modules/subscription/actionCreators'
import { useMetamask } from '@/mixins/metamask'
import api from '@/api'

const GlobalHeaderStatusTips = (props) => {
  const { } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const metamask = useMetamask()

  const accountAddress = useSelector(state => state.subscription.accountAddress)
  // const isSubscription = useSelector(state => state.subscription.isSubscription)
  // const subscriptionAt = useSelector(state => state.subscription.subscriptionAt)

  const [second, setSecond ] = useState(0)
  const [timerArr, setTimerArr ] = useState([])
  const [expiredAt, setExpiredAt ] = useState(null)
  const [networkErrorVisible, setNetworkErrorVisible ] = useState(false)
  const [networkErrorDesc, setNetworkErrorDesc ] = useState(null)
  const [type, setType ] = useState(null) // 1. not-login 2. expiring 3. expired

  const secondTimer = useRef(null)
  const secondRef = useRef(null)


  /**
   * init
   */
  useEffect(() => {
    if(!!window.web3){
      checkNetworkError();
    }
    setTimeout(() => {
      getUserMembershipsFun()
    }, 200);
  }, [])

  useEffect(() => {
    console.log('accountAddress', accountAddress)
    if (accountAddress) {
      setType(null)
      // 清理未登录的定时器
      clearInterval(secondTimer.current)
      // 获取到期时间
      api.user.getUserMemberships(accountAddress).then(res=>{
        if(res.code === 1000){
          let { expired_at } = res.data;
          // expired_at = new Date().getTime() + 365*24*60*60*1000
          dispatch(updateSubscriptionStore({
            type: 'updateAccountInvitedInfo',
            data: res.data
          }))
          dispatch(updateSubscriptionStore({
            type: 'updateIsSubscription',
            data: expired_at
          }))

          setExpiredAt(expired_at || null)
          if (expired_at) {
            let time = moment(expired_at).unix() - moment().unix()
            if ( time < 0 ) {
              setType('expired')
            } else if ( time > 0 && time < (12*60*60) ) {
              setType('expiring')
              // 开启倒计时（即将到期）
              // startTimer('expiring')
            }
          }

        }
      })
    } else {
      // this.type = 'not-login'
      // // 开启倒计时（未登录）
      // this.startTimer('not-login')
    }
  }, [accountAddress])

  useEffect(() => {
    dispatch(updateGlobalStore({
      type: 'updateNavbarTips',
      data: type || networkErrorVisible
    }))
  }, [type, networkErrorVisible])

  useEffect(() => {
    if(second){
      if(secondRef){
        secondRef.current = second
      }
      let time = second
      let h = 1 * 60 * 60
      let m = 1 * 60
      let s = 1
      let hour = String(Math.floor(time / h))
      let minute = String(Math.floor((time % h) / m))
      let seconds = String(Math.floor((time % h % m) / s))

      setTimerArr([hour, minute, seconds])
    }else{
      setTimerArr([])
    }
  }, [second])

  // 监测网络
  const checkNetworkError = async () => {
    try {
      let netId = await window.web3.eth.net.getId()
      let env = process.env.START_ENV // testnets staging prod
      if (env === 'testnets' && netId !== 4) {
        setNetworkErrorVisible(true)
        setNetworkErrorDesc("You're viewing data from the test network, but your wallet is connected to the other network.")
      } else if (env !== 'testnets' && netId !== 1) {
        setNetworkErrorVisible(true)
        setNetworkErrorDesc("You're viewing data from the main network, but your wallet is connected to the other networks.")
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (expiredAt && type === 'expiring') {
      // 开启倒计时（即将到期）
      startTimer('expiring')
    }
  }, [expiredAt, type])

  // 获取当前账户会员状态
  const getUserMembershipsFun = () => {
    if (accountAddress) {
      // 获取到期时间
      api.user.getUserMemberships(accountAddress).then(res=>{
        if(res.code === 1000){
          let { expired_at } = res.data
          // 判断是否订阅
          dispatch(updateSubscriptionStore({
            type: 'updateAccountInvitedInfo',
            data: res.data
          }))
          dispatch(updateSubscriptionStore({
            type: 'updateIsSubscription',
            data: expired_at
          }))
          setExpiredAt(expired_at || null)

          if (expired_at) {
            const time = moment(expired_at).unix() - moment().add(8, 'h').unix()
            if ( time < 0 ) {
              setType('expired')
            } else if ( time > 0 && time < (12*60*60) ) {
              setType('expiring')
            }
          }
        }
      })
    } else {
      // this.type = 'not-login'
      // // 开启倒计时（未登录）
      // this.startTimer('not-login')
    }
  }
  // 开启倒计时
  const startTimer = (type) => {
    // console.log('chen====>111', type)
    // 终点时间
    let targetTime = null
    // 倒计时时间（单位：秒）
    let second = null
    // 未登录
    if (type === 'not-login') {
      targetTime = moment().add(1, 'd').format('YYYY-MM-DD')
      second = moment(targetTime).unix() - moment().unix()
    // 即将到期
    } else if (type === 'expiring') {
      targetTime = expiredAt
      second = moment(targetTime).unix() - moment().unix()
    }

    setSecond(second)

    clearInterval(secondTimer.current)
    secondTimer.current = setInterval(() => {
      if (secondRef.current === 0) {
        clearInterval(secondTimer.current)
        // 未登录
        if (type === 'not-login') {
          startTimer(type)
        // 即将到期
        } else if (type === 'expiring') {
          setType('expired')
        }
      } else {
        setSecond(secondRef.current - 1)
      }
    }, 1000)

  }
  // 去会员落地页
  const toMemberPage = () => {
    navigate('/subscription')
  }


  return (
    <div className={`global-header-status-tips-wrapper ${ (!type && !networkErrorVisible) ? 'hidden' : ''}`}>
    { networkErrorVisible ?
      <>
        {/* (最高优先级)钱包目前连接的网络不对 */}
        <p className="text">{ networkErrorDesc }</p>
      </>
      :
      <>
        {/* 1. 用户未登录状态，倒计时展示限时免费领取会员 */}
        {type === 'not-login' ?
          <>
            <p className="text">
              Connect the wallet to get a free subscription.The promotion will end in
              <span className="strong"></span>
            </p>
            {timerArr.length ?
              <div className="timer">
                {timerArr[0].length < 2 ?
                  <>
                    <span className="timer-item">0</span>
                    <span className="timer-item">{ timerArr[0] }</span>
                  </>
                :
                  <>
                    <span className="timer-item">{ timerArr[0][0] }</span>
                    <span className="timer-item">{ timerArr[0][1] }</span>
                  </>
                }
                :
                {timerArr[1].length < 2 ?
                  <>
                    <span className="timer-item">0</span>
                    <span className="timer-item">{ timerArr[1] }</span>
                  </>
                  :
                  <>
                    <span className="timer-item">{ timerArr[1][0] }</span>
                    <span className="timer-item">{ timerArr[1][1] }</span>
                  </>
                }
                :
                {timerArr[2].length < 2 ?
                  <>
                    <span className="timer-item">0</span>
                    <span className="timer-item">{ timerArr[2] }</span>
                  </>
                  :
                  <>
                    <span className="timer-item">{ timerArr[2][0] }</span>
                    <span className="timer-item">{ timerArr[2][1] }</span>
                  </>
                }

              </div>
              : null
            }
            <Button className="btn" onClick={()=>{ metamask.connectMetamask() }}>Connect Wallet</Button>
          </>
          : null
        }
        {/* 2. 距离会员到期时间小于12小时，倒计时展示会员即将结束 */}
        {type === 'expiring' ?
          <>
            <p className="text">Your subscription will expire in</p>
            {timerArr.length ?
              <div className="timer">
                {timerArr[0].length < 2 ?
                  <>
                    <span className="timer-item">0</span>
                    <span className="timer-item">{ timerArr[0] }</span>
                  </>
                  :
                  <>
                    <span className="timer-item">{ timerArr[0][0] }</span>
                    <span className="timer-item">{ timerArr[0][1] }</span>
                  </>
                }
                :
                {timerArr[1].length < 2 ?
                  <>
                    <span className="timer-item">0</span>
                    <span className="timer-item">{ timerArr[1] }</span>
                  </>
                :
                  <>
                    <span className="timer-item">{ timerArr[1][0] }</span>
                    <span className="timer-item">{ timerArr[1][1] }</span>
                  </>
                }
                :
                {timerArr[2].length < 2 ?
                  <>
                    <span className="timer-item">0</span>
                    <span className="timer-item">{ timerArr[2] }</span>
                  </>
                  :
                  <>
                    <span className="timer-item">{ timerArr[2][0] }</span>
                    <span className="timer-item">{ timerArr[2][1] }</span>
                  </>
                }

              </div>
              : null
            }
            <p className="text">you will lose your exclusive rights 👉</p>
            <Button className="btn" onClick={()=>{ toMemberPage() }}>Renew Now</Button>
          </>
          : null
        }


        {/* 3. 开通过但是会员已过期，展示会员已过期文案提示 */}
        { type === 'expired' ?
          <>
            <p className="text">Welcome! Enjoy your 1 day free trail, join DC and submit your wallet to get Free Membership 👉</p>
            <Button className="btn" onClick={()=>{
              toMemberPage();
              window.open('https://discord.gg/Tt3THvstpg')
            }}>Join Discord</Button>
          </>
          : null
        }
      </>
    }

  </div>
  )

}

export default GlobalHeaderStatusTips
