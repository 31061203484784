import React, { useState, useEffect, useRef, } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import moment from 'moment'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Popover from '@mui/material/Popover'

import { updateGlobalStore } from '@/store/modules/global/actionCreators'
import useMessage from '@/components/global/message/message';
import { useMetamask } from '@/mixins/metamask'
import getRouteMeta from '@/router/config'
import { updateNftsStore } from '@/store/modules/nfts/actionCreators'

import './index.less'
import api from '@/api'
import AlgoliaConfig from '@/config/algolia'
import { clickReportGa } from '@/utils/utils'
import GlobalLoginButton from '@/components/global/components/LoginButton'
import copy from "copy-to-clipboard";

const algoliasearch = require("algoliasearch")
const client = algoliasearch(AlgoliaConfig.id, AlgoliaConfig.key)

import otherMemberLogo from '@/assets/images/other_member_logo.png';
import userIcon from '@/assets/images/user.png';
import helpIcon from '@/assets/images/help.png';
import morePeopleIcon from '@/assets/images/more-people.png';
import membershipIcon from '@/assets/images/navbar/membership.png';
import buymembershipIcon from '@/assets/images/navbar/buymembership.png';
import mybidIcon from '@/assets/images/navbar/mybid.png';
import logoutIcon from '@/assets/images/navbar/logout.png';


const GlobalHeaderNavbar = (props) => {
  const { routeMeta, } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const message = useMessage()
  const metamask = useMetamask()

  const showGlobalCollectionSearch = useSelector(state => state.global.showGlobalCollectionSearch)
  const showGlobalNavBarMenu = useSelector(state => state.global.showGlobalNavBarMenu)
  const windowClientInfo = useSelector(state => state.global.windowClientInfo)
  const accountAddress = useSelector(state => state.subscription.accountAddress)
  const subscriptionAt = useSelector(state => state.subscription.subscriptionAt)
  const isSubscription = useSelector(state => state.subscription.isSubscription)
  const isAccredit = useSelector(state => state.subscription.isAccredit)
  const accountInvitedInfo = useSelector(state => state.subscription.accountInvitedInfo)
  const listingStoped = useSelector(state => state.liveView.listingStoped)

  const [searchKey, setSearchKey ] = useState('') // 搜索关键词
  const [searchList, setSearchList ] = useState([]) // 搜索结果列表
  const [cacheList, setCacheList ] = useState(null) // 缓存搜索过的项目列表
  const [showResult, setShowResult ] = useState(false) //搜索结果显示开关
  const [showBack, setShowBack ] = useState({}) //是否显示返回按钮
  const [showInvited, setShowInvited ] = useState(false)
  const [hotCollections, setHotCollections ] = useState([]) //hot collection 列表
  const [limitLen, setLimitLen ] = useState(8)
  const [showSearchInput, setShowSearchInput ] = useState(false)

  const [openseaErrorTips, setOpenseaErrorTips] = useState('') //提示opensea异常
  const searchTimer = useRef(null) //搜索定时器

  useEffect(()=>{
    init()
    initOpenseaError()
  },[])

  useEffect(()=>{
    setShowInvited(isAccredit)
  },[isAccredit])

  useEffect(()=>{
    clearTimeout(searchTimer.current)
    searchTimer.current = setTimeout(() => {
      searchFun()
    }, 300);
  },[searchKey])

  useEffect(()=>{
    if(showResult){
      dispatch(updateGlobalStore({
        type: 'showGlobalCollectionSearch',
        data: true
      }))
    }
  },[showResult])
  useEffect(()=>{
    if(!showGlobalCollectionSearch){
      setShowResult(false)
    }
  },[showGlobalCollectionSearch])
  const searchFun = () => {
    if (searchKey) {
      client.multipleQueries([{indexName:"contract-infos-index",query: searchKey}]).then(({results}) => {
        let list = results[0].hits
        setSearchList([])
        setTimeout(() => {
          setSearchList(list)
        }, 300);
        setShowResult(!!list.length)
      })
      .catch(err => {
        console.log(err);
      });
    } else {
      if (cacheList && cacheList.length) {
        setSearchList(cacheList)
        setShowResult(true)
      } else {
        setSearchList([])
        setShowResult(false)
      }
    }
  }

  const init = async () => {
    // 获取 localStorage 的搜索缓存
    let cacheList = JSON.parse( localStorage.getItem('collection-search-cache') ) || []
    setCacheList(cacheList)
    // 先初始化一下
    client.multipleQueries([{indexName:"contract-infos-index",query: searchKey}]).catch(e => {
      console.log(e);
    })
    // localStorage 已经有 8 条缓存 collection 了，不再请求接口
    if (cacheList.length >= limitLen){
      return
    }
    // 获取 hot colletion ，以便补充搜索历史
    // let hotCollections = await getCollectionList()
    let hotCollections = []
    // 补充 cacheList
    fillCacheList(cacheList, hotCollections)
  }

  const fillCacheList = (cacheList, hotCollections) => {
    // 整合
    let arr = [...cacheList, ...hotCollections]

    // 去重
    for(var i=0; i<arr.length; i++){
      for(var j=i+1; j<arr.length; j++){
        if(arr[i].address == arr[j].address){
          arr.splice(j,1)
          j--
        }
      }
    }
    // 截取
    (arr.length > limitLen) && (arr = arr.splice(0, limitLen))

    // 存储
    localStorage.setItem('collection-search-cache', JSON.stringify(arr))

    // 赋值
    setCacheList(arr)
  }

  const getCollectionList = async () => {
    let hotCollection = []
    try {
      let params = {
        page: 1,
        size: limitLen,
        hot: true,
        top: false
      }
      let res = await api.collection.getcollections(params)
      if (res.code === 1000) {
        hotCollection = res.data.collections
        setHotCollections(hotCollection)
      }
    } catch (error) {
      console.log('获取 collections 错误：', error)
    }
    return hotCollection
  }
  // 邀请弹框阻断
  const beforeInvitedLink = (e) => {
    if(!accountAddress){
      e.stopPropagation()
      e.preventDefault()

      dispatch(updateGlobalStore({
        type: 'updateLoginProps',
        data: {
          show: true,
          loginCallback: () => {
            setShowInvited(true)
          }
        }
      }))
    }
  }
  const searchFocusFun = () => {
    setShowResult(false)
    if (!searchKey && cacheList && cacheList.length) {
      setSearchList(cacheList)
      setTimeout(() => {
        setShowResult(true)
      }, 200)
    }
  }
  // 去 asset-list 页
  const toAssetList = (obj) => {
    // 上报 :ga-event-data="`navbar_search/${searchKey}/100`"
    // clickReportGa({
    //   name: 'navbar_search',
    //   label: searchKey,
    //   value: 100
    // })
    // 触发 input 失去焦点
    document.activeElement.blur()
    // // 跳转到项目详情
    // window.location.href = `${window.location.origin}/collection/${obj.contract_address || obj.address}/activity`
    setSearchList([])
    setShowResult(false)

    // 缓存搜索过的项目
    let maxLen = limitLen
    let index = null
    let len = cacheList.length
    let newCacheList = JSON.parse(JSON.stringify(cacheList))

    // 检查缓存列表里是否有这个 Collection
    newCacheList.forEach((item, i) => {
      console.log(item, obj)
      if (item.address === obj.address) index = i
    })
    // 缓存列表里已经有这个 Collection 了
    if (typeof index === 'number') {
      let collectionItem = newCacheList.splice(index, 1)[0]
      newCacheList.unshift(collectionItem)
    // 没有
    } else {
      // 超过最大限制，移除最后一个，再添加新
      if (len >= maxLen) {
        newCacheList = [obj, ...newCacheList.slice(0, len -1)]
      // 向前插入最新
      } else {
        newCacheList.unshift(obj)
      }
    }
    setCacheList(newCacheList)
    // 存储到 localstorage
    localStorage.setItem('collection-search-cache', JSON.stringify(newCacheList))
    

    // 跳转到项目详情
    setTimeout(() => {
      // window.location.href = `${window.location.origin}/collection/${obj.address}/activity`
      navigate(`/collection/${obj.address}/activity`)
      dispatch(updateNftsStore({
        type: 'updateCollectionFloorPrice',
        data: null
      }))
    }, 200)

  }
  // 邀请链接
  const invitedLink = async (e) => {
    if(!accountAddress){
      e.stopPropagation()
      e.preventDefault()
      dispatch(updateGlobalStore({
        type: 'updateLoginProps',
        data: {
          show: true,
        }
      }))
      return
    }
    let res = await api.user.getUserCode({wallet_address: accountAddress})
    if(res.code == 1000) {
      let href = `${window.location.origin}/invited?address=${res.data.code}`
      copy(href)
      message.info('Copy link success')
    } else {
      message.error('Get user code failed')
    }
  }
  // 打开插件页面
  const openPlugin = () => {
    window.open('https://chrome.google.com/webstore/detail/akipilnkffepljnlccfikgglcdjfnldh')
  }

  // 初始化opensea是否有错误
  const initOpenseaError = () => {
    let errorTime = localStorage.getItem('openseaErrorTips');
    if(!!errorTime && errorTime < new Date().getTime()){
      return
    }
    try {
      // 获取错误id
      api.opensea.getOpenseaErrorEmbedJson().then(res => {
        if(res.unresolved_items){
          let promiseList = []
          // 整理错误id，多次请求一起发起
          res.unresolved_items.forEach(item => {
            promiseList.push(api.opensea.getOpenseaErrorEmbedInfo(item))
          })
          if(promiseList.length){
            Promise.all(promiseList).then(res => {
              res.forEach(data => {
                let {name, resolved_at } = data;
                // 没有结束 && 满足错误类型 就展示 name.includes('Programmatic order') ||
                if(!resolved_at && (name.includes('events'))){
                  setOpenseaErrorTips(name)
                }
              })
              // console.log(res)
            })
          }
        }
        console.log(res)
      })
    } catch (error) {
      console.log('initOpenseaError=>>>', error)
    }
  }
  // 关闭提示opensea错误
  const closeOpenseaErrorTips = () => {
    setOpenseaErrorTips('')
    localStorage.setItem('openseaErrorTips', new Date().getTime())
  }

  const metamaskMenuClick = (command) => {
    dispatch(updateGlobalStore({
      type: 'updateShowGlobalNavBarMenu',
      data: false
    }))
    if(command === 'subscription'){
      window.open(`/subscription`)
    }else if(command === 'logout'){
      metamask.logoutMetamask()
    } else if(command === 'mybid'){
      window.open('https://opensea.io/account?tab=bids_made')
    }
  }

  const toPage = (page) => {
    if (!page) return
    navigate(page)
  }

  return (
    <>
      <div className="nav-bar-wrapper-scroll rest-scrollbar">
        <div className="nav-bar-wrapper flex">

          {/* Collection Search */}
          <div className="left flex">

            <a href="/">
              <img src={otherMemberLogo} alt="" className="logo" />
            </a>
            {
              windowClientInfo.width < 750 ?
              <div className='flex search-body' onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}>
                <div className='search-btn' onClick={() => setShowSearchInput(true)}>
                  <span className="iconfont icon-Search" />
                </div>
                <input onBlur={() => setShowSearchInput(!!searchKey)} style={{width: showSearchInput ? '130px' : '0px'}} onFocus={()=>{ searchFocusFun() }}
                  onChange={(e) => { setSearchKey(e.target.value) }} value={searchKey} type="text" className="input-text"></input>
              </div>
              : <div className="input-container cursor-p flex" id="searchBox" onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}>
                <span className="iconfont icon-Search" />
                <input type="text" className="input-text" value={searchKey} placeholder={windowClientInfo.width <= 750 ? "Search" : "Search Collection / Address / NFTs"}
                  onFocus={()=>{ searchFocusFun() }}
                  onChange={(e) => { setSearchKey(e.target.value) }}
                />
                { (showResult && windowClientInfo.width > 750) ?
                  <div className='global-search-collection-content hidden-scrollbar'>
                    {searchList.map((item, index) =>
                      <div className="global-search-collection-item flex cursor-p"
                        key={`search_item_${index}`}
                        ga-event-data={`navbar_search/${searchKey}/100`}
                        onClick={()=>{
                          toAssetList(item)
                        }}
                      >
                        <img src={item.image_url} alt="" />
                        <div className="content flex">
                          <div className="name">{ item.name }</div>
                          <div className="address" address-data={item.address}>{ item.address }</div>
                        </div>
                      </div>
                    )}
                  </div>
                  : null
                }
              </div>
            }


          </div>

          {/* User Wallet */}
          <div className="right flex">
            <div className="navigation-content">
            <a href="/mint" onClick={(e)=>{
                toPage('/mint')
                e.preventDefault()
              }}>
               <div className={`navigation-item ${routeMeta.name == 'FreeMint' ? 'active': ''}`}>
                 Mints
               </div>
              </a>
              <a href="/collections" onClick={(e)=>{
                  toPage('/collections')
                  e.preventDefault()
                }}>
                <div className={`navigation-item ${routeMeta.name == 'Collections' ? 'active': ''}`}>
                  Trending
                </div>
              </a>
              {/* <a href="/snipe">
                <div className={`navigation-item ${routeMeta.name == 'Snipe' ? 'active': ''}`}>
                  Snipe
                </div>
              </a> */}
              {/* <div style={{marginRight: !accountAddress ? '10px' : '58px'}} className="navigation-item extension" ga-event-data="navbar_extension_click/1/100" onClick={()=>{ openPlugin() }}>
                Extension
              </div> */}
            </div>
            <div className='none-in-mobile flex-c'>
              <div className='nav-menu-bar-content'>
                {!!accountAddress &&
                  <Button className="nav-menu-bar-button reset-default-black-button" variant="contained"
                    onClick={()=>{ beforeInvitedLink() }}
                  >
                    <span className="font_b font_14"><span className="icon">🔥</span>Referral</span>
                  </Button>
                }
                <div className="global-invited-popover">
                  <img className="user-icon" src={userIcon} alt="" />
                  <p className="introduce">Invite 1 new user and <br/>Get 1 Day Free!</p>
                  {accountInvitedInfo && accountInvitedInfo.has_invited ?
                    <>
                      <div className="invited-hover-body">
                        <div className="invited-count">
                          You've invited  <span>{accountInvitedInfo.invitees_count} valid users</span> <br/>
                          and get <span>{accountInvitedInfo.free_member_days} days</span> free subscription
                          <img src={helpIcon} alt="" />
                        </div>
                        <span className="tips">You can only receive rewards when the invited users have a <span>non-zero</span> wallet balance.</span>
                      </div>
                    </>
                    :
                    <>
                      <div className="flex" style={{alignItems: 'center'}}>
                        <img className="more-people" src={morePeopleIcon} alt="" />
                        <span className="user-people"style={{marginLeft: '5px'}}><span className="count">3,816</span> users already join </span>
                      </div>
                      <p className="user-people">🔥 <span className="count">Stack up to 30 days while promotion </span> </p>
                    </>
                  }
                  <p className="copy-link">Click on the button 👇</p>
                  <Button color="themeColor" variant="contained" className="copy-link-btn rest-primary-btn" onClick={()=>{invitedLink()}}>
                    <span className="font_b">Invite Now</span>
                  </Button>
                </div>
              </div>
              <div className="line" />
              <GlobalLoginButton />
            </div>

          </div>

        </div>

        {!routeMeta.hiddenOpenSeaEorrTips && !!openseaErrorTips ?
          <div className='opensea-error-tips'>
            <div className="tips-content">
              <span className='iconfont icon-a-InfoCircle'></span>
              <span className='text'>{openseaErrorTips}</span>
            </div>
            <i className="iconfont icon-Close" onClick={e => {
              closeOpenseaErrorTips()
            }}></i>
          </div>
          : null
        }
      </div>
      {/* 小于750再显示 */}
      { windowClientInfo.width <= 750 ?
        <>
          {/* 搜索菜单 */}
          { showResult && showSearchInput ?
            <div className='global-header-navbar-max750'>
                <div className='global-search-collection-content hidden-scrollbar' style={{top: !routeMeta.hiddenOpenSeaEorrTips && !!openseaErrorTips ? '-105px' : '-45px'}}>
                  {searchList.map((item, index) =>
                    <div className="global-search-collection-item flex cursor-p"
                      key={`search_item_${index}`}
                      ga-event-data={`navbar_search/${searchKey}/100`}
                      onClick={()=>{
                        toAssetList(item)
                      }}
                    >
                      <img src={item.image_url} alt="" />
                      <div className="content flex">
                        <div className="name">{ item.name }</div>
                        <div className="address" address-data={item.address}>{ item.address }</div>
                      </div>
                    </div>
                  )}
                </div>
            </div>
          : null }
          {/* 个人中心menu */}
          { showGlobalNavBarMenu ?
            <div className="menu-body-max750">
              { isSubscription && (accountInvitedInfo && !accountInvitedInfo.channel) &&
                <div className="menu-membership">
                  <div className="member-title">Subscription active until</div>
                  <div className="member-date">
                    <img className="icon" src={membershipIcon} alt="" />
                    {moment(subscriptionAt).format('MMM DD, YYYY')}
                  </div>
                </div>
              }
              <div className="menu-list">
                <div className="menu-list-item" ga-event-data="wallet_subscription//100" onClick={()=>{metamaskMenuClick('subscription')}}>
                  <img className="icon" src={buymembershipIcon} alt="" /> Buy Subscription
                </div>
                <div className="menu-list-item" ga-event-data="wallet_my_bid//100" onClick={()=>{metamaskMenuClick('mybid')}}>
                  <img className="icon" src={mybidIcon} alt="" /> My Bid
                </div>
                <div className="menu-list-item" ga-event-data="wallet_logout//100" onClick={()=>{metamaskMenuClick('logout')}}>
                  <img className="icon" src={logoutIcon} alt="" /> Log Out
                </div>
              </div>

            </div>
          : null }
        </>
        : null
      }
    </>
  )

}

export default GlobalHeaderNavbar
