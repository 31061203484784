import GasFeeJson from '@/assets/json/buy/gas.json'

// 初始状态
const defaultstate = {
  assetsDetail: null,
  marketCapDetail: null,
  marketCapData: null,
  totalCountFromOpensea: null,
  focusPageStatus: true, // 浏览器 tab 是否聚焦于当前页面
  collectionName: '',     // 收藏完毕之后的 Collection Name
  collectionFloorPrice: null, // Collection Floor Price
  collectionListingsLength: null, // Collection Listings Length
  
  nftDetailsDialogOptions: { // 全局 NFT Details 弹窗配置
    visible: false, // 控制显示隐藏
    collectionName: null, // Collection Name
    collectionRoyalty: null, // Collection Royalty
    contractAddress: null, // 合约地址
    tokenId: null, // Token ID
    pendingTransactionList: [], // pending transaction list
    origin: null // 来源
  },

  successfulTransactionData: {}, // 完成的交易

  nftBuyStatusDialogOptions: { // 全局 NFT 购买状态 弹窗
    visible: false, // 控制显示隐藏
    status: 'pending', // 购买状态 1. pending 2. success 3. fail
    renderData: { // 购买弹窗里的数据
      imgSrc: null,
      nftTokenId: null,
      rank: null,
      currentPrice: null
    }
  },

  bluechipDetailValue: false, //是否显示bluechip详情
    
  buyGasData: {}, // 购买 gas data
  gasFeeType: 0,
  gasFeeList: GasFeeJson, // gas fee
  showGlobalCollectionSearch: false, //是否显示搜索
  showGlobalNavBarMenu: false, //是否显示顶部navbar菜单
  loginProps: { //登录方法
    show: false,
    loginCallback: null,
  },
  calendarProps: { //calendar方法
    show: false,
    loginCallback: null,
  },
  navbarTips: true, //navbar提示
  windowClientInfo: { // 页面宽高
    width: 0,
    height: 0,
  },
  openseaAssets: null, // opensea nft assets
  openseaAssetsLoading: false, // opensea nft assets loading
  existStatus: true, // 项目是否存在
  openseaAssets: null, // opensea nft assets
  openseaAssetsLoading: false // opensea nft assets loading
}

const reducer = (state = defaultstate, action) => {
  switch (action.type) {
    case 'updateOpenseaAssets':
      return {...state, openseaAssets: action.data }
    case 'updateOpenseaAssetsLoading':
      return {...state, openseaAssetsLoading: action.data }
    case 'updateNavbarTips':
      return {...state, navbarTips: action.data }
    case 'updateAssetsDetail':
      return {...state, assetsDetail: action.data }
    case 'updateMarketCapDetail':
      return {...state, marketCapDetail: action.data}
    case 'updateMarketCapData':
      return {...state, marketCapData: action.data}
    case 'updateTotalCountFromOpensea':
      return {...state, totalCountFromOpensea: action.data}
    case 'updateFocusPageStatus':
      return {...state, focusPageStatus: action.data}
    case 'updateCollectionName':
      return {...state, collectionName: action.data}
    case 'updateNftDetailsDialogOptions':
      return {...state, nftDetailsDialogOptions: action.data}
    case 'updateSuccessfulTransactionData':
      return {...state, successfulTransactionData: action.data}
    case 'updateNftBuyStatusDialogOptions':
      return {...state, nftBuyStatusDialogOptions: action.data}
    case 'updateCollectionFloorPrice':
      return {...state, collectionFloorPrice: action.data}
    case 'updateCollectionListingsLength':
      return {...state, collectionListingsLength: action.data}
    case 'updateBluechipDetailValue':
      return {...state, bluechipDetailValue: action.data}
    case 'updateBuyGasData':
      return {...state, buyGasData: action.data}
    case 'updateGasFeeType':
      return {...state, gasFeeType: action.data}
    case 'updateGasFeeList':
      return {...state, gasFeeList: action.data}
    case 'showGlobalCollectionSearch':
      return {...state, showGlobalCollectionSearch: action.data}
    case 'updateShowGlobalNavBarMenu':
      return {...state, showGlobalNavBarMenu: action.data}
    case 'updateLoginProps':
      return {...state, loginProps: action.data}
    case 'updateShowCalendar':
      return {...state, calendarProps: action.data}
    case 'updateCurrentPageMeta':
      return {...state, currentPageMeta: action.data}
    case 'updateWindowClientInfo':
      return {...state, windowClientInfo: action.data}
    case 'updateExistStatus':
      return {...state, existStatus: action.data}
    default:
      return state;
  }
}

export default reducer;
