// import React, { lazy } from 'react';
// import { useRoutes } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, useRoutes } from 'react-router-dom';

// import CollectionDetailActivity from '../views/collection/activity/index'
// import CollectionDetailTraits from '../views/collection/traits/index'
// import CollectionDetailBluechip from '../views/collection/bluechip/index'

// import LandingPage from '../views/landing-page/index'
// import Subscription from '../views/subscription'

// import TraitDetail from '../views/collection/traitDetail'
// import PageNotFound from '../views/not-found'
// import Invited from '../views/invited/index'
import BaseLayout from '../layout/BaseLayout'
// import CollectionIndex from '../views/collection/index'
// import CollectionDetailItems from "../views/collection/items/index"
// import IframeBuyNft from "../views/iframe/buy-nft/index"
// import Login from '../views/login/index'
// import Snipe from "../views/snipe"
// import Discount from "../views/discount/index"
// import FreeMint from "../views/free-mint/index"



const CollectionDetailActivity = lazy(() => import('../views/collection/activity/index'))
const CollectionDetail = lazy(() => import('../views/collection/collection-info/index'))
const CollectionDetailTraits = lazy(() => import('../views/collection/traits/index'))
const CollectionDetailBluechip = lazy(() => import('../views/collection/bluechip/index'))
const LandingPage = lazy(() => import('../views/landing-page/index'))
const Subscription = lazy(() => import('../views/subscription'))
const TraitDetail = lazy(() => import('../views/collection/traitDetail'))
const PageNotFound = lazy(() => import('../views/not-found'))
const Invited = lazy(() => import('../views/invited/index'))
// const BaseLayout = lazy(() => import('../layout/BaseLayout'))
const CollectionIndex = lazy(() => import('../views/collection/index'))
const CollectionIndexV2 = lazy(() => import('../views/collection/index-v2'))
// const CollectionDetailItems = lazy(() => import('../views/collection/items/index'))
const IframeBuyNft = lazy(() => import('../views/iframe/buy-nft/index'))
const Login = lazy(() => import('../views/login/index'))
const Snipe = lazy(() => import('../views/snipe'))
const Discount = lazy(() => import('../views/discount/index'))
const FreeMint = lazy(() => import('../views/free-mint/index'))
const Claim = lazy(() => import('@/views/claim'))

const Airdrop = lazy(() => import('@/views/airdrop/index'))


const BotLogin = lazy(() => import('@/views/bot-login'))

const RouteList = () => {

  const routes = useRoutes([
    {
      path: "/",
      element: <BaseLayout/>,
      children: [
        {
          path: "/",
          element: <Suspense fallback={null}><LandingPage/></Suspense>
        },
        {
          path: "/login",
          element: <Suspense fallback={null}><Login/></Suspense>
        },
        {
          path: "/subscription",
          element: <Suspense fallback={null}><Subscription/></Suspense>
        },
        {
          path: "/invited",
          element: <Suspense fallback={null}><Invited/></Suspense>
        },
        {
          path: "/collection/:contract_address/items",
          element: <Suspense fallback={null}><CollectionDetail/></Suspense>
        },
        {
          path: "/collection/:contract_address/activity",
          element: <Suspense fallback={null}><CollectionDetail/></Suspense>
        },
        {
          path: "/collection/:contract_address/bluechip",
          element: <Suspense fallback={null}><CollectionDetail/></Suspense>
        },
        // {
        //   path: "/collection/:contract_address/items",
        //   element: <Suspense fallback={null}><CollectionDetailItems/></Suspense>
        // },
        // {
        //   path: "/collection/:contract_address/activity",
        //   element: <Suspense fallback={null}><CollectionDetailActivity/></Suspense>
        // },
        // {
        //   path: "/collection/:contract_address/analysis",
        //   element: <Suspense fallback={null}><CollectionDetailTraits/></Suspense>
        // },
        {
          path: "/collection/:contract_address/bluechip",
          element: <Suspense fallback={null}><CollectionDetailBluechip/></Suspense>
        },
        {
          path: "/collections",
          element: <Suspense fallback={null}><CollectionIndexV2 /></Suspense>
        },
        // {
        //   path: "/collections-v2",
        //   element: <Suspense fallback={null}><CollectionIndexV2 /></Suspense>
        // },
        {
          path: '/collection/:contract_address/analysis/:traits_type/:traits_value/:traits_id',
          element: <Suspense fallback={null}><TraitDetail /></Suspense>
        },
        {
          path: '/iframe/buy-nft',
          element: <Suspense fallback={null}><IframeBuyNft /></Suspense>
        },
        {
          path: '/snipe',
          element: <Suspense fallback={null}><Snipe /></Suspense>
        },
        {
          path: '/discount',
          element: <Suspense fallback={null}><Discount /></Suspense>
        },
        {
          path: '/mint',
          element: <Suspense fallback={null}><FreeMint /></Suspense>
        },
        {
          path: '/claim',
          element: <Suspense fallback={null}><Claim /></Suspense>
        },
        {
          path: "/ordg-airdrop",
          element: <Suspense fallback={null}><Airdrop/></Suspense>
        },
        {
          path: "*",
          element: <Suspense fallback={null}><PageNotFound /></Suspense>
        },
      ]
    },
    {
      path: "/bot-login",
      element: <Suspense fallback={null}><BotLogin/></Suspense>,
    }
  ])
  return routes
}

export default RouteList
