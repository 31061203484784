import React, { useState, useEffect, useRef } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import RouteList from './router/index'
import store from './store/index'

// Style
import './App.less'
import './assets/css/public.less'
import './assets/css/index.less'
import "./assets/iconfont/iconfont.css" // iconfont

// SDK Api Key
import { ElementApiKey } from './config/sdk-api-key'

// Utils
import {initGlobalMethod } from "./utils/globalMethod"
import { Buffer } from "buffer"
window.Buffer = Buffer

// 引入web3
const Web3 = require('web3')
require('@metamask/legacy-web3')
const detectEthereumProvider = require("@metamask/detect-provider")

// Opensea SDK
const openseaJs = require("opensea-js")
const { OpenSeaSDK, Network } = openseaJs
const openseaJsV2 = require("opensea-js-v2.0.0")
const { OpenSeaPort} = openseaJsV2

// Element SDK
// const ElementJs = require("element-js-sdk")
// import { ethers } from 'ethers'


function App() {

  useEffect(()=>{
    // window.process = {
    //   ...window.process,
    // };
    // process.versions = { node: "", v8: "" }
    // console.log('START_ENV=>>',process.env.START_ENV)

    // 初始化公共方法
    initGlobalMethod()
    // 初始化 web3 方法
    initWeb3()
    // 初始化 opensea
    initSeaport()
    // 初始化 element
    // initElement()
  },[])
  const setRoutes = () => {
    return (
      <Router>
        <RouteList />
      </Router>
    )
  }
  // 初始化web3方法
  const initWeb3 = () => {
    if(!!window.web3){
      if (typeof window.web3 !== 'undefined') {
        window.web3 = new Web3(window.web3.currentProvider);
      } else {
        // window.web3 = new Web3(new Web3.providers.HttpProvider(this.providerUrl));
      }
    }
    console.log('web3=>>>', window.web3)
  }

  // 初始化 element
  // const initElement = () => {
  //   if (!window.ethereum) return
  //   const Provider = new ethers.providers.Web3Provider(window.ethereum)
  //   const sdk = new ElementJs.ElementSDK({
  //     networkName: ElementJs.Network.ETH, 
  //     apiKey: ElementApiKey,
  //     signer: Provider
  //   })
  //   window.$element = sdk
  // }

  // 初始化 opensea
  const initSeaport = async () => {
    let providerUrl = 'https://mainnet.infura.io/v3/6360e2a2fe3e44f2a9b9f763c34e5d6e'
    // 映射 网络地址
    let networkNameMap = {
      '0x1': 'Main',
      '0x4': 'Rinkeby'
    }
    if(window.web3){
      try {

        // 获取 provider
        const provider = await detectEthereumProvider()

        // 当前网络（十六进制）
        const _chainId = await provider.request({ method: 'eth_chainId'})
        // console.log('_chainId', _chainId)

        // // 当前网络（十进制）1:Main 4:Rinkeby
        // const chainId = Number(web3.utils.toDecimal(_chainId))

        // 决定 opensea-js 实例 API 连接的网络（Main 或 Rinkeby）
        const networkName = networkNameMap[_chainId]

        // apiKey
        const apiKey = ''
        // console.log('networkName=>>', networkName, Network[networkName])
        // 创建 opensea-js 实例的参数
        const paramsSeaport = {
          networkName: Network[networkName],
          apiKey
        }

        // 创建 opensea-js 实例
        let $seaport = new OpenSeaSDK(provider, paramsSeaport)
        window.$seaport = $seaport

        // opensea v2.0.0
        let $seaportV2 = new OpenSeaPort(provider, paramsSeaport)
        window.$seaportV2 = $seaportV2

      } catch (e) {
        console.log('初始化 opensea-js 实例错误：', e)
      }
    }else{
      // console.log('没有web3')
    }


  }

  return (
    <Provider store={store}>
      <div className="App">
        {setRoutes()}
      </div>
    </Provider>
  );
}

export default App;
