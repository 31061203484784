export function calc_outlier(data) {
  console.time('outlier')

  if(!data.length){
    return {
      lower_bound: 0,
      upper_bound: 0
    }
  }
  // 排序
  let prices_list = data.map(x => x.pe).sort(function(a, b) {
    return b - a;
  });

  let list_len = prices_list.length;

  // 平均数
  let avg = prices_list.reduce((a, b) => a + b) / list_len;

  // 中位数
  let mid = 0
  if(list_len % 2 === 0){ //判断数字个数是奇数还是偶数
    mid = (prices_list[list_len / 2] + prices_list[list_len / 2 - 1]) / 2    //偶数个取中间两个数的平均数
  }else{
    mid = prices_list[parseInt(list_len / 2)]    //奇数个取最中间那个数
  }

  let lower_bound, upper_bound

  // 中位数和平均数的差值
  let w = avg - mid

  if (w < 0) {
    w = w * -1
  }
  if (w < 1) {
    lower_bound = (mid - ( w ** 2 )) / 3
    upper_bound = (mid + ( w ** 2 )) * 4
  } else {
    lower_bound = (mid - ( Math.sqrt(w) )) / 3
    upper_bound = (mid + ( Math.sqrt(w) )) * 4
  }
  let n = prices_list.filter(item => item > lower_bound && item < upper_bound).length
  let n2 = prices_list.filter(item => item > upper_bound).length

  if (2 * n < list_len) {
    upper_bound = prices_list[0]
    lower_bound = prices_list[list_len - 1]
  } else {
    upper_bound = prices_list[n2]
    lower_bound = prices_list[n + n2 - 1]
  }
  console.timeEnd('outlier');
  let lower = lower_bound < prices_list[list_len - 1] ? prices_list[list_len - 1] : lower_bound 
  let upper = upper_bound > prices_list[0] ? prices_list[0] : upper_bound
  return {
    lower_bound: lower == upper ? lower * 0.9 : lower,
    upper_bound: upper == lower ? upper * 1.1 : upper
  }
  /**
   * data = sorted(price, reverse=True)
   *   list_len = len(data)
   *
   *   avg = round(float(np.mean(data)), 4)
   *   mid = round(float(np.median(data)), 4)
   *   print(f'平均值====={avg}')
   *   print(f'中位数====={mid}')
   *   w = avg - mid
   *   print(f'平均-中位数====={w}')
   *
   *   if w<0:
   *     w = w * -1
   *
   *   if w<=1:
   *     lower_bound = (mid - (w**2)) / 2
   *     upper_bound = (mid + (w**2)) * 2
   *   else:
   *     lower_bound = (mid - (math.sqrt(w))) / 2
   *     upper_bound = (mid + (math.sqrt(w))) * 2
   *
   *   n = sum(lower_bound < i < upper_bound for i in price)
   *
   *   if 2*n < list_len:
   *     upper_bound = data[0]
   *     lower_bound = data[list_len-1]
   *
   *   if upper_bound > data[0]:
   *     upper_bound = data[0]
   *
   *   if lower_bound < data[list_len-1]:
   *     lower_bound = data[list_len-1]
   *
   *   lower_bound = round(float(lower_bound), 4)
   *   upper_bound = round(float(upper_bound), 4)
   * **/


  // let prices = data.map(x => x.pe).sort(function(a, b) {
  //   return b - a;
  // });
  // let sum = prices.reduce(function(a, b){
  //   return a + b;
  // });
  //
  // let avg = sum / prices.length
  //
  // let mid = prices[Math.round(prices.length * 1 / 2) - 1]
  //
  // let w = avg - mid
  //
  // if (w<0) {
  //   w = w * -1;
  // }
  //
  // let lower_bound = (mid - (Math.sqrt(w))) / 2
  // let upper_bound = (mid + (Math.sqrt(w))) * 2
  //
  // let num = prices.filter(item => lower_bound <= item && item <= upper_bound).length
  //
  // if (2*num < prices.length) {
  //   upper_bound = prices[0];
  //   lower_bound = prices[prices.length-1];
  // }
  // console.timeEnd('outlier');
  // return {
  //   lower_bound: lower_bound < prices[prices.length-1] ? prices[prices.length-1] : lower_bound,
  //   upper_bound: upper_bound > prices[0] ? prices[0] : upper_bound
  // }
}
// load图片，提前渲染
export const _loadImg = (url) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = url;
    if(img.complete) {
      resolve({code : 1000})
    }
    else {
      img.onload = function() {
        resolve({code : 1000})
      };
      img.onerror = function() {
        resolve({code : 2000})
      };
    }
  })
}

export const clickReportGa = options => {
  let { name = '', label = '', category = 'Click', value = 500 } = options
  try {
    window.gtag('event', name, {
      'event_category': category,
      'event_label': label,
      value
    })
  } catch (e) {
    console.error(e)
  }
}

// 图片加载失败
export const imgOnError = ({event, props}) => {
  let imgDom = event.nativeEvent.srcElement;
  let src = imgDom.src
  if(!src.includes('empty.png')){
    // 失败，展示默认图
    imgDom.src = `${props.IMAGEURL}/empty.png`
  }
}

// 判断数据是否为空
export const checkDataDefaults = (value, defaultValue) => {
  let type = typeof value
  if(type !== 'number' && type !== 'string'){
    return defaultValue
  }
  return null
}
