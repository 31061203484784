import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { updateNftsStore } from '@/store/modules/nfts/actionCreators'
import './index.less'
const Message = (props) => {
  const snackbarAlert = useSelector(state => state.nfts.snackbarAlert)
  const navbarTips = useSelector(state => state.global.navbarTips)
  const dispatch = useDispatch()
  // 关闭消息提示
  const handleClose = (event, reason) => {
    dispatch(updateNftsStore({
      type: 'updateSnackbar',
      data: {
        show: false,
        type: '',
        text: ''
      }
    }))
  };
  // 130 78
  return (
    <Snackbar transitionDuration={{enter: 100, exit: 0}} sx={{top: navbarTips ? '130px !important' : '78px !important'}}  autoHideDuration={snackbarAlert.during} anchorOrigin={{vertical: 'top',horizontal: 'center'}} onClose={() => handleClose()} open={snackbarAlert.show} >
      {/* <Alert
        className="global-snackbar-alert"
        severity={snackbarAlert.type}>{snackbarAlert.text}</Alert> */}
        <div className={`global-snackbar-alert ${snackbarAlert.type}`}>
          <i className={`iconfont ${snackbarAlert.type == 'success' ? 'icon-a-TickSquare2' : snackbarAlert.type == 'error' ? 'icon-Cancelled' : 'icon-a-InfoCircle'} `}></i>
          {snackbarAlert.text}
        </div>
    </Snackbar>
  )
}

export default Message
