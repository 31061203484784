import moment from 'moment';
// 千分位分割 及 保留小数点后三位
const filterSupply = (val, type) => {
  if (val === '-') return '-'
  if(!val){
    return 0
  }
  let temp = ''
  if (type === 'volume') {
    temp = (val % 1 == 0) ? val : Math.round(val * 100) / 100
  } else if (type === 'price') {
    temp = (val % 1 == 0) ? val : Math.round(val * 10000) / 10000
    if (temp % 1 !== 0) {
      let str = temp.toString().split('.')[1]
      let tempNumber = temp.toString().split('.')[0]
      return `${parseInt(tempNumber).toLocaleString('en-US')}.${str}`
    }
    // return
  } else if (type === 'score') {
    temp = Math.round(val)
  } else {
    temp = (val % 1 == 0) ? val : Math.round(val * 1000) / 1000
  }
  return temp ? temp.toLocaleString('en-US') : 0
}
// 空格分割 及 首字母大写
const filterTraitTitle = (value) => {
  if(!value){
    return ''
  }
  let trait = value.split(' ');
  return trait.map(item => item ? item.firstUpperCase(): '' ).join(' ')
}

const timeFilter = (val) => {
  return moment(val).format('MMMM D YYYY, HH:mm')
}

const filterFormatGwei = (val) => {
  return window.web3.utils.fromWei(val, 'gwei')
}

const filterShowAddress = (address) => {
  if(!address){
    return ''
  }
  let text = `${address.slice(0,4)}...${address.slice(address.length-4)}`;
  return text.toUpperCase()
}

const filterFloorValue = (val) => {
  return Math.floor(val)
}

const filterFormatDuration = (time, newTime) => {
  if (newTime === 0 || time === 0) return ''
  return newTime - time
}

export {
  filterSupply,
  filterTraitTitle,
  timeFilter,
  filterFormatGwei,
  filterShowAddress,
  filterFloorValue,
  filterFormatDuration
}
