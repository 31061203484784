// 初始状态
const defaultstate = {
  isSubscription: false, //是否订阅
  getSubscriptionEnd: false, //获取订阅完成
  subscriptionAt: null, //订阅到期时间
  accountAddress: null, //address
  getAccountAddressEnd: false, //获取address完成
  metaMaskInstalled: false, //是否安装metamask
  isAccredit: false,  //是否授权过
  accountInvitedInfo: null, //账户邀请信息

}

const reducer = (state = defaultstate, action) => {
  switch (action.type) {
    case 'updateIsSubscription':
      // action.data = new Date().getTime() + 365*24*60*60*1000
      let isSubscription = false
      try {
        isSubscription = new Date(action.data).getTime() > new Date().getTime()
      } catch (error) {
      }
      return {...state, subscriptionAt: action.data, isSubscription, getSubscriptionEnd: true }
      // return {...state, subscriptionAt: new Date().getTime() + 365*24*60*60*1000, isSubscription, getSubscriptionEnd: true }
    case 'updateAccountAddress':
      if(!!action.data){
        localStorage.setItem('address', action.data)
      }else{
        localStorage.removeItem('address')
      }
      console.log('getAccountAddressEnd')
      return {...state, accountAddress: action.data, getAccountAddressEnd: true,}
    case 'updateMetaMaskInstalled':
      return {...state, metaMaskInstalled: action.data}
    case 'updateAccountInvitedInfo':
      return {...state, accountInvitedInfo: action.data}
    case 'updateIsAccredit':
      return {...state, isAccredit: action.data}
    case 'updateAccountLoad':
      return { ...state, getAccountAddressEnd: action.data }
    default:
      return state;
  }
}
export default reducer;
