//打包运行环境
let node_env = process.env.NODE_ENV || 'development'
let start_env = process.env.START_ENV || 'staging'
if(start_env === 'prodnets'){
  start_env = 'prod'
}

let apiLink = 'https://api.b4fomo.io'
let forwardLink = '' //转发接口
let imageUrl = 'https://images.b4fomo.io';
let openseaErrorApi = node_env === 'development' ? '/openseaErrorApi' : 'https://rcs5256c5q75.statuspage.io'
let openseaApi = node_env === 'development' ? '/openseaApi' : 'https://api.opensea.io'
let googleCdnApi = 'https://storage.googleapis.com' //firestore cdn域名

if(node_env === 'development'){
  forwardLink = '/forwardLink'
  googleCdnApi = '/googleCdnApi'
  if(start_env === 'testnets'){
    apiLink = '/testnetsApi'
  }else if(start_env === 'staging'){
    apiLink = '/stagingApi'
  }else{
    apiLink = '/wwwApi'
  }
}else if(start_env === 'testnets'){ //testnets
  apiLink = 'https://testnets-api.b4fomo.com';
  // forwardLink = 'https://api.gweiii.com'
}else if(start_env === 'staging'){  //正式的测试
  apiLink = 'https://stagingnets-api.b4fomo.com';
  // forwardLink = 'https://api.gweiii.com'
}else{                                     //prod
  // forwardLink = 'https://api.gweiii.com'
}


if(start_env === 'testnets'){ //testnets
  imageUrl = 'https://testnets-images.b4fomo.com';
}else if(start_env === 'staging'){  //正式的测试
  imageUrl = 'https://stagingnets-images.b4fomo.com';
}

// 谷歌分析ID
export const GA_ID = node_env === 'development' || start_env === 'testnets' ? 'G-3WTGBM7NP9' : start_env === 'prod' ? 'G-886Z7NCM36' : 'G-QDLD3NLKMJ'
export const APILINK = apiLink
export const FORWARDLINK = forwardLink;
export const IMAGEURL = imageUrl
export const OpenseaErrorApi = openseaErrorApi;
export const NFTNERDSCDN = node_env === 'development' ? '/nftnerdsCDN' : 'https://storage.googleapis.com'
export const GoogleCdnApi = googleCdnApi
export const OpenseaApi = openseaApi

// 订阅收款地址
export const subscriptionToAddress = start_env === 'testnets' || start_env === 'staging' ? '0x05a7Bb0ff3b5D1C9a56C3740Bc50eA808ca3D9D3' : '0x2E4cb69A4505d050Fc288094F8A5747A5049610E';
