import GasFeeJson from '@/assets/json/buy/gas.json'
// 初始状态
const defaultstate = {
  // 显示搜索条件
  showFilterStatus: false,
  // 显示搜索条件标签
  showFilterLabelStatus: false,
  // nfts列表数据
  nftsList: [],
  // 筛选条件
  originFilterTraitsData: {},
  filterTraitsCount: {},  // Traits筛选个数
  filterLableData: [],
  filterTraitsType: null, // Traits筛选类型
  filterTraitsTypeSeachVal: null, //Traits筛选类型-搜索
  // alert
  snackbarAlert: {
    show: false,
    type: 'success',
    text: 'success'
  },
  // 当前路由
  currentRoute: null,
  // 购买 gas data
  buyGasData: {},
  gasFeeType: null,
  // gas fee 
  gasFeeList: GasFeeJson,
  showCalendar: false, // 开图时间弹框
  collectionList: [], // 关注列表
  existStatus: true, // 项目是否存在
  listingData: {}, // 当前listing信息
  collectionDetailFromNerds: {},
  noProjectMarketCap: {},  // 没有项目时手动计算的sales 和 avg_price
  openseaStatus: {}
  // traitsFloorPriceMap: {} // traits 下的 floor price
}

const reducer = (state = defaultstate, action) => {
  switch (action.type) {
    case 'updateShowFilterStatus':
      return {...state, showFilterStatus: action.data }
    case 'updateShowFilterLabelStatus':
      return {...state, showFilterLabelStatus: action.data}
    case 'updateOpenseaStatus': 
      return {...state, openseaStatus: action.data}
    case 'updateCurrentRoute':
      return {...state, currentRoute: action.data }
    case 'updateCollectionDetailFromNerds': 
      return {...state, collectionDetailFromNerds: action.data }
    case 'updateNoProjectMarketCap': 
      return {...state, noProjectMarketCap: action.data}
    case 'updatenNftsList':
      // traits按照字母排序
      let data = action.data
      data.forEach(item => {
        item.traits && item.traits.sort()
      })
      return {...state, nftsList: data}
    case 'updateOriginFilterTraitsData':

      let { address, traits } = action.data
      let originFilterTraitsData = JSON.parse(JSON.stringify(state.originFilterTraitsData))
      // // 根据字母顺序排列，里面根据count降序排序
      // if(!traits) traits = {}
      // // console.log(traits);
      // let sortArr = Object.keys(traits)
      // sortArr = sortArr.sort()
      // let temp = {}
      // sortArr.forEach(key => {
      //   let countArr = Object.keys(traits[key]) || []
      //   let stemp = {}
      //   countArr = countArr.sort((a, b) =>{
      //     return traits[key][a].count - traits[key][b].count
      //   })
      //   countArr.forEach(skey => {
      //     if (skey !== 'None') {
      //       stemp[skey] = traits[key][skey]
      //     }
      //   })
      //   if (traits[key].None) {
      //     temp[key] = {...stemp, None: traits[key].None}
      //   } else {
      //     temp[key] = stemp
      //   }
      // })
      // let tempObj = {}
      // Object.keys(temp).forEach(item => {
      //   if (item !== 'Trait Count') {
      //     tempObj[item] = temp[item]
      //   }
      // })
      // originFilterTraitsData[address] = {...tempObj, 'Trait Count': temp['Trait Count']}
      originFilterTraitsData[address] = traits
      return {...state, originFilterTraitsData: originFilterTraitsData }
    case 'updateFilterTraitsCount':    
      return {...state, filterTraitsCount: action.data}
    case 'updateFilterLableData':
      // traits相关
      // let clicked = []
      // if(state.filterTraitsCount) {
      //   Object.keys(state.filterTraitsCount).forEach(key => {
      //     if(state.filterTraitsCount[key] && state.filterTraitsCount[key].length) {
      //       clicked = [...clicked, ...state.filterTraitsCount[key]]
      //     }
      //   })
      // }
      // if(clicked.length >= 0) {
      //   let query = {
      //     ...state.currentRoute,
      //     traits: clicked.toString()
      //   }
      //   Object.keys(query).forEach(key => {
      //     if(!query[key]){
      //       delete query[key]
      //     }
      //   })
        // router.push({
        //   query
        // })
      // }
      return {...state, filterLableData: JSON.parse(JSON.stringify(action.data)) }
    case 'updateSnackbar':
      return {...state, snackbarAlert: action.data}
    case 'updateBuyGasData':
      return {...state, buyGasData: action.data}
    case 'updateFilterTraitsType':
      return {...state, filterTraitsType: action.data}
    case 'updateFilterTraitsTypeSeachVal':
      return {...state, filterTraitsTypeSeachVal: action.data}
    case 'updateTraitsDate':
      let filterLableData = state.filterLableData
      filterLableData[data.key] = data.value
      return {...state, filterLableData: filterLableData}
    case 'updateGasFeeType':
      return {...state, gasFeeType: action.data}
    case 'setGasFeeListMutation':
      return {...state, gasFeeList: action.data}
    case 'updateShowCalendar':
      return {...state, showCalendar: action.data}
    case 'updateCollectionList':
      return {...state, collectionList: action.data}
    case 'updateTraitsFloorPriceMap':
      return {...state, traitsFloorPriceMap: action.data}
    case 'updateExistStatus':
      return {...state, existStatus: action.data}   
    case 'updateListingData':
      return {...state, listingData: action.data}
    default:
      return state;
  }
}

export default reducer;
