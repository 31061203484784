import React, { useState, useEffect, useRef, } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateGlobalStore } from '@/store/modules/global/actionCreators'

// css
import './index.less'
import HeaderStatusTips from '@/components/global/components/HeaderStatusTips'
import HeaderNavbar from '@/components/global/components/HeaderNavbar'

const NavigationView = (props) => {
  const { routeMeta={} } = props
  const dispatch = useDispatch()

  const [statusTipsVisible, setStatusTipsVisible ] = useState(false)
  const [navigationBarVisible, setNavigationBarVisible ] = useState(false)
  
      
  /**
   * init
   */
  useEffect(() => {

  }, [])

  useEffect(() => {
    let { statusTipsVisible, navigationBarVisible } = routeMeta
    setStatusTipsVisible(statusTipsVisible)
    setNavigationBarVisible(navigationBarVisible)
  }, [routeMeta])


  return (
    <div className="navigation-view-wrapper" onClick={() => {
      dispatch(updateGlobalStore({
        type: 'updateBluechipDetailValue',
        data: null
      }))
    }}>
      {/* Status Tips */}
      { statusTipsVisible && <HeaderStatusTips/> }
      {/* Navigation Bar */}
      { navigationBarVisible && <HeaderNavbar
        routeMeta={routeMeta}
      /> }
    </div>
  )

}

export default NavigationView
