import { APILINK } from '@/config/config'
import axios from '../axios'

// 获取 banner
export const getBanner = (position) => {
  return axios({
    url: `${APILINK}/nft/v1/banners`,
    method: 'GET',
    params: {
      position
    }
  })
}
