// 映射 网络地址
const networkNameMap = {
  1: 'Main',
  4: 'Rinkeby'
}
const openseaLink = 'https://opensea.io' // 'https://testnets.opensea.io'

export {
  networkNameMap,
  openseaLink
}