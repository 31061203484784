const routeMeta = {
  "LandingPage":{
    title: 'b4fomo - Fastest NFTs ranking platform',
    name: 'LandingPage',
    statusTipsVisible: true, // 控制 status tips 显示或隐藏
    navigationBarVisible: true, // 控制 navbar 显示或隐藏
    hiddenOpenSeaEorrTips: true, // 控制 open sea tips 显示或隐藏
  },
  "Airdrop":{
    title: 'b4fomo - Fastest NFTs ranking platform',
    name: 'Airdrop',
    statusTipsVisible: true, // 控制 status tips 显示或隐藏
    navigationBarVisible: true // 控制 navbar 显示或隐藏
  },
  "Claim":{
    title: 'b4fomo - Fastest NFTs ranking platform',
    name: 'Claim',
    statusTipsVisible: true, // 控制 status tips 显示或隐藏
    navigationBarVisible: true // 控制 navbar 显示或隐藏
  },
  "Collections": {
    title: 'b4fomo - Fastest NFTs ranking platform',
    name: 'Collections',
    oneScreenDisplay: false, //一屏展示
    statusTipsVisible: true, // 控制 status tips 显示或隐藏
    navigationBarVisible: true, // 控制 navbar 显示或隐藏
    hiddenOpenSeaEorrTips: true, // 控制 open sea tips 显示或隐藏
    GALabel: 'collection',
  },
  // "Collections-V2": {
  //   title: 'b4fomo - Fastest NFTs ranking platform',
  //   name: 'Collections-v2',
  //   oneScreenDisplay: false, //一屏展示
  //   statusTipsVisible: true, // 控制 status tips 显示或隐藏
  //   navigationBarVisible: true, // 控制 navbar 显示或隐藏
  //   hiddenOpenSeaEorrTips: true, // 控制 open sea tips 显示或隐藏
  //   GALabel: 'collection',
  // },
  'CollectionInfo': {
    name: 'CollectionInfo',
    oneScreenDisplay: false, //一屏展示
    statusTipsVisible: true, // 控制 status tips 显示或隐藏
    navigationBarVisible: true, // 控制 navbar 显示或隐藏
    GALabel: 'CollectionInfo',
  },
  "AssetsList": {
    name: 'AssetsList',
    statusTipsVisible: true, // 控制 status tips 显示或隐藏
    navigationBarVisible: true, // 控制 navbar 显示或隐藏
    GALabel: 'items',
  },
  'Activity': {
    name: 'Activity',
    oneScreenDisplay: false, //一屏展示
    statusTipsVisible: true, // 控制 status tips 显示或隐藏
    navigationBarVisible: true, // 控制 navbar 显示或隐藏
    GALabel: 'Live View',
  },
  'Bluechip': {
    name: 'Bluechip',
    oneScreenDisplay: false, //一屏展示
    statusTipsVisible: true, // 控制 status tips 显示或隐藏
    navigationBarVisible: true, // 控制 navbar 显示或隐藏
    GALabel: 'bluechip',
  },
  'Analysis': {
    name: 'Analysis',
    oneScreenDisplay: false, //一屏展示
    statusTipsVisible: true, // 控制 status tips 显示或隐藏
    navigationBarVisible: true, // 控制 navbar 显示或隐藏
    GALabel: 'traits',
  },
  'AnalysisChart': {
    name: 'AnalysisChart',
    oneScreenDisplay: false, //一屏展示
    statusTipsVisible: true, // 控制 status tips 显示或隐藏
    navigationBarVisible: true, // 控制 navbar 显示或隐藏
  },
  'Snipe': {
    title: 'Snipe ｜ b4fomo',
    name: 'Snipe',
    oneScreenDisplay: true, //一屏展示
    statusTipsVisible: true, // 控制 status tips 显示或隐藏
    navigationBarVisible: true, // 控制 navbar 显示或隐藏
  },
  'Subscription': {
    name: 'Subscription',
    title: 'Subscription ｜ b4fomo',
    oneScreenDisplay: false, //一屏展示
    statusTipsVisible: true, // 控制 status tips 显示或隐藏
    navigationBarVisible: true, // 控制 navbar 显示或隐藏
    GALabel: 'subscription',
  },
  'invited': {
    name: 'invited',
    title: 'Referral Program ｜ b4fomo',
    oneScreenDisplay: false, //一屏展示
    statusTipsVisible: true, // 控制 status tips 显示或隐藏
    navigationBarVisible: true, // 控制 navbar 显示或隐藏
    GALabel: 'referral',
  },
  'IframeBuyNft': {
    name: 'IframeBuyNft',
    title: 'Subscription ｜ b4fomo',
    statusTipsVisible: false, // 控制 status tips 显示或隐藏
    navigationBarVisible: false, // 控制 navbar 显示或隐藏
  },
  'MyAlertList': {
    name: 'MyAlertList',
    title: 'MyAlertList ｜ b4fomo',
    statusTipsVisible: true, // 控制 status tips 显示或隐藏
    navigationBarVisible: true, // 控制 navbar 显示或隐藏
  },
  'Profit': {
    name: 'Profit',
    title: 'Flipped Profit ｜ b4fomo',
    statusTipsVisible: true, // 控制 status tips 显示或隐藏
    navigationBarVisible: true, // 控制 navbar 显示或隐藏
  },
  'Discount' :{
    name: 'Discount',
    title: 'Discount ｜ b4fomo',
    oneScreenDisplay: false, //一屏展示
    statusTipsVisible: true, // 控制 status tips 显示或隐藏
    navigationBarVisible: true, // 控制 navbar 显示或隐藏
    GALabel: 'Discount',
  },
  'FreeMint' :{
    name: 'FreeMint',
    title: 'Mints ｜ b4fomo',
    oneScreenDisplay: false, //一屏展示
    statusTipsVisible: true, // 控制 status tips 显示或隐藏
    navigationBarVisible: true, // 控制 navbar 显示或隐藏
    GALabel: 'Free Mint'
  },
  '404': {
    name: '404',
    title: '404',
    statusTipsVisible: true, // 控制 status tips 显示或隐藏
    navigationBarVisible: true, // 控制 navbar 显示或隐藏
  }
}
const getRouteMeta = (pathname) => {
  let urlSplit = pathname.split('/').filter(item => !!item)
  let currentPageName = ''
  console.log('pathname', pathname)
  if(pathname === '/' || pathname === ''){
    currentPageName = 'LandingPage'
  }else if(pathname === '/collections'){
    currentPageName = 'Collections'
  } 
  // else if(pathname === '/collections-v2'){
  //   currentPageName = 'Collections-V2'
  // }  
  else if(urlSplit[0] === 'collection' && urlSplit[2] === 'items'){
    currentPageName = 'AssetsList'
  } else if(urlSplit[0] === 'collection' && urlSplit[2] === 'activity'){
    currentPageName = 'Activity'
  } else if(urlSplit[0] === 'collection' && urlSplit[2] === 'bluechip'){
    currentPageName = 'Bluechip'
  } else if(urlSplit[0] === 'collection' && urlSplit[2] === 'analysis'){
    if(urlSplit.length === 3){
      currentPageName = 'Analysis'
    }else{
      currentPageName = 'AnalysisChart'
    }
  } else if(pathname === '/snipe'){
    currentPageName = 'Snipe'
  } else if(pathname === '/subscription'){
    currentPageName = 'Subscription'
  } else if(pathname === '/invited'){
    currentPageName = 'invited'
  } else if(pathname === '/iframe/buy-nft'){
    currentPageName = 'IframeBuyNft'
  } else if(pathname === '/notification'){
    currentPageName = 'Notification'
  } else if(pathname === '/alert/list'){
    currentPageName = 'MyAlertList'
  } else if(pathname === '/profit'){
    currentPageName = 'Profit'
  } else if(pathname === '/discount'){
    currentPageName = 'Discount'
  } else if(pathname === '/mint'){
    currentPageName = 'FreeMint'
  } else if(pathname === '/claim'){
    currentPageName = 'Claim'
  } else if(pathname === '/ordg-airdrop'){
    currentPageName = 'Airdrop'
  } 
  // else if(pathname.includes('/test')){
  //   currentPageName = 'CollectionInfo'
  // }

  return routeMeta[currentPageName] || {}
}
export default getRouteMeta
