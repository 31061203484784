import { combineReducers } from 'redux';
import { reducer as globalReducer } from "./modules/global/index"
import { reducer as nftsReducer } from "./modules/nfts/index"
import { reducer as liveViewReducer } from "./modules/liveView/index"
import { reducer as subscriptionReducer } from "./modules/subscription/index"
import { reducer as snipeReducer } from "./modules/snipe/index"

export default combineReducers({
  global: globalReducer,
  nfts: nftsReducer,
  liveView: liveViewReducer,
  subscription: subscriptionReducer,
  snipe: snipeReducer
});