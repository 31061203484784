import axios from 'axios';

//设置axios基础路径
const service = axios.create({
  timeout: 30000
})
axios.defaults.withCredentials = true // 若跨域请求需要带 cookie 身份识别
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// 请求拦截器
service.interceptors.request.use(config => { 
  // const token = window.localStorage.getItem('userToken') || window.sessionStorage.getItem('userToken');
  // //在每次的请求中添加token
  // config.data = Object.assign({}, config.data, {
  //   token: token,
  // })

  //设置请求头
  if(config.customHeaders){
    // console.log(1232131, config)
    config.headers = {
      ...config.headers,
      ...config.customHeaders
    }
  }
  // config.data = QS.stringify(config.data)
  return config
}, error => { 
    return error;
})

// 响应拦截器
service.interceptors.response.use(response => {

  // 本地模拟正式环境的会员
  // if(response.config.url && response.config.url.includes('/nft/v1/memberships')) {
  //   response.data.data.expired_at = '2023-06-11T10:27:38.041625Z'
  // }
  
  // if (response.code) {
  //   switch (response.code) {
  //     case 200:
  //       return response.data;
  //     case 401:
  //       //未登录处理方法
  //       break;
  //     case 403:
  //       //token过期处理方法
  //       break;
  //     default:
  //       message.error(response.data.msg)
  //   }
  // } else { 
  //   return response;
  // }
  return response.data;
})
export default service